<template>
  <div class="cell small-12 medium-3 product-container">
    <div class="product">
      <div class="product__body" :class="{ disabled }">
        <div class="product__body-img" v-if="hasPreview">
          <!-- <hcc-image-viewer :images="images" imageClass="product__body-img" /> -->
          <hcc-image-viewer
            :images="[imageUrl]"
            imageClass="product__body-img"
          />
        </div>
        <div class="product__body-img" v-else>
          <img :src="imageUrl" alt="product-img" />
        </div>
        <div class="product__body-text">
          <div class="product__body-name">
            {{ data.nombre }}
          </div>
          <div class="product__body-price">{{ precio }}</div>
          <div class="product__body-about" v-if="data.descripcion">
            {{ truncateWithDots(data.descripcion, 100) }}
          </div>
        </div>
        <div class="product__footer" v-if="hasSlotFooter">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral';
import HccImageViewer from '@/components/shared/HccImageViewer/index.vue';

export default {
  name: 'ItemProduct',
  components: { HccImageViewer },
  props: {
    data: {},
    hasPreview: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    precio() {
      return numeral(this.data.precio).format('$0,0.00');
    },
    hasSlotFooter() {
      return !!this.$slots.footer;
    },
    imageUrl() {
      let url = 'http://placehold.it/700x400';

      if (this.data && this.data.imageURL) {
        const imgU = this.data.imageURL[0];
        url = imgU;
      }
      return url;
    },
  },

};
</script>

<style lang="scss" scoped>
@import "~styles/components/item-product.scss";
</style>
