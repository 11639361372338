<template>
  <div class="loader-container">
    <overlay-loader :loading="pending" />
    <hcc-table
      :columns="columns"
      :rows="supervisors"
      :title="tableName"
      :buttonText="assign"
      @search="searchUser"
      :enableAdd="canAssign"
      @add="assignSupervisor"
      @delete="deleteSupervisor"
      @pageChanged="changePage"
      :rowsPerPage="pagination.perPage"
      :actualPage="pagination.page"
      :totalPages="pagination.totalPages"
    />
    <assign-supervisor-modal
      v-if="canAssign"
      :campaign="campaign"
      @updateSupervisors="updateSupervisors"
    />
    <hcc-confirmation />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import HccTable from '@/components/shared/HccTable/index.vue';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import UsersByCampaignGql from '@/graphql/queries/users/usersByCampaign.gql';
import adminRole from '@/utils/adminRole';
import executeQuery from '@/utils/gql-api';
import unassignUser from '@/graphql/mutations/users/unassignUser.gql';
import errorHandler from '@/utils/error.handler';
import AssignSupervisorModal from './AssignSupervisorModal.vue';

export default {
  components: {
    HccTable,
    HccConfirmation,
    OverlayLoader,
    AssignSupervisorModal,
  },
  data() {
    return {
      text: null,
      supervisorsByCampaign: [],
      searchSupervisor: null,
      supervisor: {},
      pending: null,
      pagination: {
        page: 1,
        perPage: 10,
        totalPages: 0,
      },
    };
  },
  watch: {
    campaign(newValue, oldValue) {
      if ((newValue.id !== oldValue.id && this.$route.name === 'campaigns-settings')
        || (newValue.id === oldValue.id && newValue.company)) {
        this.fetchSupervisors(1);
      }
    },
  },
  computed: {
    ...mapState({
      campaign: state => state.setting.currentCampaign,
    }),
    tableName() {
      return this.$t('campaign-settings.assigned-users.supervisors');
    },
    assign() {
      return this.$t('campaign-settings.assigned-users.assign');
    },
    delete() {
      return this.$t('campaign-settings.assigned-users.delete-supervisor');
    },
    supervisors() {
      return this.supervisorsByCampaign || [];
    },
    role() {
      return adminRole();
    },
    canAssign() {
      return ['owner', 'admin', 'super'].includes(this.role);
    },
    columns() {
      return [{
        label: this.$t('campaign-settings.assigned-users.user'),
        field: 'name',
      }, {
        label: this.$t('campaign-settings.assigned-users.email'),
        field: 'email',
      }];
    },
    emptyRows() {
      return this.pagination.totalPages === 0;
    },
  },
  methods: {
    ...mapActions('setting', ['updateCampaignField']),
    async fetchSupervisors(page) {
      this.pending = true;
      const result = await executeQuery('usersByCampaign',
        UsersByCampaignGql,
        {
          role: 'supervisor',
          id: this.campaign.id,
          length: this.pagination.perPage,
          page,
          search: this.text,
        },
        false);

      this.pending = false;
      this.supervisorsByCampaign = result.data;
      this.pagination.totalPages = result.total;
    },
    assignSupervisor() {
      this.$modal.show('assign-supervisor-modal');
    },
    async updateSupervisors() {
      await this.fetchSupervisors(1);
      this.updateCampaignField({
        type: 'admins',
        campaign: this.campaign,
        data: this.pagination.totalPages,
      });
    },
    deleteSupervisor(row) {
      const info = {
        supervisor: row.name,
        campaign: this.campaign.name,
      };

      this.$modal.show('confirmation', {
        title: this.delete,
        description: this.$t('campaign-settings.assigned-users.confirm-delete-supervisor',
          info),
        variant: 'error',
        confirm: () => this.unassignSupervisor(row.id, info),
      });
    },
    async unassignSupervisor(supervisor, info) {
      await this.$apollo.mutate({
        mutation: unassignUser,
        variables: {
          user: supervisor,
          campaign: this.campaign.id,
        },
      })
        .then(() => {
          this.$toasted.global.success(this.$t('campaign-settings.assigned-users.supervisor-deleted', info));
          this.updateSupervisors();
        })
        .catch((err) => {
          errorHandler.logErrors(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        });
    },
    changePage(newPage) {
      if (this.pagination.page !== newPage) {
        this.pagination.page = newPage;
        this.fetchSupervisors(this.pagination.page);
      }
    },
    searchUser({ value, key }) {
      if (key && value.length >= 3) {
        this.text = value;
        this.fetchSupervisors(1);
      } else if (value.length === 0) {
        this.text = null;
        this.fetchSupervisors(1);
      }
    },
  },
  created() {
    if (this.campaign.id) {
      this.fetchSupervisors(1);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/settings/_users-tab.scss";
</style>
