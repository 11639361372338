import getOrdersGql from '@/graphql/queries/sales/getOrdersAdmin.gql';
import percentageDeliveryMethodsGql from '@/graphql/queries/sales/percentageDeliveryMethods.gql';
import paymentMethodsGql from '@/graphql/queries/sales/paymentMethods.gql';
import topProductsGql from '@/graphql/queries/sales/topProducts.gql';
import salesDensityGql from '@/graphql/queries/sales/salesDensity.gql';
import executeQuery from '@/utils/gql-api';
import errorHandler from '@/utils/error.handler';

export default {
  async getOrdersAdmin({ commit }, params) {
    try {
      const payload = await executeQuery(
        'getOrdersAdmin',
        getOrdersGql,
        params,
        false,
      );
      commit('setItems', payload);
    } catch (err) {
      errorHandler.logErrors(err);
    }
  },
  async getPaymentMethodsPercentage({ commit }, params) {
    try {
      const payload = await executeQuery(
        'paymentMethods',
        paymentMethodsGql,
        params,
        false,
      );
      commit('setPaymentMethods', payload);
    } catch (err) {
      errorHandler.logErrors(err);
    }
  },
  async getPercentageDeliveryMethods({ commit }, params) {
    try {
      const payload = await executeQuery(
        'percentageDeliveryMethods',
        percentageDeliveryMethodsGql,
        params,
        false,
      );
      commit('setPercentageDelivery', payload);
    } catch (err) {
      errorHandler.logErrors(err);
    }
  },
  async topProducts({ commit }, params) {
    try {
      const payload = await executeQuery(
        'topProducts',
        topProductsGql,
        params,
        false,
      );
      commit('setTopProducts', payload);
    } catch (err) {
      errorHandler.logErrors(err);
    }
  },
  async salesDensity({ commit }, params) {
    try {
      const payload = await executeQuery(
        'salesDensity',
        salesDensityGql,
        params,
        false,
      );
      commit('setSalesDensity', payload);
    } catch (err) {
      errorHandler.logErrors(err);
    }
  },
};
