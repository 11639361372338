<template>
  <div>
    <hcc-modal
      :title="$t('shop.sale.title-modal-details', { order: data.order })"
      class="shop"
      :width="800"
      name="detail-order"
      :adaptive="true"
      :buttons="['exit']"
    >
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t('shop.sale.quantity') }}</th>
            <th>{{ $t('shop.sale.product') }}</th>
            <th>{{ $t('shop.sale.costumers-commentaries') }}</th>
            <th
              v-if="showAddress"
            >
              {{ $t('shop.sale.delivery-address') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in data.car" :key="index">
            <td>{{ product.quantity }}</td>
            <td>
              {{ product.name }}
              <ul v-if="product.optionsGroup.length > 0">
                <li v-for="(optionGroup, index) in product.optionsGroup" :key="index">
                  {{ optionGroup.name }}
                  <ul>
                    <li v-for="(option, index) in optionGroup.options" :key="index">
                      {{ option.name }}
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
            <td>{{ product.comment }}</td>
            <td style="max-width: 190px">
              <span v-if="index === 0 && showAddress">
                {{data.location.address}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </hcc-modal>
  </div>
</template>

<script>
import HccModal from '@/components/shared/HccModal/index.vue';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    HccModal,
  },
  computed: {
    showAddress() {
      return this.data.location?.address;
    },
  },
};
</script>
