import { render, staticRenderFns } from "./AdminsTab.vue?vue&type=template&id=6c9a0646&scoped=true&"
import script from "./AdminsTab.vue?vue&type=script&lang=js&"
export * from "./AdminsTab.vue?vue&type=script&lang=js&"
import style0 from "./AdminsTab.vue?vue&type=style&index=0&id=6c9a0646&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c9a0646",
  null
  
)

export default component.exports