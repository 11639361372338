<template>
  <div class="loader-container">
    <overlay-loader :loading="loading" />
    <section class="main-content">
      <div v-show="isOwner" class="grid-x align-right margin-bottom--20">
        <div class="cell shrink">
          <button-filter
            v-bind:display="enabledFilters"
            v-on:change="onFiltersChanged"
          />
        </div>
      </div>
      <div class="grid-x grid-margin-x grid-margin-y" v-if="!isLoading">
        <hcc-table
          class="cell small-12"
          :enableSearch="true"
          :rowsPerPage="10"
          :actualPage="actualPage"
          :buttonText="$t('shop.ecommerce.title-add')"
          :rows="stores"
          :columns="columns"
          @preview="showStore"
        />
      </div>
    </section>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import { getImageUrlInformation } from '@/utils/helper';
import HccTable from '@/components/shared/HccTable/index.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import editCampaignMutation from '@/graphql/mutations/editCampaign.gql';
import campaignsByCompanyIdQuery from '@/graphql/queries/campaigns/byCompanyId.gql';

export default {
  components: {
    HccTable,
    OverlayLoader,
    ButtonFilter: () => import('@/components/ButtonFilter.vue'),
  },
  data() {
    return {
      actualPage: 1,
      loading: true,
      filters: null,
      enabledFilters: ['company'],
      currentStore: {},
    };
  },
  activated() {
    if (this.filters !== null || !this.isOwner) {
      this.init();
    }
  },
  computed: {
    ...mapState({
      user: state => state.shared.user,
      stores: state => state.shop.items,
    }),
    ...mapGetters({
      isOwner: 'shared/isOwner',
      isSuper: 'shared/isSuper',
      campaigns: 'setting/getCampaigns',
    }),
    isLoading() {
      return this.loading || this.$apollo.loading;
    },
    columns() {
      return [{
        label: this.$t('shop.name'),
        field: 'name',
      }, {
        label: this.$t('shop.description'),
        field: 'description',
      }];
    },
    currentCompanyId() {
      return this.isOwner ? this.filterCompany : this.userCompany.id;
    },
    userCompany() {
      return JSON.parse(localStorage.currentCompany);
    },
    filterCompany() {
      return this.filters ? this.filters.companyId : 0;
    },
    hideButtonEcommerce() {
      return this.stores.some(({ category }) => category === 'Ecommerce');
    },
  },
  methods: {
    ...mapActions({
      saveStore: 'shop/save',
      getMyStores: 'shop/getStoreOfUser',
      clearMyStore: 'shop/clearStoreOfUser',
      removeStore: 'shop/remove',
      updateCampaigns: 'setting/updateCampaign',
    }),
    onFiltersChanged(filters) {
      this.filters = filters;
    },
    getFilterStore(campaigns = []) {
      return campaigns.map(({ id }) => id);
    },
    async init() {
      this.loading = true;
      const { data: { campaignsByCompanyId } } = await this.$apollo.query({
        query: gql`${campaignsByCompanyIdQuery}`,
        variables: {
          companyId: this.currentCompanyId,
        },
        fetchPolicy: 'no-cache',
      });
      if (campaignsByCompanyId && campaignsByCompanyId.length > 0) {
        await this.getMyStores(this.getFilterStore(campaignsByCompanyId));
      } else {
        this.clearMyStore();
      }
      this.loading = false;
    },
    async saveNewData(storeData) {
      this.loading = true;
      await this.saveStore(storeData);
      if (this.campaigns.length > 0) {
        await this.getMyStores(this.getFilterStore(this.campaigns));
      }
      const { data: campaign } = await this.$apollo.mutate({
        mutation: editCampaignMutation,
        variables: {
          id: storeData.id,
          name: storeData.nombre,
        },
      });
      this.updateCampaigns(campaign.editCampaign);
      this.loading = false;
    },
    async editStore(item) {
      const dataImage = await getImageUrlInformation(item.imageUrl);
      this.currentStore = { ...item, ...dataImage, size: dataImage.size };
      if (this.currentStore.category === 'Ecommerce') {
        this.$modal.show('ecommerce-form');
      } else {
        this.$modal.show('shop-form');
      }
    },
    showStore(item) {
      if (item.category === 'Ecommerce') {
        this.currentStore = { ...item };
        this.$modal.show('ecommerce-form');
      } else {
        const { campaign } = item;
        this.$router.push({ name: 'store.admin.sellia', params: { id: campaign } });
        sessionStorage.setItem('campaign', JSON.stringify(campaign));
      }
    },
    // showCalendar({ campaign }) {
    //   window.open(`${process.env.VUE_APP_RESERVATIONS_URL}/calendar/${campaign}`, '_blank');
    // },
  },
  watch: {
    filters(newValue, oldValue) {
      if (newValue !== null && (oldValue === null || newValue.companyId !== oldValue.companyId)) {
        this.init();
      }
    },
  },
};
</script>
