<template>
  <div class="grid-x grid-margin-x">
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'conversationsDataCards'"
        :theme="dataCards.avgAht.theme"
        :name="dataCards.avgAht.name"
        :value="secondsDuration(dataCards.avgAht.value)"
      >
        <clock-outline-icon />
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'conversationsDataCards'"
        :theme="dataCards.avgAsa.theme"
        :name="dataCards.avgAsa.name"
        :value="secondsDuration(dataCards.avgAsa.value)"
      >
        <clock-outline-icon />
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'conversationsDataCards'"
          v-bind:theme="dataCards.avgAtention.theme"
          v-bind:name="dataCards.avgAtention.name"
          v-bind:value="secondsDuration(dataCards.avgAtention.value)"
          v-bind:showIconInfo="'chart.card.atention-time-info'">
          <clock-outline-icon/>
        </data-card>
      </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        v-bind:card-id="'conversationsDataCards'"
        v-bind:theme="dataCards.totalConversations.theme"
        v-bind:name="dataCards.totalConversations.name"
        v-bind:value="dataCards.totalConversations.value | numFormat">
        <message-icon/>
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        v-bind:card-id="'conversationsDataCards'"
        v-bind:theme="dataCards.inbound.theme"
        v-bind:name="dataCards.inbound.name"
        v-bind:value="dataCards.inbound.value | numFormat">
        <message-icon/>
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'conversationsDataCards'"
        :theme="dataCards.outbound.theme"
        :name="dataCards.outbound.name"
        :value="dataCards.outbound.value | numFormat"
      >
        <message-icon />
      </data-card>
    </div>
    <h3 class="cell small-12 medium-12 card__title">
      <span class="icon-info" v-tooltip="$t('chart.card.whatsapp-information')">
        <information-variant-icon/>
      </span>
      {{ `${$t('chart.card.whatsapp-text')}: ${month}` }}
    </h3>
    <div class="cell small-12 medium-6 large-6">
      <data-card
        v-bind:card-id="'conversationWA'"
        v-bind:theme="dataCards.whatsapp.theme"
        v-bind:name="dataCards.whatsapp.name"
        v-bind:extra-info="dataCards.whatsapp.info"
        v-bind:value="dataCards.whatsapp.value | numFormat"
      >
        <message-icon/>
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-6">
      <data-card
        v-bind:card-id="'conversationWA'"
        v-bind:theme="dataCards.whatsappPayment.theme"
        v-bind:name="dataCards.whatsappPayment.name"
        v-bind:extra-info="dataCards.whatsappPayment.info"
          v-bind:cost="dataCards.whatsappPayment.cost"
        v-bind:value="dataCards.whatsappPayment.value | numFormat"
      >
        <message-icon/>
        <div slot="description">
          {{ $t(dataCards.whatsappPayment.subtitle) }}
          <span class="subtitle">$ {{ dataCards.whatsappPayment.cost }}</span>
        </div>
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-6">
      <data-card
        v-bind:card-id="'conversationWA'"
        v-bind:theme="dataCards.whatsappIn.theme"
        v-bind:name="dataCards.whatsappIn.name"
        v-bind:extra-info="dataCards.whatsappIn.info"
        v-bind:cost="dataCards.whatsappIn.cost"
        v-bind:value="dataCards.whatsappIn.value | numFormat"
      >
        <message-icon/>
        <div slot="description">
          {{ $t(dataCards.whatsappIn.subtitle) }}
          <span class="subtitle">$ {{ dataCards.whatsappIn.cost }}</span>
        </div>
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-6">
      <data-card
        v-bind:card-id="'conversationWA'"
        v-bind:theme="dataCards.whatsappOut.theme"
        v-bind:name="dataCards.whatsappOut.name"
        v-bind:extra-info="dataCards.whatsappOut.info"
        v-bind:cost="dataCards.whatsappOut.cost"
        v-bind:value="dataCards.whatsappOut.value | numFormat"
      >
        <message-icon/>
        <div slot="description">
          {{ $t(dataCards.whatsappOut.subtitle) }}
          <span class="subtitle">$ {{ dataCards.whatsappOut.cost }}</span>
        </div>
      </data-card>
    </div>
  </div>
</template>

<script>
import executeQuery from '@/utils/gql-api';
import ConversationsDataCards from '@/graphql/queries/conversations/dataCards.gql';
import ConversationWA from '@/graphql/queries/conversations/whatsappConversation.gql';
import DataCard from '@/components/DataCard.vue';
import DataCardInfo from '@/utils/dataCardsInfo';

export default {
  components: {
    DataCard,
  },
  props: {
    filters: Object,
  },
  data() {
    return {
      dataCards: DataCardInfo,
      askForWhatsapp: false,
      askForWhatsappMounted: false,
    };
  },
  watch: {
    filters: {
      handler(newValue, oldValue) {
        this.loadCards();
        if (this.validMonth(newValue?.startDate, oldValue?.startDate)
          || newValue?.companyId !== oldValue?.companyId) {
          this.loadWhatsappCards();
        }
      },
      deep: true,
    },
  },
  computed: {
    month() {
      return this.$moment(this.filters?.startDate)
        .local()
        .format('MMMM')
        .toUpperCase();
    },
  },
  mounted() {
    if (this.filters !== null) {
      this.loadCards();
      this.loadWhatsappCards();
    }
  },
  methods: {
    async fetchData(id, query, variables = {}) {
      this.$store.commit('dashboard/enableLoader', id);
      const data = await executeQuery(id, query, variables, false);
      this.$store.commit('dashboard/disableLoader', id);

      return data;
    },
    async loadCards() {
      const data = await this.fetchData('conversationsDataCards', ConversationsDataCards, this.filters);
      this.dataCards.avgAht.value = data.avgAht;
      this.dataCards.avgAsa.value = data.avgAsa;
      this.dataCards.avgAtention.value = data.avgAttention;
      this.dataCards.totalConversations.value = data.totalConversations;
      this.dataCards.inbound.value = data.inbound;
      this.dataCards.outbound.value = data.outbound;
    },
    async loadWhatsappCards() {
      const query = { company: this.filters.companyId, date: this.filters.startDate };
      const data = await this.fetchData('conversationWA', ConversationWA, query);
      const { total: payment = 0, pricing: pricingOut = 0 } = data.find(({ _id }) => _id === 'business_initiated') ?? {};
      const { total: free } = data.find(({ _id }) => _id === 'whatsapp_free');
      const { total: initiated = 0, pricing: pricingIn = 0 } = data.find(({ _id }) => _id === 'user_initiated') ?? {};
      const toPay = free < 0 ? Math.abs(free) : 0;
      const byFree = free > 0 ? free : 0;

      this.dataCards.whatsapp.value = byFree;
      this.dataCards.whatsappPayment.value = toPay;
      this.dataCards.whatsappPayment.cost = free > 0 ? 0 : (pricingOut + pricingIn).toFixed(2);

      this.dataCards.whatsappOut.value = payment;
      this.dataCards.whatsappOut.cost = free > 0 ? 0 : pricingOut.toFixed(2);
      this.dataCards.whatsappIn.value = initiated;
      this.dataCards.whatsappIn.cost = free > 0 ? 0 : pricingIn.toFixed(2);
      this.askForWhatsappMounted = false;
    },
    secondsDuration(value) {
      return this.$moment.duration(value, 'seconds')
        .format('h[h] m[m] s[s]');
    },
    validMonth(newDate, oldDate) {
      const newEst = this.$moment(newDate).local();
      const oldEst = this.$moment(oldDate).local();
      return !newEst.isSame(oldEst, 'month');
    },
  },
};
</script>
