<template>
  <nav class="top-nav">
    <ul v-if="!showButtonEvent">
      <li v-for="(item, index) in items" :key="index">
        <router-link :to="item.href" v-if="item.disabled">
          <span>{{ item.text }}</span>
        </router-link>
        <span class="top-nav__active" v-else>{{ item.text }}</span>
      </li>
    </ul>
    <ul v-else>
      <li v-for="(item, index) in items" :key="index">
        <button
          @click="handleChange"
          v-if="index === 0"
          style="cursor: pointer"
        >{{item.text}}
        </button>
        <button
          @click="handleChangeChildren(item, index)"
          v-if="index > 0"
          style="cursor: pointer">
        <span class="top-nav__active">{{ item.text }}</span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    handleChange: {
      type: Function,
      required: false,
      default: undefined,
    },
    showButtonEvent: {
      type: Boolean,
      required: false,
      default: false,
    },
    handleChangeChildren: {
      type: Function,
      required: false,
      default: undefined,
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_breadcrumb.scss';
</style>
