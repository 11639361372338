<template>
  <div class="off-canvas-wrapper" :class="{ scrollNone: activeNotification }">
    <site-header v-on:open-notifications="openNotifications" />
    <div
      data-off-canvas
      id="offCanvas"
      class="off-canvas position-left reveal-for-medium"
      v-bind:class="{ 'menu-collapse': collapse, overflow: collapse }"
      v-show="this.windowWidth > 640"
    >
      <div class="site-logo">
        <div class="logo" v-on:click="collapse = !collapse"></div>
      </div>
      <div class="sidebar-wrapper">
        <vuescroll :ops="ops">
          <nav class="sidebar-menu" data-close="offCanvas">
            <ul>
              <li v-for="(item, index) in items()" v-bind:key="index">
                <router-link v-bind:to="item.href" :key="item.title">
                  <div class="icon">
                    <component v-bind:is="item.icon" />
                  </div>
                  <div class="sidebar-link">
                    <span class="title">{{ item.title }}</span>
                  </div>
                </router-link>
              </li>
              <li v-if="isOwner">
                <a href="#" @click.prevent.stop="addOnsRedirect">
                  <div class="icon">
                    <puzzle-icon />
                  </div>

                  <div class="sidebar-link">
                    <span class="title">{{ $t('app.add-ons') }}</span>
                  </div>
                </a>
              </li>
            </ul>
          </nav>
        </vuescroll>
      </div>
    </div>
    <div
      data-off-canvas-content
      class="main-content grid-container full"
      :class="{ 'canvas-collapse': collapse, mobile: isMobile }"
    >
      <div class="grid-x">
        <div class="cell">
          <transition name="component-fade" mode="out-in">
            <keep-alive>
              <slot />
            </keep-alive>
          </transition>
        </div>
      </div>
    </div>
    <menu-notification v-if="!isLogin" ref="notificationsMenu" />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import jwtDecode from 'jwt-decode';
import errorHandler from '@/utils/error.handler';
import vuescroll from 'vuescroll';

export default {
  components: {
    vuescroll,
    SiteHeader: () => import('@/components/Header.vue'),
    MenuNotification: () => import('@/components/MenuNotification.vue'),
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: '#A9C3E4',
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0,
          size: '6px',
          disable: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      accessToken: 'dashboard/getAccessToken',
    }),
    role() {
      try {
        const decoded = jwtDecode(this.accessToken);
        return decoded.role ? decoded.role : '';
      } catch (e) {
        errorHandler.logErrors(e);
        return '';
      }
    },
    permissions() {
      try {
        const decoded = jwtDecode(this.accessToken);
        return decoded.permissions ? decoded.permissions : ['dashboard'];
      } catch (e) {
        errorHandler.logErrors(e);
        return '';
      }
    },
    collapse: {
      get() {
        return this.$store.state.dashboard.collapse;
      },
      set(value) {
        this.toogleCollapse(value);
      },
    },
    isLogin() {
      return this.$route.name === 'login';
    },
    isOwner() {
      return this.role === 'owner' && false;
    },
    activeNotification() {
      return this.$store.state.notification.toogleMenu;
    },
    messagesUrl() {
      return `${process.env.VUE_APP_REDIRECT_URL}/conversations`;
    },
    teamUrl() {
      return `${process.env.VUE_APP_REDIRECT_URL}/team`;
    },
    dataBankUrl() {
      return `${process.env.VUE_APP_REDIRECT_URL}/data-bank`;
    },
  },
  methods: {
    ...mapMutations({
      toogleCollapse: 'dashboard/toogleCollapse',
    }),
    openNotifications() {
      this.$refs.notificationsMenu.displayMenu();
    },
    items() {
      let itemsTotal = [];

      if (this.role !== 'supervisor') {
        itemsTotal = [
          {
            title: this.$t('app.dashboard'),
            href: '/dashboard',
            icon: 'view-dashboard-icon',
            new: false,
          },
          {
            title: this.$t('app.send-templates'),
            href: '/send-templates',
            icon: 'whatsapp',
            new: false,
          },
          {
            title: this.$t('app.campaigns'),
            href: '/campaigns',
            icon: 'bullhorn-icon',
            new: false,
          },
          {
            title: this.$t('app.messages'),
            href: '/messages',
            icon: 'message-icon',
            new: false,
          },
          {
            title: this.$t('app.team'),
            href: '/team',
            icon: 'account-group',
            new: false,
          },
          {
            title: this.$t('app.store'),
            href: '/store',
            icon: 'store-icon',
            new: false,
          },
        ];
      }

      if (this.role === 'owner' || this.role === 'super') {
        itemsTotal.push({
          title: this.$t('app.users'),
          href: '/users',
          icon: 'account-multiple-icon',
          new: false,
        });

        itemsTotal.push({
          title: this.$t('app.permissions'),
          href: '/permissions',
          icon: 'account-supervisor-circle-icon',
          new: false,
        });
      }
      if (this.role === 'owner') {
        itemsTotal.push({
          title: this.$t('app.company'),
          href: '/company',
          icon: 'briefcase-icon',
          new: false,
        });

        itemsTotal.push({
          title: this.$t('app.company-send'),
          href: '/shipments',
          icon: 'truckfast-icon',
          new: false,
        });
      }

      if (this.role === 'supervisor') {
        itemsTotal.push({
          title: this.$t('app.dashboard'),
          href: '/dashboard',
          icon: 'view-dashboard-icon',
          new: false,
        });

        if (this.permissions.includes('templates')) {
          itemsTotal.push({
            title: this.$t('app.send-templates'),
            href: '/send-templates',
            icon: 'whatsapp',
            new: false,
          });
        }

        if (this.permissions.includes('config')) {
          itemsTotal.push({
            title: this.$t('app.campaigns'),
            href: '/campaigns',
            icon: 'bullhorn-icon',
            new: false,
          });
        }

        if (this.permissions.includes('messages')) {
          itemsTotal.push({
            title: this.$t('app.messages'),
            href: '/messages',
            icon: 'message-icon',
            new: false,
          });
        }

        if (this.permissions.includes('team')) {
          itemsTotal.push({
            title: this.$t('app.team'),
            href: '/team',
            icon: 'account-group',
            new: false,
          });
        }

        if (this.permissions.includes('stores')) {
          itemsTotal.push({
            title: this.$t('app.store'),
            href: '/store',
            icon: 'store-icon',
            new: false,
          });
        }
      }
      return itemsTotal;
    },
    async addOnsRedirect() {
      window.location.href = `${process.env.VUE_APP_BOT_BUILDER}?data=${this.accessToken}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_site-navigation.scss";
</style>
