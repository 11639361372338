<template>
  <div>
    <slot />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import saveChannel from '@/graphql/mutations/channels/saveChannel.gql';
import deleteChannel from '@/graphql/mutations/channels/deleteChannel.gql';
import ChannelByCampaignId from '@/graphql/queries/channels/channelByCampaignId.gql';
import executeQuery from '@/utils/gql-api';
import errorHandler from '@/utils/error.handler';

export default {
  data() {
    return {
      mode: 'detail',
      pending: null,
    };
  },
  computed: {
    ...mapGetters({ currentCampaign: 'setting/getCurrentCampaign' }),
  },
  methods: {
    ...mapActions({
      fetchCompanies: 'shared/fetchCompanies',
      updateCampaign: 'setting/updateCampaign',
    }),
    async getChannels(campaign) {
      const result = await (executeQuery('channelByCampaignId',
        ChannelByCampaignId, {
          campaign,
          getMainCampaign: false,
        }, false));

      return result;
    },
    updateChannel(channel, selectedChannel) {
      this.pending = true;
      return this.$apollo.mutate({
        mutation: saveChannel,
        variables: channel,
      })
        .then((result) => {
          if ('overrideMessages' in channel || 'overrideSchedules' in channel || 'overrideConfig' in channel) {
            this.$toasted.global.success(this.editSuccessMessage(channel, selectedChannel));
          } else {
            this.$toasted.global.success(this.successMessage(channel, selectedChannel));
          }
          this.updateChannelsCurrentCampaign(result.data.saveChannel);
          this.fetchCompanies();
          this.pending = false;
          return result;
        })
        .catch((err) => {
          this.pending = false;
          errorHandler.logError(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        });
    },
    successMessage(channel, selectedChannel) {
      const msg = !channel.id ? 'create-success' : 'edit-success';

      return this.$t(`channels-settings.${msg}`, { channel: selectedChannel.name });
    },
    editSuccessMessage(channel, selectedChannel) {
      const msg = (channel.overrideMessages || channel.overrideSchedules || channel.overrideConfig) ? 'enable-channel-settings' : 'disable-channel-settings';

      let settings = '';

      if ('overrideMessages' in channel) {
        settings = this.$t('channels-settings.messages.title');
      } else if ('overrideSchedules' in channel) {
        settings = this.$t('channels-settings.schedules.title');
      } else {
        settings = this.$t('channels-settings.options.title');
      }

      return this.$t(`channels-settings.${msg}`, { settings, channel: selectedChannel.name });
    },
    deleteChannel(channel) {
      return this.$apollo.mutate({
        mutation: deleteChannel,
        variables: { channelId: channel.id },
      })
        .then(() => {
          this.$toasted.global.success(this.deleteSuccess(channel.name));
          this.updateChannelsCurrentCampaign(channel, true);
          this.fetchCompanies();
        })
        .catch((err) => {
          errorHandler.logError(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        });
    },
    deleteSuccess(channel) {
      return this.$t('channels-settings.delete-success', { channel });
    },
    getMode() {
      return this.mode;
    },
    changeMode(mode) {
      this.mode = mode;
    },
    isPending() {
      return this.pending;
    },
    updateChannelsCurrentCampaign({ id, name, channelType }, remove = false) {
      const channel = { id, name, channelType };
      const campaign = this.currentCampaign;
      const index = campaign.channels.findIndex(chan => chan.id === id);
      if (remove && index !== -1) {
        campaign.channels.splice(index, 1);
      } else if (index !== -1) {
        this.$set(campaign.channels, index, channel);
      } else {
        campaign.channels.push(channel);
      }
      this.updateCampaign(campaign);
    },
  },
  provide() {
    return {
      updateChannel: this.updateChannel,
      getMode: this.getMode,
      changeMode: this.changeMode,
      getChannels: this.getChannels,
      deleteChannel: this.deleteChannel,
      isPending: this.isPending,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
