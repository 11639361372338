import getCategorysByCampaignIdGql from '@/graphql/queries/categoriesProducts/getCategorysByCampaignId.gql';
import getCategoriesLastChildrenGql from '@/graphql/queries/categoriesProducts/getCategoriesLastChildren.gql';
import getCategorys from '@/graphql/queries/categoriesProducts/getCategorys.gql';
import getCategories from '@/graphql/queries/categoriesProducts/getCategories.gql';
import getChildrensCategories from '@/graphql/queries/categoriesProducts/getChildrensCategories.gql';
import executeQuery from '@/utils/gql-api';
import errorHandler from '@/utils/error.handler';

export default {
  async getCategorysByCampaignId({ commit }, campaign) {
    try {
      const payload = await executeQuery(
        'getCategorysByCampaignId',
        getCategorysByCampaignIdGql,
        { campaign },
        false,
      );
      commit('setItems', payload);
    } catch (err) {
      commit('setItems', []);
      errorHandler.logErrors(err);
    }
  },
  async getCategoriesLastChildren({ commit }, campaign) {
    executeQuery('getCategoriesLastChildren',
      getCategoriesLastChildrenGql,
      {
        campaign,
      }, false)
      .then((payload) => {
        commit('setElementGroups', payload);
      })
      .catch((err) => {
        commit('setElementGroups', []);
        errorHandler.logErrors(err);
      });
  },
  async getCategorys({ commit }, campaign) {
    executeQuery('getCategorysByCampaignId', getCategorys, { campaign }, false)
      .then((payload) => {
        commit('setItems', payload);
        commit('setDefaultItems');
      })
      .catch((err) => {
        commit('setItems', []);
        errorHandler.logErrors(err);
      });
  },
  async getCategoriesCampaign({ commit }, campaign) {
    executeQuery('getCategories',
      getCategories,
      {
        campaign,
      }, false)
      .then((payload) => {
        commit('setItems', payload);
        commit('setDefaultItems');
      })
      .catch((err) => {
        commit('setItems', []);
        errorHandler.logErrors(err);
      });
  },
  async getChildrensCategories({ commit }, { id, name, active }) {
    executeQuery('getChildrensCategories',
      getChildrensCategories,
      {
        id,
      }, false)
      .then((payload) => {
        const paramss = {
          response: payload, id, name, active,
        };
        commit('setItems', paramss);
      })
      .catch((err) => {
        commit('setItems', []);
        errorHandler.logErrors(err);
      });
  },
  async removeItems({ commit }, key) {
    commit('removeItems', key);
  },
};
