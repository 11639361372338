import campaignsByCompanyIdQuery from '@/graphql/queries/campaigns/byCompanyId.gql';
import executeQuery from '@/utils/gql-api';
// import Vue from 'vue';

export default {
  async fetchCampaigns({ commit }, id) {
    const result = await executeQuery(
      'campaignsByCompanyId',
      campaignsByCompanyIdQuery,
      { companyId: id },
      false,
    );

    commit('setCampaigns', result);
  },
  updateCampaign({ commit }, payload) {
    commit('setCampaignChange', payload);
  },
  updateCampaignField({ commit }, payload) {
    commit('setCampaignField', payload);
  },
  selectCampaign({ commit, getters }, id) {
    const campaigns = getters.getCampaigns;
    const campaign = campaigns.find(c => c.id === id);
    localStorage.currentCampaign = JSON.stringify(campaign);
    commit('setCurrentCampaign', campaign);
  },
  addCampaign({ commit, getters }, payload) {
    const campaigns = getters.getCampaigns;
    campaigns.push(payload);
    commit('setCampaigns', campaigns);
  },
};
