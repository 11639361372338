<template>
  <div>
    <overlay-loader :loading="loading" />
    <hcc-modal
      :title="titleModal"
      class="shop"
      :width="600"
      name="product-form-options"
      @confirm="createOption"
      @cancel="resetData"
      @opened="focusBuildModal"
      @closed="resetData"
      :confirmDisableButton="!validForm"
      :adaptive="true"
    >
      <div class="user__property">
        <hcc-input
          :placeholder="$t('shop.product.name-character')"
          v-model="newOption.lista"
          @blur="$v.newOption.lista.$touch()"
          :error="$v.newOption.lista.$error"
          :errorMessage="
            $t('shop.errors.required', { field: $t('shop.product.options.list') })
          "
          :label="$t('shop.product.options.list')"
          :maxLength="100"
          ref="hccInput"
        />
        <!-- <hcc-input
          type="number"
          :placeholder="$t('shop.product.options.inventory')"
          v-model="newOption.inventario"
          :label="$t('shop.product.options.inventory')"
        /> -->
        <hcc-money
          :placeholder="$t('shop.product.options.extra-cost')"
          v-model="newOption.costoExtra"
          @blur="$v.newOption.costoExtra.$touch()"
          :error="$v.newOption.costoExtra.$error"
          :errorMessage="
            $t('shop.errors.required', {
              field: $t('shop.product.options.extra-cost'),
            })
          "
          :label="$t('shop.product.options.extra-cost')"
        />
        <!-- <hcc-money
          :placeholder="$t('shop.product.options.purchase')"
          v-model="newOption.compra"
          @blur="$v.newOption.compra.$touch()"
          :error="$v.newOption.compra.$error"
          :errorMessage="
            $t('shop.errors.required', {
              field: $t('shop.product.options.purchase'),
            })
          "
          :label="$t('shop.product.options.purchase')"
        /> -->
        <div class="cell small-12 medium-4">
          <div class="multimedia__drop">
            <div class="shop__timage">{{$t('shop.image')}}<span>*</span></div>
            <vue-file-agent
              ref="dropZone"
              :theme="'list'"
              :multiple="true"
              :deletable="true"
              :helpText="$t('shop.product.image-size')"
              :maxFiles="5"
              :meta="true"
              :compact="true"
              accept="image/*"
              :maxSize="maxSize"
              @beforedelete="onBeforeDelete($event)"
              v-model="imagesOption"
            >
            </vue-file-agent>
          </div>
          <div class="shop__text">{{$t('shop.product.image-dimensions')}} </div>
        </div>
        <div class="shop__container">
          <div class="shop__group">
            <span>{{ $t('shop.product.options.active') }}</span>
            <div class="shop__status">
              <hcc-toggle-switch
                name="toggle-activo"
                :value="newOption.activo"
                @change="toggleStatus"
              />
            </div>
          </div>
        </div>
      </div>
    </hcc-modal>
  </div>
</template>

<script>
import numeral from 'numeral';
import { required, maxLength } from 'vuelidate/lib/validators';
import { saveImage, getDataImage } from '@/utils/helper';
import HccModal from '@/components/shared/HccModal/index.vue';
import HccInput from '@/components/shared/HccInput/index.vue';
import HccMoney from '@/components/shared/HccMoney/index.vue';
import HccToggleSwitch from '@/components/shared/HccToggleSwitch/index.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';

export default {
  components: {
    HccModal,
    HccInput,
    HccMoney,
    HccToggleSwitch,
    OverlayLoader,
  },
  props: {
    option: {
      require: true,
    },
    save: {
      default: false,
    },
  },
  data() {
    return {
      newOption: {
        activo: false,
        costoExtra: 0,
      },
      imagesOption: [],
      loading: false,
    };
  },
  validations: {
    newOption: {
      lista: {
        required,
        maxLength: maxLength(100),
      },
      costoExtra: { required },
    },
  },
  computed: {
    validForm() {
      return !this.$v.$invalid;
    },
    titleModal() {
      return this.newOption.id
        ? this.$t('shop.product.options.title-form-edit')
        : this.$t('shop.product.options.title-form');
    },
    maxSize() {
      return process.env.VUE_APP_UPLOAD_MAX_FILESIZE;
    },
  },
  watch: {
    async option(newValue) {
      if (newValue) {
        this.newOption = { ...newValue };
        this.imagesOption = await getDataImage(newValue.imgs, newValue.name);
      } else {
        this.resetData();
      }
    },
  },
  methods: {
    onBeforeDelete(fileRecord) {
      this.$refs.dropZone.deleteFileRecord(fileRecord);
    },
    async createOption() {
      this.loading = true;
      const data = { ...this.newOption };
      const images = this.imagesOption.map(({ file }) => {
        if (file instanceof File) return saveImage(file, this.$route.params.id);
        return [];
      });
      const listImagesOptions = [...this.imagesOption];
      let imgs = await Promise.resolve(Promise.all(images));
      imgs = imgs.filter(item => typeof item === 'string' || item instanceof String);
      const newImages = listImagesOptions.filter((item) => {
        if (item.urlResized) {
          return item.urlResized.toString().includes('https://');
        }
        if (item.url) {
          return item.url.toString().includes('https://');
        }
        return false;
      }).map(item => item.urlResized || item.url);
      const objImages = [...newImages, ...imgs];
      const dataOption = {
        groupOptionId: data.parent,
        name: data.lista,
        salePrice: numeral(data.costoExtra).value(),
        active: data.activo || false,
        imgs: objImages,
      };
      if (data.name) {
        dataOption.optionId = data.optionId;
      }
      this.$emit('save', dataOption);
      this.resetData();
      this.loading = false;
    },
    focusBuildModal() {
      this.$refs.hccInput.focus();
    },
    toggleStatus() {
      this.newOption.activo = !this.newOption.activo;
    },
    resetData() {
      this.newOption = { activo: false, costoExtra: 0, compra: 0 };
      this.imagesOption = [];
      this.$v.$reset();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_user.scss";
@import "~styles/views/_shop.scss";
</style>
