<template>
  <div class="order-card">
    <div class="order__header">
      <div class="order__number">{{ `${$t('shop.sale.order')} ${order.order}` }}
      </div>
      <div class="order__date">
        <div>{{ order.orderDate }} </div>
        <div class="order__creation">
          {{ order.createdAt }}
        </div>
      </div>
    </div>
    <div class="order__container-actions">
      <div class="order__status">
        <span class="order__title">{{ $t('filter.status') }}:
        </span>
        <hcc-badge :text="getStatus(order.statecart.name)"
          :class="getStatusColor(order.statecart.name)" rounded fill />
      </div>
      <hcc-dropdown position="bottom-left" customClass="custom-position">
        <template #button>
          <hcc-button-icon size="xl" round color="primary"
            class="right-panel__icon">
            <more-icon />
          </hcc-button-icon>

        </template>
        <hcc-dropdown-item icon="eye-icon" @click="viewOrderDetails">
          <span>{{ $t('shop.sale.see-details') }}</span>
        </hcc-dropdown-item>
        <hcc-dropdown-item :icon="statusIcon" @click="updateOrderStatus">
          <span>{{ changeStatusText }}</span>
        </hcc-dropdown-item>
        <hcc-dropdown-item icon="cancel-icon"
          v-if="[1, 2, 3, 6].includes(order.statecart.key)" @click="cancelOrder">
          <span>{{ $t('shared-components.table.cancel') }}</span>
        </hcc-dropdown-item>
      </hcc-dropdown>
    </div>
    <div class="order__container">
      <div>
        <span class="order__title">
          {{ `${$t('shop.sale.products')}:` }}
        </span>
      </div>
      <div>
        <clipboard-list-outline />
        {{ totalProducts }}
      </div>
    </div>
    <div class="order__container">
      <span class="order__title">{{ $t('dashboard.orders.payment-method') }}:
      </span>
      <div class="order__payment">
        <credit-card v-if="order.paymentMethod === 'card'"
          class="order__icon order__icon-card" />
        <cash-multiple v-else class="order__icon order__icon-cash" />
        <span>
          {{ getPaymentMethod(order.paymentMethod) }}
        </span>
      </div>
    </div>
    <div class="order__container">
      <span class="order__title">{{ $t('dashboard.orders.method-of-delivery')
      }}:
      </span>
      <div class="order__payment">
        <walk class="order__icon" v-if="Number(order.deliveryStatus) === 0" />
        <motorbike class="order__icon" v-else />
        <span class="order-list-column-text">
          {{ getDeliveryMethod(order.deliveryStatus) }}
        </span>
      </div>
    </div>
  </div>

</template>

<script>
import HccBadge from '../../components/shared/HccBadge/index.vue';
import HccButtonIcon from '../../components/shared/HccButtonIcon/index.vue';
import HccDropdown from '../../components/shared/HccDropdown/index.vue';
import HccDropdownItem from '../../components/shared/HccDropdown/DropdownItem.vue';

export default {
  components: {
    HccBadge, HccButtonIcon, HccDropdown, HccDropdownItem,
  },
  props: {
    order: {
      type: Object,
    },
  },
  computed: {
    totalProducts() {
      return this.order.car.reduce((total, { quantity }) => total
        + quantity, 0);
    },
    statusIcon() {
      if (this.order.statecart.name === 'Pendiente') {
        return 'pot-mix';
      }

      if (this.order.statecart.name === 'En preparación') {
        return 'check-circle';
      }

      if (this.order.statecart.name === 'Listo' && this.order.deliveryStatus === '1') {
        return 'bike';
      }

      if (this.order.statecart.name === 'En reparto' || this.order.deliveryStatus === '0') {
        return 'handshake-outline';
      }

      return '';
    },
    changeStatusText() {
      if (this.order.statecart.name === 'Pendiente') {
        return this.$t('shop.sale.mark-in-preparation');
      }

      if (this.order.statecart.name === 'En preparación') {
        return this.$t('shop.sale.mark-done');
      }

      if (this.order.statecart.name === 'Listo' && this.order.deliveryStatus === '1') {
        return this.$t('shop.sale.mark-as-delivered');
      }

      if (this.order.statecart.name === 'En reparto' || this.order.deliveryStatus === '0') {
        return this.$t('shop.sale.mark-delivered');
      }

      return null;
    },
  },
  methods: {
    getStatus(status) {
      if (status === 'Pendiente') return this.$t('shop.sale.pending');
      if (status === 'En preparación') return this.$t('shop.sale.in-preparation');
      if (status === 'Listo') return this.$t('shop.sale.done');
      if (status === 'En reparto') return this.$t('shop.sale.as-delivered');
      if (status === 'Entregado') return this.$t('shop.sale.delivered');
      return this.$t('shop.sale.cancel');
    },
    getStatusColor(status) {
      if (status === 'Pendiente') return 'secondary';
      if (status === 'En preparación') return 'orange';
      if (status === 'Listo') return 'primary';
      if (status === 'En reparto') return 'orange';
      if (status === 'Entregado') return 'alert-green';
      return 'alert';
    },
    getPaymentMethod(method) {
      if (method === 'card') return this.$t('shop.sale.card');
      return this.$t('shop.sale.cash');
    },
    getDeliveryMethod(status) {
      if (Number(status)) return this.$t('shop.sale.at-home');
      return this.$t('shop.sale.go-through-the-order');
    },
    viewOrderDetails() {
      this.$emit('view-detail', this.order);
    },
    updateOrderStatus() {
      this.$emit('update-status', this.order);
    },
    cancelOrder() {
      this.$emit('cancel', this.order);
    },
  },
};

</script>

<style scoped lang="scss">
@import "~styles/components/_order-card.scss";
</style>
