<template>
  <div>
    <dashboard-conversations-cards :filters="filters"/>
    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-12">
        <data-chart
          ref="conversationsByChannel"
          name="chart.conversations.by-channel"
          chart-id="conversationsByChannel"
          type="stackedColumn"
          :chart-data="finalByChannel"
          :doubleChart="doubleChart('conversationsByChannel')"
          :stackName="emptyStack('conversationsByChannel')"
          :filters="filters"
          @change="chartRangeChanged"
          @change-filter="filterChartData('conversationsByChannel', 0, $event)"
          @update-filter="filterChartData('conversationsByChannel', 1, $event)"
          @clear-filter="clearChartFilter('conversationsByChannel', $event)"
          @clear-date="clearDateFilter('conversationsByChannel', $event)"
        />
      </div>
      <div class="cell small-12 medium-12">
        <data-chart
          ref="conversationsReceivedVsAttended"
          name="chart.conversations.received-vs-attended"
          chart-id="conversationsReceivedVsAttended"
          type="line"
          :translatable="true"
          :chart-data="finalReceivedVsAttended"
          :doubleChart="doubleChart('conversationsReceivedVsAttended')"
          :filters="filters"
          @change="chartRangeChanged"
          @change-filter="
            filterChartData('conversationsReceivedVsAttended', 0, $event)
          "
          @update-filter="
            filterChartData('conversationsReceivedVsAttended', 1, $event)
          "
          @clear-filter="
            clearChartFilter('conversationsReceivedVsAttended', $event)
          "
        />
      </div>
      <div class="cell small-12 medium-12">
        <data-chart
          ref="conversationsByCategory"
          name="chart.conversations.by-category"
          chart-id="conversationsByCategory"
          type="pie"
          type-loader="piechart"
          :chart-data="finalByCategory"
          :doubleChart="doubleChart('conversationsByCategory')"
          :stackName="emptyStack('conversationsByCategory')"
          :filters="filters"
          @change="chartRangeChanged"
          @change-filter="filterChartData('conversationsByCategory', 0, $event)"
          @update-filter="filterChartData('conversationsByCategory', 1, $event)"
          @clear-filter="clearChartFilter('conversationsByCategory', $event)"
        />
      </div>
      <div class="cell small-12 medium-12">
        <data-chart
          ref="conversationsStatuses"
          name="chart.conversations.statuses"
          chart-id="conversationsStatuses"
          type="line"
          :translatable="true"
          :chart-data="finalStatuses"
          :doubleChart="doubleChart('conversationsStatuses')"
          :filters="filters"
          @change="chartRangeChanged"
          @change-filter="filterChartData('conversationsStatuses', 0, $event)"
          @update-filter="filterChartData('conversationsStatuses', 1, $event)"
          @clear-filter="clearChartFilter('conversationsStatuses', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _uniq from 'lodash/uniq';
import _isEmpty from 'lodash/isEmpty';
import ConversationsByChannel from '@/graphql/queries/conversations/byChannel.gql';
import ConversationsReceivedVsAttended from '@/graphql/queries/conversations/receivedVsAttended.gql';
import ConversationsByCategory from '@/graphql/queries/conversations/byCategory.gql';
import ConversationsStatuses from '@/graphql/queries/conversations/statuses.gql';
import executeQuery from '@/utils/gql-api';
import DataChart from '@/components/DataChart.vue';
import DashboardConversationsCards from '@/components/DashboardConversationsCards.vue';

export default {
  components: {
    DataChart,
    DashboardConversationsCards,
  },
  props: {
    filters: {
      required: true,
    },
  },
  data() {
    return {
      dateFilter: null,
      chart: {
        conversationsByChannel: [],
        conversationsReceivedVsAttended: [],
        conversationsByCategory: [],
        conversationsStatuses: [],
      },
    };
  },
  watch: {
    filters: {
      handler() {
        this.initAll();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      campaign: 'setting/getCurrentCampaign',
    }),
    finalByChannel() {
      return this.getChartData('conversationsByChannel');
    },
    finalReceivedVsAttended() {
      return this.getChartData('conversationsReceivedVsAttended');
    },
    finalStatuses() {
      return this.getChartData('conversationsStatuses');
    },
    finalByCategory() {
      return this.getPieData();
    },
    fromDashboard() {
      return this.$route.name === 'dashboard-conversation';
    },
  },
  mounted() {
    if (this.filters !== null) {
      this.initAll();
    }
  },
  methods: {
    initAll() {
      this.resetDateInput();
      this.resetCharts();
      this.fetchChartData('conversationsByChannel', 0);
      this.fetchChartData('conversationsReceivedVsAttended', 0);
      this.fetchChartData('conversationsByCategory', 0);
      this.fetchChartData('conversationsStatuses', 0);
    },
    async fetchData(id, query, variables = {}) {
      this.$store.commit('dashboard/enableLoader', id);
      const data = await executeQuery(id, query, variables, false);
      this.$store.commit('dashboard/disableLoader', id);

      return data;
    },
    chartRangeChanged(chartId, from, to) {
      if (from !== null && to !== null) {
        const startDate = this.$moment(from)
          .format('YYYY-MM-DD');
        const endDate = this.$moment(to)
          .format('YYYY-MM-DD');

        this.dateFilter = {
          startDate,
          endDate,
        };

        this.resetChart(chartId);
        this.filterChartData(chartId, 0, this.dateFilter);
      }
    },
    async fetchChartData(name, index, paramFilters = {}) {
      const filters = { ...this.filters, ...paramFilters };

      const filterName = paramFilters.name || this.campaign.name;

      const nameToQuery = {
        conversationsByChannel: ConversationsByChannel,
        conversationsReceivedVsAttended: ConversationsReceivedVsAttended,
        conversationsByCategory: ConversationsByCategory,
        conversationsStatuses: ConversationsStatuses,
      };

      const response = await this.fetchData(name, nameToQuery[name], filters);

      response.name = filterName;

      response.series = response.series
        .map(serie => ({ ...serie, stack: filterName }));

      const resultChart = [...this.chart[name]];

      resultChart[index] = response;

      this.chart[name] = resultChart;
    },
    getChartData(name) {
      if (this.chart[name].length === 0) {
        return {};
      }

      const final = {
        dateFormat: this.chart[name][0].dateFormat,
        categories: [],
        series: [],
      };

      this.chart[name].forEach((data) => {
        final.categories = [...final.categories, ...data.categories];
      });

      final.categories = _uniq(final.categories);

      const { categories } = final;

      this.chart[name].forEach((data) => {
        const series = data.series.map(serie => ({
          ...serie,
          filter: data.name,
          data: this.fillSeries(
            serie.data,
            data.categories,
            categories,
          ),
        }));
        final.series = [...final.series, ...series];
      });

      return final;
    },
    fillSeries(series, initCategories, finalCategories) {
      return finalCategories.map((final) => {
        const index = initCategories.findIndex(init => init === final);

        return index === -1 ? 0 : series[index];
      });
    },
    getPieData() {
      if (this.chart.conversationsByCategory.length === 0) {
        return {};
      }

      const final = {
        series: [],
      };

      this.chart.conversationsByCategory.forEach((data) => {
        const series = data.series.map(serie => ({
          ...serie,
          pie: data.name,
        }));
        final.series = [...final.series, ...series];
      });

      return final;
    },
    async filterChartData(from, index, filters) {
      await this.fetchChartData(from, index, this.getFilters(from, filters));
    },
    clearChartFilter(name, filters) {
      this.chart[name].splice(0, 1);
      this.fetchChartData(name, 0, this.getFilters(name, filters));
    },
    getFilters(name, filters) {
      const chartFilters = { ...filters };

      if (filters.startDate && filters.campaignId) {
        this.$refs[name].resetFilters();
        this.dateFilter = null;
      }

      if (this.dateFilter?.startDate) {
        chartFilters.startDate = this.dateFilter.startDate;
        chartFilters.endDate = this.dateFilter.endDate;
      }

      return chartFilters;
    },
    clearDateFilter(name, filters) {
      this.chart[name].splice(0, 2);
      this.fetchChartData(name, 0,
        {
          campaignId: filters.firstCampaign.id,
          name: filters.firstCampaign.name,
        });
      this.fetchChartData(name, 1,
        {
          campaignId: filters.secondCampaign.id,
          name: filters.secondCampaign.name,
        });
    },
    resetChart(name) {
      this.chart[name].splice(0, 2);
    },
    resetCharts() {
      this.chart.conversationsByChannel.splice(0, 2);
      this.chart.conversationsReceivedVsAttended.splice(0, 2);
      this.chart.conversationsByCategory.splice(0, 2);
      this.chart.conversationsStatuses.splice(0, 2);
    },
    resetDateInput() {
      if (!_isEmpty(this.$refs)) {
        this.$refs.conversationsByChannel.resetFilters();
        this.$refs.conversationsReceivedVsAttended.resetFilters();
        this.$refs.conversationsByCategory.resetFilters();
        this.$refs.conversationsStatuses.resetFilters();
      }
      this.dateFilter = null;
    },
    emptyStack(name) {
      const result = this.chart[name].filter(data => data.series.length === 0);
      return result.length === this.chart[name].length ? '' : result[0]?.name;
    },
    doubleChart(name) {
      return this.chart[name].length === 2;
    },
  },
};
</script>
