<template>
  <div class="table-container tab-container">
    <overlay-loader :loading="isLoading" />
    <hcc-table
      :actualPage="page"
      :rowsPerPage="10"
      :totalPages="total"
      :pagination="total > 10"
      :columns="permissionColumns"
      :rows="permissions"
      :enableAdd="enableAdd"
      @add="addNewPermission"
      @edit="editPermission"
      @pageChanged="pageChanged"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'access'">
          <div v-if="props.row.permissions.length === 0">
            <span class="no-campaign">{{ $t("users['no-permissions']") }}</span>
          </div>
          <div v-if="props.row.permissions.length === 1">
            <span>{{ $t("permissions['"+props.row.permissions[0]+"']") }}</span>
          </div>
          <div
            @click.stop="() => {}"
            v-else-if="props.row.permissions.length > 1"
          >
          <span
            v-for="permission in props.row.permissions"
            :key="permission">
            {{ $t("permissions['"+permission+"']") }}
          </span>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <supervisor-actions
      :permissionCompany="companyId"
      :edit="isEdit"
      :permission="infoPermission"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import listCompanyPermissions from '@/graphql/queries/users/supervisorsByCompanyId.gql';
import executeQuery from '@/utils/gql-api';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import EventBus from '@/eventBus';
import errorHandler from '@/utils/error.handler';
import { UPDATE_COMPANY_PERMISSIONS } from '@/eventTypes';

export default {
  props: {
    filters: {
      type: Object,
    },
    isOwner: {
      required: true,
      type: Boolean,
    },
    licences: {
      type: Boolean,
    },
    role: {
      type: Object,
    },
  },
  components: {
    OverlayLoader,
    SupervisorActions: () => import('@/components/users/SupervisorActions.vue'),
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
  },
  data() {
    return {
      page: 1,
      infoPermission: {},
      permissions: [],
      total: 0,
      isEdit: false,
      isLoading: false,
    };
  },
  created() {
    EventBus.$on(UPDATE_COMPANY_PERMISSIONS, () => {
      this.fetchPermission(this.page);
    });
  },
  mounted() {
    if (this.companyId !== 0) {
      this.fetchPermission(this.page);
    }
  },
  watch: {
    companyId(newVal) {
      this.page = 1;
      if (newVal && newVal !== 0) {
        this.fetchPermission(this.page);
      }
    },
    role() {
      this.page = 1;
      this.fetchPermission(1);
    },
  },
  computed: {
    ...mapGetters({
      permission: 'shared/permissionInfo',
    }),
    permissionColumns() {
      return [{
        label: this.$t('common.role'),
        field: 'role.display_name',
      }, {
        label: this.$t('supervisor.title'),
        field: 'access',
        sortable: false,
      }];
    },
    emptyRows() {
      return this.total === 0;
    },
    companyId() {
      return this.filters ? this.filters.companyId : null;
    },
    enableAdd() {
      return this.permissions.length === 0;
    },
  },
  methods: {
    async fetchPermission(page, text = null) {
      this.isLoading = true;
      try {
        const params = this.buildQuery(page, text);

        const { permissions, total } = await executeQuery(
          'listCompanyPermissions',
          listCompanyPermissions,
          params,
          false,
        );

        this.page = page;
        this.total = total;
        this.permissions = permissions;
      } catch (error) {
        errorHandler.logErrors(error);
        this.$toasted.global.error(this.$t('alerts.unexpectedError'));
      } finally {
        this.isLoading = false;
      }
    },
    buildQuery(page, text) {
      const params = {
        companyId: this.companyId,
        page,
        perPage: 10,
        text,
      };
      return params;
    },
    pageChanged(page) {
      this.page = page;
      this.fetchPermission(page);
    },
    editPermission(props) {
      if (this.validPermission(props.id)) {
        this.isEdit = true;
        this.infoPermission = props;
        this.$modal.show('Supervisor');
      } else {
        this.$toasted.global.error(this.$t('permissions.errors'));
      }
    },
    addNewPermission() {
      this.isEdit = false;
      this.$modal.show('Supervisor');
    },
    validPermission(id) {
      return this.permission.id !== id;
    },
  },
};
</script>

<style scoped lang='scss'>
::v-deep .campaign {
  width: 200px;
}

.tab-container {
  position: relative;
}

.no-campaign {
  font-weight: bold;
  color: $gray-disabled;
}
</style>
