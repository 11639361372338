<template>
  <div class="header global">
    <div class="grid-container full header">
      <div class="main-content" :class="{ 'canvas-collapse': collapse }">
        <div class="general-actions">
          <div class="menu-content__option">
            <breadcrumb :items="items" />
            <span v-if="showCampaignName" class="separator"> > </span>
            <h3 class="campaign-name" v-if="showCampaignName">
              {{ currentCampaign.name | truncate(30) }}
            </h3>
          </div>
          <div class="action-item">
            <div class="translate" @click="goReportsView()" v-if="allowReports">
              <hcc-button-icon
                v-tooltip="$t('header.reports')"
                :muted="false"
                color="accent"
              >
                <file-excel-outline class="button-report__link" />
              </hcc-button-icon>
            </div>
            <div class="translate">
              <language-button />
            </div>
            <a
              class="button-badge"
              href="javascript:void(0);"
              @click="toggleNotifications"
              v-tooltip.down="$t('app.notifications')"
            >
              <bell-outline
                aria-selected="false"
                :class="{ active: toogleMenu }"
                class="bell"
              />
              <span v-show="getNews" class="badge alert">{{ getNews }}</span>
            </a>
            <user-menu
              id="user_image"
              :profileAcronym="profileAcronym"
              :profileName="profileName"
            />
          </div>
          <!-- <div class="action-item">
            <img :src="require('@/assets/images/configuration-icon.svg')" alt="Configuration">
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import jwtDecode from 'jwt-decode';
import Breadcrumb from '@/components/Breadcrumb.vue';
import UserMenu from '@/components/UserMenu.vue';
import LanguageButton from '@/components/LanguageButton.vue';
import HccButtonIcon from '@/components/shared/HccButtonIcon/index.vue';

export default {
  components: {
    Breadcrumb,
    UserMenu,
    LanguageButton,
    HccButtonIcon,
  },
  computed: {
    ...mapState({
      collapse: state => state.dashboard.collapse,
      toogleMenu: state => state.notification.toogleMenu,
      product: state => state.product.item,
    }),
    ...mapGetters({
      accessToken: 'dashboard/getAccessToken',
      getNews: 'notification/getNews',
      currentCampaign: 'setting/getCurrentCampaign',
      getCurrentShop: 'shop/getCurrentShop',
    }),
    decodedData() {
      let decoded;

      try {
        decoded = jwtDecode(this.accessToken);
      } catch (e) {
        decoded = {};
      }

      return decoded;
    },
    profileName() {
      return this.decodedData && this.decodedData.name ? this.decodedData.name : '???';
    },
    allowReports() {
      const userInfo = this.decodedData;
      if (userInfo.role === 'supervisor') {
        return userInfo.permissions ? userInfo.permissions.includes('reports') : false;
      }
      return true;
    },
    profileAcronym() {
      return this.profileName.substr(0, 1);
    },
    storeDetailId() {
      return this.$route?.params?.id;
    },
    items() {
      if (this.$route.path === '/dashboard') {
        return this.breadcrumb.dashboard;
      }
      if (this.$route.path === '/send-templates') {
        return this.breadcrumb.templates;
      }
      if (this.$route.path === '/notifications') {
        return this.breadcrumb.notifications;
      }
      if (this.$route.path === '/campaigns') {
        return this.breadcrumb.campaigns;
      }
      if (this.$route.path.includes('/settings')) {
        return this.breadcrumb.campaignSettings;
      }
      if (this.$route.path.includes('/channels-settings')) {
        return this.breadcrumb.channelsSettings;
      }
      if (this.$route.path.includes('/messages')) {
        return this.breadcrumb.messages;
      }
      if (this.$route.path.includes('/team')) {
        return this.breadcrumb.team;
      }
      if (this.$route.path.includes('/permissions')) {
        return this.breadcrumb.permissions;
      }
      if (this.$route.path.includes('/users')) {
        return this.breadcrumb.users;
      }
      if (this.$route.path.includes('/company')) {
        return this.breadcrumb.companies;
      }
      if (this.$route.path.includes('/reports')) {
        return this.breadcrumb.reports;
      }
      if (this.$route.path.includes('/blocked-clients')) {
        return this.breadcrumb.blockedClients;
      }
      if (this.$route.name === 'store.admin.sellia') {
        return this.breadcrumb.storeDetail;
      }
      if (this.$route.name === 'store.mobile.sellia') {
        return this.breadcrumb.marketplace;
      }
      if (this.$route.path.includes('/product')) {
        return this.breadcrumb.storeOptions;
      }
      if (this.$route.path.includes('/categories')) {
        return this.breadcrumb.categories;
      }
      if (this.$route.path.includes('/store')) {
        return this.breadcrumb.store;
      }
      return [];
    },
    isCampaignSetting() {
      return this.$route.name === 'campaigns-settings';
    },
    isChannelSetting() {
      return this.$route.name === this.$t('campaigns.cards.channels-settings');
    },
    isBlockedClients() {
      return this.$route.name === 'campaigns-blocked-clients';
    },
    showCampaignName() {
      return (this.isCampaignSetting && this.currentCampaign)
                || (this.isChannelSetting && this.currentCampaign)
                || (this.isBlockedClients && this.currentCampaign);
    },
    showStoreName() {
      return (this.storeDetailId && this.getCurrentShop);
    },
    breadcrumb() {
      return {
        dashboard: [{
          text: this.$t('app.dashboard'),
          disabled: false,
          href: '/dashboard',
        }],
        templates: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.send-templates'),
            disabled: false,
            href: '/send-templates',
          },
        ],
        notifications: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: 'dashboard',
          },
          {
            text: this.$t('app.notifications'),
            disabled: false,
            href: 'notifications',
          },
        ],
        campaigns: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.campaigns'),
            disabled: false,
            href: '/campaigns',
          },
        ],
        campaignSettings: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.campaigns'),
            disabled: true,
            href: '/campaigns',
          },
          {
            text: this.$t('app.campaign-settings'),
            disabled: false,
            href: '',
          },
        ],
        shipments: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.company-send'),
            disabled: false,
            href: '/shipments',
          },
        ],
        channelsSettings: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.campaigns'),
            disabled: true,
            href: '/campaigns',
          },
          {
            text: this.$t('app.channels-settings'),
            disabled: false,
            href: '/messages',
          },
        ],
        messages: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.messages'),
            disabled: false,
            href: '/messages',
          },
        ],
        team: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.team'),
            disabled: false,
            href: '/team',
          },
        ],
        permissions: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.permissions'),
            disabled: false,
            href: '/permissions',
          },
        ],
        users: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.users'),
            disabled: false,
            href: '/users',
          },
        ],
        companies: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.company'),
            disabled: false,
            href: '/company',
          },
        ],
        reports: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.reports'),
            disabled: false,
            href: '/reports',
          },
        ],
        blockedClients: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.campaigns'),
            disabled: true,
            href: '/campaigns',
          },
          {
            text: this.$t('app.blocked-clients'),
            disabled: false,
            href: '',
          },
        ],
        store: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.store'),
            disabled: false,
            href: '/store',
          },
        ],
        storeOptions: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.store'),
            disabled: true,
            href: '/store',
          },
          {
            text: this.$t('app.store-detail'),
            disabled: true,
            href: `/store/${this.storeDetailId}/edit`,
          },
          {
            text: this.product.nombre,
            disabled: false,
            href: '',
          },
        ],
        storeDetail: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.store'),
            disabled: true,
            href: '/store',
          },
          {
            text: this.$t('app.store-detail'),
            disabled: false,
            href: `/store/${this.storeDetailId}/edit`,
          },
        ],
        marketplace: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.store'),
            disabled: true,
            href: '/store',
          },
          {
            text: this.$t('app.store-detail'),
            disabled: true,
            href: `/store/${this.storeDetailId}/edit`,
          },
          {
            text: this.$t('app.mobile'),
            disabled: false,
            href: `/store/${this.storeDetailId}/mobile`,
          },
        ],
        categories: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.store'),
            disabled: true,
            href: '/store',
          },
          {
            text: this.$t('app.store-detail'),
            disabled: true,
            href: `/store/${this.storeDetailId}/edit`,
          },
          {
            text: this.$t('app.categories'),
            disabled: false,
            href: `/store/${this.storeDetailId}/categories`,
          },
        ],
      };
    },
  },
  methods: {
    toggleNotifications() {
      this.$emit('open-notifications');
    },
    goReportsView() {
      if (!this.$route.path.includes('/reports')) {
        this.$router.push('/reports');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_header.scss";
</style>
