<template>
  <div class="user">
    <div class="user__filter">
      <p
        class="user__company__info licence"
        v-if="activeLicenses <= 0 && !isOwner"
      >
        {{ $t("users. licenses-legend") }}
      </p>
      <template>
        <button-filter :display="enabledFilters" @change="onFiltersChanged">
        </button-filter>
      </template>
    </div>
    <filters :display="enabledFilters" />
    <section class="main-content">
      <component
        :isOwner="isOwner"
        :licences="total > 0"
        :is="component"
        :filters="filters"
      ></component>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import permissionActions from '@/graphql/mutations/permissionActions.gql';
import EventBus from '@/eventBus';
import { UPDATE_COMPANY_PERMISSIONS, NEW_PERMISSION } from '@/eventTypes';
import errorHandler from '@/utils/error.handler';
import Filters from '@/components/Filters.vue';


export default {
  components: {
    Filters,
    DataCardSmall: () => import('@/components/DataCardSmall.vue'),
    ButtonFilter: () => import('@/components/ButtonFilter.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
    HccButton: () => import('@/components/shared/HccButton/index.vue'),
    Supervisor: () => import('@/components/users/Supervisor.vue'),
  },
  data() {
    return {
      filter: false,
      takenLicenses: 0,
      supers: 0,
      admins: 0,
      filters: null,
      component: 'supervisor',
      enabledFilters: ['company'],
    };
  },
  created() {
    EventBus.$on(NEW_PERMISSION, ({ payload }) => {
      this.updatePermissionsCompany(payload);
    });
    this.fetchRoles();
  },
  computed: {
    ...mapGetters({
      isOwner: 'shared/isOwner',
      companies: 'shared/getFilterCompanies',
      roles: 'shared/getRoles',
    }),
    activeLicenses() {
      return this.currentCompany ? this.currentCompany.maxUsers : 0;
    },
    total() {
      return this.activeLicenses - this.takenLicenses;
    },
    currentCompanyId() {
      return this.isOwner ? this.filterCompany : this.userCompany.id;
    },
    currentCompany() {
      return this.companies.find(({ id }) => id === this.currentCompanyId);
    },
    userCompany() {
      return JSON.parse(localStorage.currentCompany);
    },
    filterCompany() {
      return this.filters ? this.filters.companyId : null;
    },
    agents() {
      return this.activeLicenses - this.takenLicenses;
    },
    allRoles() {
      return [{
        display_name: 'Todos',
        id: 4,
      }].concat(this.roles);
    },
    supportUrl() {
      return process.env.VUE_APP_SUPPORT;
    },
  },
  mounted() {
    if (this.isOwner) {
      this.enabledFilters.push('company');
    }
  },
  methods: {
    ...mapActions({ fetchRoles: 'shared/fetchRoles' }),
    async updatePermissionsCompany(payload) {
      try {
        await this.$apollo.mutate({
          mutation: permissionActions,
          variables: payload,
        });

        EventBus.$emit(UPDATE_COMPANY_PERMISSIONS);
      } catch (error) {
        this.$toasted.global.error(this.$t('users.errors.repeated-email'));
        errorHandler.logErrors(error);
      }
    },
    onFiltersChanged(filters) {
      this.filters = filters;
    },
    openChatCenter() {
      window.open(this.supportUrl, '_blank').focus();
    },
  },
};

</script>

<style scoped lang="scss">
@import "~styles/components/_campaign-configuration.scss";
@import "~styles/views/_user.scss";
</style>
