<template>
  <div class="buttons" :class="{ 'buttons-disabled': disabled }">
    <div v-tooltip="$t('campaigns.view-cards')" class="buttons__icon"
      @click="toggleShow(true)">
      <crop-square-icon />
    </div>
    <div v-tooltip="$t('campaigns.view-list')" class="buttons__icon"
      @click="toggleShow(false)">
      <format-list-bulleted-icon />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleShow(showCards) {
      this.$emit('change-view', showCards);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_change-view.scss";
</style>
