<template>
  <div class="grid-x grid-margin-x">
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'pendingCards'"
        :theme="'green'"
        :name="$t('dashboard.orders.orders-to-prepare')"
        :value="pending"
      >
         <check-circle />
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'deliveryCards'"
        :theme="'blue'"
        :name="$t('dashboard.orders.orders-to-be-delivered')"
        :value="delivery"
      >
        <bike />
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'deliveredCards'"
        :theme="'red'"
        :name="$t('dashboard.orders.orders-delivered')"
        :value="delivered"
      >
        <handshake-outline />
      </data-card>
    </div>
  </div>
</template>

<script>
import DataCard from '@/components/DataCard.vue';

export default {
  components: {
    DataCard,
  },
  props: {
    pending: {
      type: Number,
      default: 0,
    },
    delivery: {
      type: Number,
      default: 0,
    },
    delivered: {
      type: Number,
      default: 0,
    },
  },
};
</script>
