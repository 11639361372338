<template>
  <div class="table-container tab-container">
    <overlay-loader :loading="isLoading" />
    <hcc-table
      :totalPages="total"
      :pagination="total > 10"
      :rowsPerPage="10"
      :actualPage="actualPage"
      :rows="rows"
      :columns="columns"
      @add="create"
      @edit="editShipment"
      @pageChanged="changePage"
    >

      <template slot="table-row" slot-scope="props">

        <span v-if="props.column.field == 'campaigns'">

          <div v-if="props.row.campaigns.length === 0">
            <span class="no-campaign">{{ $t("users['no-campaigns']") }}</span>
          </div>

          <div v-if="props.row.campaigns.length === 1">
            <span>{{ props.row.campaigns[0].name }}</span>
          </div>
          <div v-if="props.row.campaigns.length > 1">
            <hcc-dropdown custom :position="getDropdownPosition(props.row.originalIndex)">
              <template #button>
                <hcc-button variant="outline" color="muted" size="sm">
                  {{ $t("users['show-campaigns']") }}
                </hcc-button>
              </template>
        <hcc-dropdown-item unclickable v-for="campaign in props.row.campaigns" :key="campaign.id">
          {{ campaign.name }}
        </hcc-dropdown-item>

          </hcc-dropdown>
          </div>
        </span>

      </template>

    </hcc-table>
    <hcc-modal
      @confirm="saveShipment"
      @opened="focusInput"
      @closed="clear"
      :confirmDisableButton="!enableSave"
      name="shipment"
      :title="edit ? $t('shipment.edit-title') : $t('shipment.title')"
    >
      <div class="modal-company">
        <hcc-input
          ref="name"
          class="modal-company__item"
          v-model="name"
          :label="$t('company.name')"
          requiredInput
          @keyup.enter="submit"
          :maxLength="500"
        />

      </div>

      <div class="filters">
        <hcc-select
          v-model="filterSelection"
          :label="$t('shipment.campaing')"
          track-by="id"
          optionLabel="name"
          :options="campaigns"
          :placeholder="$t('multiselect.placeholder')"
          :multiple="true"
          :allowEmpty="true"
          :close-on-select="false"
          :limit-text="filterLimit"
          :limit="1"
          :requiredInput="true"
          customClass="select"
          :disabled="isLoading"
          :clear-on-select="false"
          @select="selected"
          :taggable="false"
          @remove="remove"
        />
      </div>

    </hcc-modal>

  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import executeQuery from '@/utils/gql-api';
import errorHandler from '@/utils/error.handler';
import listDeliveryGql from '@/graphql/queries/listDeliveryCompany.gql';
import listCampaignWithShopGql from '@/graphql/queries/listCampaignWithShop.gql';
import updateDeliveryGql from '@/graphql/mutations/shipments/updateDeliveryCompany.gql';
import createDeliveryGql from '@/graphql/mutations/shipments/createDeliveryCompany.gql';

export default {
  components: {
    OverlayLoader,
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccDropdown: () => import('@/components/shared/HccDropdown/index.vue'),
    HccButton: () => import('@/components/shared/HccButton/index.vue'),
    HccDropdownItem: () => import('@/components/shared/HccDropdown/DropdownItem.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
  },
  data() {
    return {
      isLoading: false,
      rows: [],
      edit: false,
      actualPage: 1,
      shipment: null,
      name: null,
      total: 0,
      filterSelection: [],
      campaigns: [],
    };
  },
  mounted() {
    this.fetchCampaign();
    this.fetchShipment();
    this.buildRows();
  },
  computed: {
    ...mapGetters({
      shipments: 'shared/getShipments',
    }),
    columns() {
      return [{
        label: this.$t('company.name'),
        field: 'name',
      },
      {
        label: this.$t('app.store'),
        field: 'campaigns',
      }];
    },
    enableSave() {
      return this.name != null;
    },
  },
  watch: {
    shipments() {
      this.buildRows();
    },
  },
  methods: {
    ...mapMutations({
      setShipments: 'shared/setShipments',
    }),
    async fetchShipment() {
      this.isLoading = true;
      const shipping = await executeQuery('listDeliveryCompany', listDeliveryGql, {}, false);
      this.isLoading = false;
      this.setShipments(shipping);
    },
    async fetchCampaign() {
      const shops = await executeQuery('listCampaignWithShop', listCampaignWithShopGql, {}, false);

      if (shops) {
        this.campaigns = shops.map(({ id, name }) => ({ id, name }));
      }
    },
    changePage(newPage) {
      this.actualPage = newPage;
    },
    create() {
      this.filterSelection = [];
      this.edit = false;
      this.$modal.show('shipment');
    },
    buildRows() {
      const shipmentsMap = this.shipments.map((s) => {
        const campaings = s.campaigns.map(({ id, name }) => ({ id, name, $isDisabled: true }));
        return {
          ...s,
          campaings,
        };
      });
      this.rows = shipmentsMap;
    },
    editShipment(props) {
      this.edit = true;
      this.shipment = props;
      this.name = props.name;
      this.filterSelection = props.campaigns;
      this.$modal.show('shipment');
    },
    buildRequest() {
      const obj = {};
      obj.name = this.name;
      obj.campaigns = this.filterSelection.map(({ id, name }) => ({ id, name }));
      if (this.edit) {
        obj.id = this.shipment.id;
      }
      return obj;
    },
    async saveShipment() {
      this.isLoading = true;

      try {
        await this.$apollo.mutate({
          mutation: this.edit ? updateDeliveryGql : createDeliveryGql,
          variables: this.buildRequest(),
        });
        await this.fetchShipment();
      } catch (error) {
        errorHandler.logErrors(error);
        this.$toasted.global.error(this.$t('common.error-saving'));
      } finally {
        this.isLoading = false;
      }
    },
    focusInput() {
      this.$refs.name.focus();
    },
    clear() {
      this.name = null;
    },
    submit() {
      if ((this.name.length > 0)) {
        this.saveShipment();
        this.$modal.hide('shipment');
      }
    },
    getDropdownPosition(index) {
      return index === this.total - 1 ? 'top-right' : 'bottom-right';
    },
    filterLimit() {
      return this.$t('multiselect.limit-suffix', { count: this.filterSelection.length - 1 });
    },
    remove() {

    },
    selected() {

    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/companies.scss";
@import "~styles/components/_user.scss";

::v-deep .campaign {
  width: 200px;
}
.filters {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;

      ::v-deep .multiselect {
        max-width: 350px;
        min-width: 190px;
        margin-right: 5px;

        &__tag {
          max-width: 200px;
        }
      }

      ::v-deep .vue-daterange-picker {
        min-width: 160px;
        margin-right: 5px;

        > .reportrange-text {
          padding-right: 2.5rem;
          background-size: 15px 17px;
          background-position: right -1.75rem center;
          background-image: url($images-dir + "calendar-icon.svg");
        }
      }
}

.tab-container {
  position: relative;
}

.no-campaign {
  font-weight: bold;
  color: $gray-disabled;
}
</style>
