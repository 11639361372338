<template>
  <hcc-modal
    name="assign-supervisor-modal"
    :title="modalName"
    :width="800"
    :confirmDisableButton="selectedSupervisors.length === 0"
    @confirm="handleConfirm"
    @opened="getOperatos"
    @closed="clear"
  >
    <div class="table-container" @click.stop="() => {}">
      <overlay-loader :loading="pending"/>
      <hcc-checkbox-table
        :searchService="true"
        :columns="columns"
        :rows="supervisors"
        :pagination="pagination.total > 5"
        :rowsPerPage="5"
        :actualPage="pagination.page"
        :totalPages="pagination.total"
        @search="searchUser"
        @pageChanged="changePage"
        @checked="getCheckedSupervisors"
        @check-all="getCheckedSupervisors"
      />
    </div>
  </hcc-modal>
</template>

<script>
import { fetchUnassignedUsers } from '@/utils/userByCompany';
import HccModal from '@/components/shared/HccModal/index.vue';
import HccCheckboxTable from '@/components/shared/HccTable/HccCheckboxTable.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import assignUsers from '@/graphql/mutations/users/assignUsers.gql';
import errorHandler from '@/utils/error.handler';

export default {
  components: {
    HccModal,
    HccCheckboxTable,
    OverlayLoader,
  },
  props: {
    campaign: [Object, null],
  },
  data() {
    return {
      text: null,
      pagination: {
        total: 0,
        page: 1,
        length: 5,
      },
      supervisors: [],
      selectedSupervisors: [],
      pending: null,
    };
  },
  computed: {
    modalName() {
      return this.$t('campaign-settings.assigned-users.assign-operators');
    },
    userLabel() {
      return this.$t('campaign-settings.assigned-users.user');
    },
    emailLabel() {
      return this.$t('campaign-settings.assigned-users.email');
    },
    columns() {
      return [{
        label: this.$t('campaign-settings.assigned-users.user'),
        field: 'name',
      }, {
        label: this.$t('campaign-settings.assigned-users.email'),
        field: 'email',
      }];
    },
  },
  methods: {
    getCheckedSupervisors(rows) {
      this.selectedSupervisors = rows;
    },
    async getOperatos() {
      this.pending = true;

      const result = await fetchUnassignedUsers(
        this.pagination.page,
        this.campaign.company,
        this.campaign.id,
        this.pagination.length,
        this.text,
        'operator',
      );

      this.pagination.total = result.total;
      this.pagination.page = result.page;

      this.supervisors = result.data;

      this.pending = false;
    },
    handleConfirm() {
      const params = this.selectedSupervisors.map(supervisor => ({
        user: supervisor.id,
        campaign: this.campaign.id,
      }));

      this.assignOperatorToCampaign(params);
    },
    async assignOperatorToCampaign(users) {
      try {
        await this.$apollo.mutate({
          mutation: assignUsers,
          variables: { users, role: 'operator' },
        });
        this.$emit('update');
        if (users.length > 1) {
          this.$toasted.global.success(this.$t('campaign-settings.assigned-users.assign-supervisors-success', { campaign: this.campaign.name }));
        } else {
          this.$toasted.global.success(this.$t('campaign-settings.assigned-users.assign-supervisors-success', { campaign: this.campaign.name }));
        }
      } catch (err) {
        errorHandler.logErrors(err);
        this.$toasted.global.error(this.$t('alerts.unexpectedError'));
      }
    },
    searchUser(value) {
      this.pagination.page = 1;
      if (value.length >= 3) {
        this.text = value;
      } else if (value.length === 0) {
        this.text = null;
      }
      this.getOperatos();
    },
    changePage(page) {
      this.pagination.page = page;
      this.getOperatos();
    },
    clear() {
      this.text = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/settings/_users-tab.scss";
</style>
