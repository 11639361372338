/* eslint-disable import/prefer-default-export */
import Vue from 'vue';
import campaignsActions from './actions/campaignActions';

export const setting = {
  namespaced: true,
  state: {
    channels: [
      {
        id: 1,
        name: 'Whastapp',
      },
      {
        id: 2,
        name: 'Telegram',
      },
      {
        id: 3,
        name: 'Messenger',
      },
    ],
    agentsAssignList: [],
    currentCampaign: {},
    campaigns: [],
    currentChannel: {},
  },
  getters: {
    getCurrentCampaign(state) {
      return state.currentCampaign;
    },
    getCampaigns(state) {
      return state.campaigns.map(el => ({
        ...el,
        loading: false,
      }));
    },
    getCurrentChannel(state) {
      return state.currentChannel;
    },
  },
  mutations: {
    setAgentsAssignList(state, payload) {
      state.agentsAssignList = payload;
    },
    deleteAssignUser(state, payload) {
      const index = state.agentsAssignList.findIndex(agent => agent.id === payload);
      if (index !== -1) {
        state.agentsAssignList.splice(index, 1);
      }
    },
    deleteAssignUsers(state) {
      state.agentsAssignList = [];
    },
    setCurrentCampaign(state, payload) {
      state.currentCampaign = payload;
    },
    clearCurrentCampaign(state) {
      state.currentCampaign = null;
    },
    setCampaigns(state, event) {
      state.campaigns = event;
    },
    setCurrentChannel(state, payload) {
      state.currentChannel = payload;
    },
    setCampaignChange(state, payload) {
      const index = state.campaigns.findIndex(c => c.id === payload.id);
      Vue.set(state.campaigns, index, payload);
    },
    setCampaignField(state, { data, type, campaign }) {
      const index = state.campaigns.findIndex(c => c.id === campaign);
      if (index !== -1) {
        Vue.set(state.campaigns[index], type, data);
      }
    },
  },
  actions: {
    ...campaignsActions,
  },
};
