<template>
  <div class="loader-container">
    <overlay-loader :loading="loading" />
    <div class="flex-container align-middle align-justify item-card status-price">
      <div class="column-left text">
        <p class="text-subtitle">{{ $t('shop.product.wholesale.text-status.title') }}</p>
        <p class="paragraph small">{{ $t('shop.product.wholesale.text-status.sub-text') }}</p>
      </div>
      <div class="column-right price">
        <hcc-toggle-switch
          name="toggle-wholesale-status"
          :value="wholesalePriceStatus"
          @change="toggleStatusWholesale"
        />
      </div>
    </div>
    <hcc-table
      :columns="columns"
      :rowsPerPage="10"
      :rows="WholesalePrice"
      @edit="editWholesale"
      @add="openAddGroup"
      @delete="removeWholesale"
      @pageChanged="changePage"
      :actualPage="actualPage"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'minimumAmount'">
          {{ props.row.minimumAmount }}
        </span>
        <span v-if="props.column.field === 'maxAmount'">
          {{ props.row.maxAmount }}
        </span>
        <span v-if="props.column.field === 'price'">
          {{ formatNumber(props.row.price) }}
        </span>
        <span v-if="props.column.field === 'status'">
          {{ validateStatusWholesale(props.row.status) }}
        </span>
      </template>
    </hcc-table>
    <WholesaleModalForm
      :dataWholesale="dataWholesale"
      @onEditWholesale="loadProducts"
    />
  </div>
</template>

<script>
import numeral from 'numeral';
import { mapGetters, mapActions, mapState } from 'vuex';
import removeWholesalePriceInProductGql from '@/graphql/mutations/product/removeWholesalePriceInProduct.gql';
import editStatusWholesalePriceInProductGql from '@/graphql/mutations/product/editStatusWholesalePriceInProduct.gql';


export default {
  components: {
    OverlayLoader: () => import('@/components/loaders/OverlayLoader.vue'),
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    WholesaleModalForm: () => import('@/components/shop/products/wholesale/ModalForm.vue'),
    HccToggleSwitch: () => import('@/components/shared/HccToggleSwitch/index.vue'),
  },
  data() {
    return {
      loading: true,
      dataWholesale: {},
      actualPage: 1,
      wholesalePriceStatus: false,
    };
  },
  computed: {
    ...mapState({ product: state => state.product.item }),
    ...mapGetters({
      WholesalePrice: 'product/getWholesalePrice',
    }),
    idProduct() {
      return this.$route.params.idProduct;
    },
    columns() {
      return [
        {
          label: this.$t('shop.product.wholesale.minimum'),
          field: 'minimumAmount',
        }, {
          label: this.$t('shop.product.wholesale.maximum'),
          field: 'maxAmount',
        }, {
          label: this.$t('shop.product.wholesale.price'),
          field: 'price',
        }, {
          label: this.$t('shop.product.wholesale.status'),
          field: 'status',
        },
      ];
    },
  },
  watch: {
    product(newValue) {
      this.wholesalePriceStatus = newValue.wholesalePriceStatus;
    },
  },
  methods: {
    ...mapActions({
      getProductById: 'product/getProductById',
    }),
    async toggleStatusWholesale() {
      this.wholesalePriceStatus = !this.wholesalePriceStatus;
      this.editStatusWholesale();
    },
    async editStatusWholesale() {
      await this.$apollo.mutate({
        mutation: editStatusWholesalePriceInProductGql,
        variables: {
          productId: this.idProduct,
          wholesalePriceStatus: this.wholesalePriceStatus,
        },
      });
    },
    changePage(newPage) {
      this.actualPage = newPage;
    },
    formatNumber(value) {
      return numeral(value).format('$0,0.00');
    },
    validateStatusWholesale(status) {
      return status ? this.$t('shop.product.wholesale.active') : this.$t('shop.product.wholesale.inactive');
    },
    editWholesale(row) {
      this.dataWholesale = row;
      this.$modal.show('form-wholesale');
    },
    openAddGroup() {
      this.dataWholesale = {};
      this.$modal.show('form-wholesale');
    },
    removeWholesale(row) {
      this.$modal.show('confirmation', {
        title: this.$t('shop.product.wholesale.remove.title'),
        description: this.$t('shop.product.wholesale.remove.confirm-remove'),
        variant: 'default',
        confirm: () => this.removeWholesaleConfirm(row),
      });
    },
    async removeWholesaleConfirm({ id }) {
      await this.$apollo.mutate({
        mutation: removeWholesalePriceInProductGql,
        variables: {
          productId: this.idProduct,
          wholesalePriceId: id,
        },
      });
      await this.loadProducts();
    },
    async loadProducts() {
      this.loading = true;
      await this.getProductById(this.idProduct);
      this.loading = false;
    },
  },
  async created() {
    await this.loadProducts();
  },
};
</script>

<style scoped lang="scss">
.status-price {
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
    .text, .price {
      padding: 10px;
      margin: 0;
    }
  }
</style>
