<template>
  <div class="channels__info">
    <div class="channels__group">
      <hcc-input
        :label="whatsappSettings['api-key']"
        v-model="apiKey"
        customClass="channels__input"
        :disabled="pending"
        @input="changeData($event, 'apiKey')"
      />
    </div>
    <div class="channels__group">
      <hcc-select
        track-by="value"
        optionLabel="name"
        :placeholder="whatsappSettings.provider"
        :label="whatsappSettings.provider"
        :options="providers"
        :value="provider"
        customClass="channels__select"
        @select="selectProvider"
      />
    </div>
    <div class="channels__group" v-if="isGupshup">
      <hcc-input
        label="Source"
        v-model="source"
        customClass="channels__input"
        :disabled="pending"
        @input="changeData($event, 'source')"
      />
    </div>
    <div class="channels__group" v-if="isGupshup">
      <hcc-input
        label="APP Name"
        v-model="appName"
        customClass="channels__input"
        :disabled="pending"
        @input="changeData($event, 'appName')"
      />
    </div>
    <div class="channels__group">
      <hcc-input
        :label="whatsappSettings['id-admin']"
        v-model="id"
        customClass="channels__input"
        :disabled="pending"
        @input="changeData($event, 'businessId')"
      />
    </div>
    <div class="channels__file">
      <span>{{ whatsappSettings["attach-logo"] }}</span>
      <div class="channels__file-drop">
        <vue-file-agent
          ref="dropZone"
          :compact="false"
          :deletable="true"
          :helpText="whatsappSettings['select-file']"
          :maxFiles="1"
          :meta="false"
          :multiple="false"
          @select="onFileSelected"
          @beforedelete="onDeleteFile($event)"
        />
      </div>
      <div
        v-if="channel.extras && channel.extras.waLogo"
        class="channels__file-logo"
      >
        {{ whatsappSettings["actual-logo"] + ":  " + channel.extras.waLogo }}
      </div>
    </div>
    <hcc-card
      :title="whatsappSettings['business-profile']"
      class="channels__profile"
      bodyClass="channels__profile-body"
    >
      <div class="channels__group">
        <hcc-input
          :label="whatsappSettings.about"
          v-model="about"
          customClass="channels__input"
          :disabled="pending"
          @input="changeData($event, 'about')"
        />
      </div>
      <div class="channels__group">
        <hcc-input
          :label="whatsappSettings.address"
          v-model="address"
          customClass="channels__input"
          :disabled="pending"
          @input="changeData($event, 'address')"
        />
      </div>
      <div class="channels__group">
        <hcc-input
          :label="whatsappSettings['business-desc']"
          v-model="description"
          customClass="channels__input"
          :disabled="pending"
          @input="changeData($event, 'description')"
        />
      </div>
      <div class="channels__group">
        <hcc-select
          track-by="id"
          optionLabel="name"
          :allowEmpty="true"
          :placeholder="whatsappSettings.category"
          :label="whatsappSettings.category"
          :options="categoryOptions"
          :value="displayCategory()"
          customClass="channels__select"
          requiredInput
          :error="hasError()"
          :errorMessage="whatsappSettings['error-category']"
          :disabled="pending"
          @select="selectCategory"
          @remove="unselectCategory"
        />
      </div>
      <div class="channels__group">
        <hcc-input
          :label="whatsappSettings['contact-email']"
          v-model="email"
          customClass="channels__input"
          :disabled="pending"
          @input="changeData($event, 'email')"
        />
      </div>
      <div class="channels__group">
        <hcc-input
          :label="whatsappSettings.website"
          v-model="website"
          customClass="channels__input"
          :disabled="pending"
          @input="changeData($event, 'website')"
        />
      </div>
    </hcc-card>
  </div>
</template>

<script>
import HccInput from '@/components/shared/HccInput/index.vue';
import HccCard from '@/components/shared/HccCard/index.vue';
import HccSelect from '@/components/shared/HccSelect/index.vue';

export default {
  components: {
    HccInput,
    HccCard,
    HccSelect,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      appName: null,
      source: null,
      categoryOptions: [
        { id: 1, name: 'Automotive' },
        { id: 2, name: 'Beauty, Spa and Salon' },
        { id: 3, name: 'Clothing and Apparel' },
        { id: 4, name: 'Education' },
        { id: 5, name: 'Entertainment' },
        { id: 6, name: 'Event Planning and Service' },
        { id: 7, name: 'Finance and Banking' },
        { id: 8, name: 'Food and Grocery' },
        { id: 9, name: 'Public Service' },
        { id: 10, name: 'Hotel and Lodging' },
        { id: 11, name: 'Medical and Health' },
        { id: 12, name: 'Non-profit' },
        { id: 13, name: 'Professional Services' },
        { id: 14, name: 'Shopping and Retail' },
        { id: 15, name: 'Travel and Transportation' },
        { id: 16, name: 'Restaurant' },
        { id: 17, name: 'Other' },
      ],
      providers: [
        { name: 'GupShup', value: 'gupshup' },
        { name: '360Dialog', value: '360' },
      ],
      apiKey: '',
      id: '',
      about: '',
      address: '',
      description: '',
      category: '',
      provider: '',
      email: '',
      website: '',
      extras: {},
    };
  },
  inject: ['isPending'],
  computed: {
    whatsappSettings() {
      return this.$t('channels-settings.whatsapp');
    },
    pending() {
      return this.isPending();
    },
    isGupshup() {
      return this.provider.value === 'gupshup';
    },
  },
  methods: {
    setData() {
      this.apiKey = this.channel.extras ? this.channel.extras.apiKey : '';
      this.id = this.channel.extras ? this.channel.extras.businessId : '';
      this.about = this.channel.extras ? this.channel.extras.about : '';
      this.address = this.channel.extras ? this.channel.extras.address : '';
      this.description = this.channel.extras ? this.channel.extras.description : '';
      this.category = this.channel.extras ? this.channel.extras.category : '';
      this.setProvider();
      this.email = this.channel.extras ? this.channel.extras.email : '';
      this.website = this.channel.extras ? this.channel.extras.website : '';
      this.source = this.channel.extras ? this.channel.extras.source : '';
      this.appName = this.channel.extras ? this.channel.extras.appName : '';

      const { __typename, ...extras } = (this.channel.extras || {});
      this.extras = extras;
    },
    setProvider() {
      this.provider = {};
      if (this.channel.extras && this.channel.extras.waProvider) {
        this.provider = this.providers
          .find(({ value }) => this.channel.extras.waProvider === value);
      }
    },
    displayCategory() {
      let selected = null;

      if (this.category) {
        selected = this.categoryOptions
          .find(category => category.name === this.category);
      }

      return selected;
    },
    selectCategory(value) {
      this.category = value.name;
      this.extras.category = this.category;
    },
    unselectCategory() {
      this.category = '';
      this.extras.category = this.category;
    },
    changeData(value, key) {
      this.extras[key] = value;
      this.whatsappExtras();
    },
    whatsappExtras() {
      this.$emit('update-extras', this.extras);
    },
    selectProvider(value) {
      this.provider = value;
      this.extras.waProvider = this.provider.value;
      this.whatsappExtras();
    },
    hasError() {
      let error = false;

      if (!this.category) {
        if (this.about || this.address || this.description || this.email || this.website) {
          error = true;
        }
      }
      this.$emit('error', error);
      return error;
    },
    onFileSelected(file) {
      this.extras.waLogo = file[0].file.name;
      this.$emit('add-logo', file[0].file);
    },
    onDeleteFile(file) {
      this.$refs.dropZone.deleteFileRecord(file);
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
