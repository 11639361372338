<template>
  <div>
    <WholesaleList/>
  </div>
</template>

<script>
export default {
  components: {
    WholesaleList: () => import('@/components/shop/products/wholesale/List.vue'),
  },
};
</script>
