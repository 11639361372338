<template>
  <span>
    <transition name="fade">
      <settings-warning v-if="hasChanges" style="animation-duration: 0.5s" />
    </transition>
    <div class="messages-tab">
      <div class="grid-x">
        <hcc-card-header :title="$t('configuration.tabs.automatic-messages')" />
        <hcc-card class="cell large-6 custom-cell" color="gray">
          <message
            v-model="getMessage('welcomeMessage').modalValue"
            :title="$t('configuration.messages.welcome-message')"
            :description="$t('configuration.messages.welcome-description')"
            @update="updateData($event, 'welcomeMessage')"
          />
          <message
            v-model="getMessage('messageOutOfService').modalValue"
            :title="$t('configuration.messages.message-out-service')"
            :description="$t('configuration.messages.oos-message')"
            @update="updateData($event, 'messageOutOfService')"
          />
          <message
            v-model="getMessage('agentsOfflineMessage').modalValue"
            :title="$t('configuration.messages.agents-offline-message')"
            :description="$t('configuration.messages.no-agents-message')"
            @update="updateData($event, 'agentsOfflineMessage')"
          />
          <message
            v-model="getMessage('unattendedMessage').modalValue"
            :title="$t('configuration.messages.unattended-message')"
            :description="$t('configuration.messages.automatic-logout-message')"
            @update="updateData($event, 'unattendedMessage')"
          />
          <hcc-card>
            <div class="message__filter-container">
              <hcc-select
                :label="$t('configuration.messages.category.title')"
                customClass="message__filter"
                track-by="title"
                option-label="title"
                :options="categories"
                v-model="categoryId.modalValue"
                :disabled="unattendedEnabled"
              />
            </div>
            <div class="paragraph select">
              {{ $t("configuration.messages.category.description") }}
            </div>
          </hcc-card>
        </hcc-card>
        <hcc-card class="cell large-6 custom-cell" color="gray">
          <h3 v-if="previewList[0]" class="preview-title">
            {{ previewList[indexPreview].title }}
          </h3>
          <chat-preview
            ref="chatPreview"
            v-if="previewList[0]"
            :key="key"
            v-bind:messages="previewList[indexPreview].messages"
            :iterate="!isIterate"
            v-on:animationCompleted="animationCompleted"
          />
          <chat-preview v-else v-bind:messages="[]" :iterate="false" />
        </hcc-card>
        <hcc-card
          class="cell large-12 custom-cell"
          color="gray"
          :title="$t('configuration.messages.more-options')"
        >
          <message
            v-model="getMessage('unattendedReminderMessage').modalValue"
            :title="$t('configuration.messages.unattended-reminder-message.title')"
            :description="$t('configuration.messages.unattended-reminder-message.description')"
            @update="updateData($event, 'unattendedReminderMessage')"
          />
          <hcc-card>
            <hcc-input
              min="5"
              type="number"
              class="input"
              :disabled="reminderEnabled"
              :error="hasError"
              v-model.number="unattendedReminderInterval.modalValue"
              :label="$t('configuration.messages.unattended-reminder-interval.title')"
              :errorMessage="$t('configuration.messages.unattended-reminder-interval.error')"
            />
            <p class="paragraph">
              {{ $t("configuration.messages.unattended-reminder-interval.description") }}
            </p>
          </hcc-card>
        </hcc-card>
        <div class="cell flex-container align-right">
          <hcc-button
            color="muted"
            class="space"
            :disabled="!hasChanges"
            name="interval"
            @click="cancelUpdate()"
            >{{ $t("configuration.delete") }}
          </hcc-button>
          <hcc-button
            :disabled="!hasChanges || hasError || isSaving"
            @click="saveChanges()"
            >{{ $t("common.save") }}
          </hcc-button>
        </div>
      </div>
    </div>
    <transition name="fade">
      <hcc-confirmation :confirmFunction="handleConfirm" />
    </transition>
  </span>
</template>

<script>
import EventBus from '@/eventBus';
import {
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_DONE,
  UPDATE_CAMPAIGN_ERROR,
} from '@/eventTypes';
import ErrorHandler from '@/utils/error.handler';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import SettingsWarning from './SettingsWarning.vue';

export default {
  props: {
    campaign: {
      required: true,
    },
  },
  components: {
    ChatPreview: () => import('@/components/ChatPreview.vue'),
    HccCard: () => import('@/components/shared/HccCard/index.vue'),
    HccCardHeader: () => import('@/components/shared/HccCard/HccCardHeader.vue'),
    HccButton: () => import('@/components/shared/HccButton/index.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
    Message: () => import('@/components/Message.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccConfirmation,
    SettingsWarning,
  },
  data() {
    return {
      messagesList: [],
      previewList: [],
      key: null,
      indexPreview: 0,
      categories: [],
      isSaving: false,
      unattendedReminderInterval: {
        modalValue: 5,
        value: 5,
        name: 'unattendedReminderInterval',
      },
      categoryId: {
        modalValue: {},
        value: {},
        name: 'unattendedWarningCloseCategoryId',
      },
      valueOutOfService: {
        modalValue: true,
        value: false,
      },
      outOfServiceCategoryId: {
        modalValue: {},
        value: {},
        name: 'outOfServiceCategoryId',
      },
      outOfService: null,
    };
  },
  computed: {
    hasChanges() {
      const changes = this.messagesList.some(msg => msg.modalValue !== msg.value)
        || this.unattendedReminderInterval.value !== this.unattendedReminderInterval.modalValue
        || this.categoryId.value.id !== this.categoryId.modalValue.id;

      this.$emit('changed', changes);
      return changes;
    },
    isIterate() {
      return this.previewList.length > 1;
    },
    reminderEnabled() {
      return this.getMessage('unattendedReminderMessage').modalValue === '';
    },
    hasError() {
      return this.unattendedReminderInterval.modalValue < 5;
    },
    unattendedEnabled() {
      return this.getMessage('unattendedMessage').modalValue === '';
    },
  },
  watch: {
    'campaign.messages': function setMssages(newMessages) {
      if (newMessages && newMessages.lenght > 0) {
        this.buildMessages(newMessages);
      }
    },
    'campaign.categories': function setCategories(newCategories) {
      if (newCategories != null) {
        this.categories = newCategories;
        if (this.campaign && this.campaign.messages) {
          this.buildMessages(this.campaign.messages);
        }
      } else {
        this.categories = [];
      }
    },
    'campaign.config': function setConfig(newConfig) {
      if (newConfig) {
        this.addConfigMsg(newConfig);
      }
    },
  },
  created() {
    EventBus.$on(UPDATE_CAMPAIGN_DONE, (tab) => {
      this.isSaving = false;
      if (tab === 'Messages') {
        this.$toasted.global.success(this.$t('configuration.messages.updated'));
      }
    });
    EventBus.$on(UPDATE_CAMPAIGN_ERROR, (err) => {
      ErrorHandler.logErrors(err);
      this.isSaving = false;
    });
  },
  mounted() {
    this.categories = this.campaign.categories || [];
    this.addConfigMsg(this.campaign.config);
    this.buildMessages(this.campaign.messages);
  },
  destroyed() {
    EventBus.$off(UPDATE_CAMPAIGN_DONE);
    EventBus.$off(UPDATE_CAMPAIGN_ERROR);
  },
  methods: {
    addConfigMsg(config) {
      this.unattendedReminderInterval = Object.assign({
        value: config.unattendedReminderInterval,
        modalValue: config.unattendedReminderInterval,
      });

      const category = this.categories.find(c => c.id === config.unattendedWarningCloseCategoryId) || { id: '0' };
      this.categoryId = Object.assign({
        value: category,
        modalValue: category,
      });
      this.outOfService = config.closeConversationOutOfService;
    },
    buildMessages(messages) {
      this.key = this.randomID();
      this.messagesList = messages
        .map(({ value, name }) => ({ value, name, modalValue: value }));
      this.initValues();
    },
    cancelUpdate() {
      this.buildMessages(this.campaign.messages);
      this.addConfigMsg(this.campaign.config);
    },
    updateData(value, nameId) {
      const position = this.messagesList.findIndex(({ name }) => name === nameId);
      this.messagesList[position].modalValue = value;
    },
    saveChanges() {
      if (!this.getMessage('welcomeMessage').modalValue) {
        this.$toasted.global.error(this.$t('configuration.messages.error-welcome-message'));
      } else if (!this.validOutOfServiceOption()) {
        this.isSaving = true;
        const messages = this.messagesList
          .map(({ name, modalValue }) => ({ name, value: modalValue }));
        const configData = this.configChanges();

        const { __typename, sendAsTemplate, ...options } = this.campaign.config;

        const data = {
          id: this.campaign.id,
          messages,
          config: { ...options, ...configData },
        };

        EventBus.$emit(UPDATE_CAMPAIGN, { data, tab: 'Messages' });
      } else {
        this.$toasted.global.error(this.$t('configuration.messages.error-out-of-service'));
      }
    },
    validOutOfServiceOption() {
      return this.outOfService
        && this.getMessage('messageOutOfService').modalValue === '';
    },
    configChanges() {
      return {
        unattendedReminderInterval: this.unattendedReminderInterval.modalValue,
        unattendedWarningCloseCategoryId: this.categoryId.modalValue.id !== '0'
          ? this.categoryId.modalValue.id : null,
      };
    },
    getMessage(id) {
      const msg = this.messagesList.find(({ name }) => name === id);
      if (msg) {
        return msg;
      }
      this.messagesList.push({ modalValue: '', value: '', name: id });
      return this.messagesList[this.messagesList.length - 1];
    },
    initValues() {
      this.messagesList.forEach(({ name, value }) => {
        if (value) {
          switch (name) {
            case ('welcomeMessage'):
              this.previewWelcome(value);
              break;
            case ('messageOutOfService'):
              this.previewOutOfService(value);
              break;
            case ('agentsOfflineMessage'):
              this.previewAgentsOffline(value);
              break;
            case ('unattendedMessage'):
              this.previewUnattended(value);
              break;
            case ('unattendedReminderMessage'):
              this.previewReminder(value);
              break;
            default:
          }
        }
      });
    },
    previewWelcome(value) {
      const messages = [];

      messages.push({
        text: this.$t('configuration.messages.welcome-text'),
        time: '15:00',
        type: 'sent',
      });

      messages.push({
        text: value,
        time: '15:00',
        type: 'received',
      });

      this.previewList.push({
        messages,
        title: this.$t('configuration.messages.welcome-message'),
      });
    },
    previewOutOfService(value) {
      const messages = [];

      messages.push({
        text: this.$t('configuration.messages.out-of-service.text'),
        time: '22:30',
        type: 'sent',
      });

      messages.push({
        text: value,
        time: '22:30',
        type: 'received',
      });

      messages.push({
        text: this.$t('configuration.messages.out-of-service.confirm'),
        time: '22:30',
        type: 'sent',
      });

      this.previewList.push({
        messages,
        title: this.$t('configuration.messages.message-out-service'),
      });
    },
    previewAgentsOffline(value) {
      const messages = [];

      messages.push({
        text: this.$t('configuration.messages.agents-offline'),
        time: '16:00',
        type: 'sent',
      });

      messages.push({
        text: value,
        time: '16:00',
        type: 'received',
      });

      messages.push({
        text: this.$t('configuration.messages.out-of-service.confirm'),
        time: '16:00',
        type: 'sent',
      });

      this.previewList.push({
        messages,
        title: this.$t('configuration.messages.agents-offline-message'),
      });
    },
    previewUnattended(value) {
      const messages = [];

      messages.push({
        text: this.$t('configuration.messages.unattended.received'),
        time: 'ayer 10:00',
        type: 'sent',
      });

      messages.push({
        text: this.$t('configuration.messages.unattended.send'),
        time: 'ayer 10:00',
        type: 'received',
      });

      messages.push({
        text: value,
        time: 'hoy 10:00',
        type: 'received',
      });

      this.previewList.push({
        messages,
        title: this.$t('configuration.messages.unattended-message'),
      });
    },
    previewReminder(value) {
      const messages = [];

      messages.push({
        text: this.$t('configuration.messages.remainder.send'),
        time: 'hoy 10:00',
        type: 'sent',
      });

      messages.push({
        text: this.$t('configuration.messages.remainder.received'),
        time: 'hoy 10:00',
        type: 'received',
      });
      messages.push({
        text: this.$t('configuration.messages.remainder.send-response'),
        time: 'hoy 10:05',
        type: 'sent',
      });

      messages.push({
        text: value,
        time: 'hoy 10:10',
        type: 'received',
      });

      this.previewList.push({
        messages,
        title: this.$t('configuration.messages.unattended-reminder-message.title'),
      });
    },
    animationCompleted() {
      this.indexPreview = (this.indexPreview + 1) % this.previewList.length;
    },
    handleConfirm() {
      this.cancelUpdate();
    },
  },
};

</script>

<style scoped lang="scss">
@import "~styles/settings/shared/_modal.scss";
@import "~styles/components/settings/_messages-tab.scss";
@import "~styles/shared/confirmation.scss";
</style>
