<template>
  <div class="empty-campaigns">
    <div class="empty-campaigns__container">
      <img
        :src="require('@/assets/images/folder-wrench.svg')"
        class="empty-campaigns__icon"
      />
    </div>
    <span class="empty-campaigns__text">{{ emptyText }}</span>
  </div>
</template>

<script>
export default {
  computed: {
    emptyText() {
      return this.$t('campaigns.empty');
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/_campaigns.scss";
</style>
