<template>
  <hcc-modal
    name="add-template-modal"
    :title="$t('campaigns.data-bank.templates.add-template')"
    @closed="clear"
    @opened="focusRefs"
    :width="650"
  >
    <overlay-loader :loading="isLoading" />
    <div class="add-template__modal" v-show="!this.showPreview">
      <hcc-input
        :placeholder="$t('common.name')"
        ref="templateName"
        v-model.trim="name"
        :label="$t('common.name')"
        class="add-template__row"
        @blur="$v.name.$touch()"
        :error="$v.name.$error"
        :errorMessage="$t('campaigns.data-bank.templates.error')"
      />
      <div class="template__container">
        <hcc-select
          v-model="categoryInput"
          track-by="id"
          option-label="name"
          :options="categories"
          :label="$t('configuration.categories.header')"
          :placeholder="$t('configuration.categories.header')"
          custom-class="template__select"
        />
        <hcc-select
          v-model="languageCode"
          track-by="code"
          option-label="name"
          :options="languague"
          :label="$t('campaigns.data-bank.templates.lang')"
          :placeholder="$t('campaigns.data-bank.templates.lang')"
          custom-class="template__select"
        />
      </div>
      <hcc-input
        v-if="isNewCategory"
        :placeholder="$t('configuration.categories.header')"
        ref="templateName"
        v-model.trim="newCategory"
        :label="$t('configuration.categories.header')"
        class="add-template__row"
      />
      <hcc-select
        v-model="typeInput"
        option-label="type"
        track-by="id"
        :options="options"
        :label="$t('common.type')"
        :placeholder="$t('campaigns.data-bank.templates.select-type')"
        custom-class="add-template__row"
      />
      <hcc-textarea
        v-model.trim="description"
        name="template_add"
        :placeholder="$t('campaigns.data-bank.templates.description')"
        :label="$t('campaigns.data-bank.templates.description')"
        class="add-template__row"
        ref="templateTextarea"
        :rows="6"
      />
      <div class="add-template__container" @click="addParameter()">
        <plus-circle-icon class="add-template__icon" />
        <span class="add-template__text"
          >{{ $t("campaigns.data-bank.templates.add-parameter") }}
        </span>
      </div>
      <hcc-textarea
        v-if="isMultimedia"
        v-model.trim="footer"
        name="template_footer"
        :placeholder="$t('campaigns.data-bank.templates.footer')"
        :label="$t('campaigns.data-bank.templates.footer')"
        class="add-template__row"
        :rows="3"
        ref="templateFooter"
      />
      <hcc-button
        @click="handleEnableButton(!hasButtons)"
        :variant="enabledAddButtons" size="lg"
        class="template__buttons">
        {{ $t('campaigns.data-bank.templates.buttons') }}
      </hcc-button>
      <div class="template-detail__row template__colum" v-if="hasButtons">
        <div class="template-detail__row--buttons">
          <hcc-select
            v-model="buttonType"
            track-by="key"
            option-label="name"
            :options="buttonsTypes"
            placeholder="Type"
            custom-class="select__template-button"
          />
          <hcc-input
            size="eg"
            :placeholder="$t('campaigns.data-bank.templates.button-information')"
            ref="templateName"
            v-model.trim="buttonName"
          />
          <hcc-input
            size="eg"
            :placeholder="'Payload'"
            ref="templateName"
            v-model.trim="buttonPayload"
            class="add-template__row"
          />
          <div class="add-template__container" v-if="buttons.length < 3">
            <hcc-button-icon
              v-if="buttonNameLength"
              @click="addButton"
              :disabled="!enableAdd"
              size="md">
              <plus-circle-icon class="add-template__button-icon" />
              <span class="add-template__button-icon">
                {{ $t('campaigns.data-bank.templates.add-button') }}
              </span>
            </hcc-button-icon>
            <span v-else>
             {{ $t('campaigns.data-bank.templates.button-name')}}
            </span>
          </div>
        </div>
        <div class="template-detail__buttons">
          <vue-flip v-for="(button, index) in buttons"
            :key="index"
            :active-click="true"
            width="100%"
            height="-webkit-fill-available"
            class="flip__card">
            <template v-slot:front>
              <div class="template__flip">
                <hcc-button color="pagination" variant="fill" size="eg">
                  {{ button.name }}</hcc-button>
                <div class="template__icon delete" @click.stop="deleteButton(index)">
                  <delete-icon/>
                </div>
              </div>
            </template>
            <template v-slot:back>
              <div class="template__flip-back">
                <div class="template-detail__row">
                  <span class="template-detail__buttons-title">
                    <b>Payload:</b> {{ button.payload }}</span>
                </div>
                <div class="template-detail__row">
                  <span class="template-detail__buttons-title">
                    <b>Type:</b> {{ button.type }} </span>
                </div>
              </div>
            </template>
          </vue-flip>
        </div>
      </div>
    </div>
    <div slot="footer" v-if="!this.showPreview">
      <div class="modal__buttons">
        <hcc-button
          @click="clear"
          size="sm"
          variant="outline"
          color="muted"
          >{{ $t("shared-components.modal.cancel") }}</hcc-button
        >
        <hcc-button
          :disabled="!saveEnabled"
          @click="handleConfirm"
          size="sm"
          class="modal__buttons--button"
          color="secondary"
          >Siguiente</hcc-button
        >
      </div>
    </div>
    <div slot="footer" v-else>
      <div class="modal__buttons">
        <hcc-button
          @click="isPreview(false)"
          size="sm"
          variant="outline"
          color="muted"
          >Anterior</hcc-button
        >
        <hcc-button
          @click="save"
          size="sm"
          class="modal__buttons--button"
          color="secondary"
          >Guardar</hcc-button
        >
      </div>
    </div>
    <div class="footer_information">
      <input type="checkbox" v-model="acceptedPolicy">
      <label class="input__label">
        {{ $t("templates.info-teamplate") }}
      </label>
    </div>
    <preview v-show="this.showPreview"
      :numParams="parametersCount"
      :template="template"
      :footer="footer"
      @multimedia="saveMultimedia"
      @params="saveParams"
      @change="savePreview"
    />
  </hcc-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import VueFlip from 'vue-flip';
import EventBus from '@/eventBus';
import { CREATE_TEMPLATE } from '@/eventTypes';
import HccModal from '@/components/shared/HccModal/index.vue';
import HccSelect from '@/components/shared/HccSelect/index.vue';
import HccTextarea from '@/components/shared/HccTextarea/index.vue';
import HccInput from '@/components/shared/HccInput/index.vue';
import HccButton from '@/components/shared/HccButton/index.vue';
import HccButtonIcon from '@/components/shared/HccButtonIcon/index.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import TemplateCategories from './categories.json';
import Preview from './Preview.vue';
import Lang from './language';

const validName = (value) => {
  const regex = new RegExp(/^[a-z0-9_]*$/g);
  return regex.test(value);
};

export default {
  components: {
    HccModal,
    HccSelect,
    HccTextarea,
    HccInput,
    VueFlip,
    HccButton,
    HccButtonIcon,
    Preview,
    OverlayLoader,
  },
  data() {
    return {
      template: {},
      options: [
        { id: 1, type: this.$t('campaigns.data-bank.templates.text'), key: 'text' },
        { id: 2, type: this.$t('campaigns.data-bank.templates.image'), key: 'image' },
        { id: 3, type: this.$t('campaigns.data-bank.templates.video'), key: 'video' },
        { id: 4, type: this.$t('campaigns.data-bank.templates.document'), key: 'document' },
      ],
      acceptedPolicy: false,
      typeInput: '',
      description: '',
      previewText: null,
      name: '',
      categoryInput: '',
      categories: [],
      newCategory: null,
      buttons: [],
      buttonPayload: null,
      buttonType: null,
      buttonName: null,
      hasButtons: false,
      footer: '',
      buttonsTypes: [
        { name: 'URL', key: 'url', $isDisabled: false },
        { name: this.$t('campaigns.data-bank.templates.mobile'), key: 'phone_number', $isDisabled: false },
        { name: this.$t('campaigns.data-bank.templates.quick-replie'), key: 'quick_reply', $isDisabled: false },
      ],
      showPreview: false,
      languague: Lang,
      languageCode: '',
      params: [],
      multimedia: '',
      isLoading: false,
    };
  },
  computed: {
    parametersCount() {
      return ((this.description || '').match(/\{\{\d+\}\}/gi) || []).length;
    },
    isNewCategory() {
      return this.categoryInput.id === 11;
    },
    saveEnabled() {
      return this.typeInput !== '' && this.description !== '' && this.acceptedPolicy
        && this.name !== '' && this.categoryInput !== '' && !this.$v.$invalid;
    },
    categoryName() {
      return this.categoryInput.id === 11 ? this.newCategory : this.categoryInput.key;
    },
    enableAdd() {
      return this.buttonType != null && this.buttonName != null && this.buttonPayload != null;
    },
    enabledAddButtons() {
      return this.hasButtons ? 'fill' : 'outline';
    },
    isMultimedia() {
      return this.typeInput && this.typeInput?.key !== 'text';
    },
    buttonNameLength() {
      return this.buttonName ? this.buttonName.length <= 25 : true;
    },
  },
  watch: {
    buttons(val) {
      const btn = this.buttonsTypes;
      if (val.length === 1 && val[0].type === 'quick_reply') {
        this.buttonsTypes = [
          { ...btn[0], $isDisabled: true },
          { ...btn[1], $isDisabled: true },
          { ...btn[2], $isDisabled: false },
        ];
      } else if (val.length === 1 && val[0].type !== 'quick_reply') {
        this.buttonsTypes = [
          { ...btn[0], $isDisabled: false },
          { ...btn[1], $isDisabled: false },
          { ...btn[2], $isDisabled: true },
        ];
      } else if (val.length === 0) {
        this.buttonsTypes = [
          { ...btn[0], $isDisabled: false },
          { ...btn[1], $isDisabled: false },
          { ...btn[2], $isDisabled: false },
        ];
      }
    },
  },
  created() {
    EventBus.$on('close-template-modal', (data) => {
      if (data) {
        this.clear();
      } else {
        this.isLoading = false;
      }
    });
  },
  destroyed() {
    EventBus.$off('close-template-modal');
  },
  mounted() {
    this.templateCategories();
  },
  validations: {
    name: {
      required,
      valid: name => validName(name),
    },
  },
  methods: {
    handleConfirm() {
      this.template = {
        type: this.typeInput.key,
        text: this.description,
        element_name: this.name,
        category: this.categoryName,
        buttons: this.buttons,
        lang: this.languageCode.code,
      };
      this.showPreview = true;
    },
    clear() {
      this.$v.$reset();
      this.typeInput = '';
      this.description = '';
      this.name = '';
      this.categoryInput = '';
      this.buttons = [];
      this.hasButtons = false;
      this.footer = '';
      this.previewText = '';
      this.clearButtons();
      this.languageCode = '';
      this.showPreview = false;
      this.params = [];
      this.multimedia = '';
      this.$modal.hide('add-template-modal');
      this.isLoading = false;
    },
    addParameter() {
      const num = this.parametersCount + 1;
      this.description = `${this.description} {{${num}}}`;
      this.$refs.templateTextarea.focus();
    },
    focusRefs() {
      this.$refs.templateName.focus();
    },
    templateCategories() {
      this.categories = TemplateCategories.data;
    },
    addButton() {
      if (this.buttons.length < 3) {
        this.buttons.push({
          name: this.buttonName,
          type: this.buttonType.key,
          payload: this.buttonPayload,
        });
        this.clearButtons();
      }
    },
    clearButtons() {
      this.buttonName = null;
      this.buttonType = null;
      this.buttonPayload = null;
    },
    deleteButton(index) {
      this.buttons.splice(index, 1);
    },
    handleEnableButton(event) {
      this.hasButtons = event;
      if (!event) {
        this.buttons = [];
        this.clearButtons();
      }
    },
    isPreview(event) {
      this.showPreview = event;
    },
    savePreview(val) {
      this.previewText = val;
    },
    saveParams(val) {
      this.params = val;
    },
    saveMultimedia(val) {
      this.multimedia = val;
    },
    save() {
      this.isLoading = true;
      if (this.typeInput.key !== 'text') {
        this.template.footer = this.footer;
      }

      let preview = this.previewText;
      if (!preview) {
        preview = this.description;
      }

      EventBus.$emit(CREATE_TEMPLATE, {
        ...this.template,
        bodyParams: this.params,
        multimedia: this.multimedia,
        preview,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~styles/components/settings/dataBank/_templates_tab_data_bank.scss";
  .footer_information {
    margin-top: 20px;
    font-size: 12px;
  }
</style>
