import fullCompaniesGql from '@/graphql/queries/companies/filters.gql';
import executeQuery from '@/utils/gql-api';
import errorHandler from '@/utils/error.handler';

export default {
  async fetchCompanies({ commit }) {
    try {
      const result = await executeQuery(
        'fullCompanies',
        fullCompaniesGql,
        {},
        false,
      );

      commit('setCompanies', result);
    } catch (err) {
      errorHandler.logErrors(err);
    }
  },
};
