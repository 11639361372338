<template>
  <div class="shop-detail loader-container">
    <overlay-loader :loading="loading" />
    <template v-if="myStore">
      <div class="shop-detail__header flex flex-column">
        <div class="shop-detail__header-logo">
          <img
            :src="profilePic"
            class="shop-detail__header-image"
            alt="store-img"
          />
        </div>
        <div
          class="
            shop-detail__header-description
            shop-description shop-description-sm
          "
        >
          <div class="header-sm">
            <div class="shop-detail__header-title">
              <div class="shop-detail__header-name">{{ myStore.name }}</div>
              <div class="shop-detail__header-id text-align-xs">
                ({{ myStore.nameIdCanonical }})
              </div>
            </div>
            <div class="header-sm">
              <div class="shop-detail__header-category">
                {{ myStore.category }}
              </div>
              <div class="shop-detail__header-about text-align-xs">
                {{ myStore.description }}
              </div>
              <div class="shop-detail__header-address text-align-xs">
                &#128204;
                {{ myStore.address }}
              </div>
            </div>
          </div>
          <div class="shop-detail__header-actions header-sm">
            <generate-qr
              v-if="myStore.settings.showQR"
              :id="myStore.campaign"
              :urlMarketplace="typeUrl"
            />
            <router-link
              :to="{
                name: 'store-categorie.admin.sellia',
                params: { idStore: $route.params.id },
              }"
            >
            <hcc-button variant="transparent">
              <format-list-bulleted-icon />
              {{ $t('shop.product.categories.name') }}
            </hcc-button>
            </router-link>
            <hcc-button
              v-if="myStore.settings.ordersView"
              variant="transparent"
              v-clipboard:copy="getUrl()"
              @click="handleClick">
              <copy-icon />
              {{ $t("shop.view-as-shopper") }}
            </hcc-button>
            <router-link
                :to="{
                  name: 'store.mobile.sellia',
                  params: {
                    idStore: $route.params.id,
                  }
                }"
              >
              <hcc-button variant="transparent">
                <eye-icon />
                {{ $t("shop.view-as-visitor") }}
              </hcc-button>
            </router-link>
            <hcc-button @click="addProduct">
              {{ $t("shop.add-product") }}
            </hcc-button>
          </div>
        </div>
      </div>
      <div class="shop-detail__body" v-if="!showStatistics">
        <item-product
          v-for="product in products"
          :key="product.id"
          :data="product"
          class="flex-item flex-item-md flex-item-sm"
        >
          <template v-slot:footer>
            <div class="shop-detail__body-footer">
              <router-link
                :to="{
                  name: 'store.product-info',
                  params: {
                    idStore: $route.params.id,
                    idProduct: product.id
                  }
                }"
              >
                <hcc-button
                  variant="outline"
                  :size="buttonSize"
                >
                  {{ $t("shop.edit") }}
                </hcc-button>
              </router-link>
              <hcc-button
                variant="outline"
                color="accent"
                :size="buttonSize"
                @click="confirmDuplicate(product)"
              >
                {{ $t("shop.duplicate") }}</hcc-button
              >
              <hcc-button
                variant="outline"
                color="muted"
                :size="buttonSize"
                @click="deleteProduct(product)"
              >
                {{ $t("shop.delete") }}</hcc-button
              >
            </div>
          </template>
        </item-product>
      </div>
    </template>
    <hcc-confirmation />
  </div>
</template>

<script>
import numeral from 'numeral';
import { mapState, mapActions } from 'vuex';
import randomstring from 'randomstring';
import ItemProduct from '@/components/shop/ItemProduct.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import HccButton from '@/components/shared/HccButton/index.vue';
import GenerateQr from '@/components/shop/GenerateQr.vue';
import deleteProductStoreGql from '@/graphql/mutations/product/deleteProductStore.gql';
import createProductStore from '@/graphql/mutations/product/createProductStore.gql';

export default {
  components: {
    ItemProduct,
    OverlayLoader,
    HccConfirmation,
    HccButton,
    GenerateQr,
  },
  data: () => ({
    edit: false,
    loading: false,
    actualPage: 1,
    currentStore: {},
    selectedProduct: null,
    showStatistics: false,
    showFormProduct: false,
  }),
  computed: {
    ...mapState({
      user: state => state.shared.user,
      myStore: state => state.shop.item,
      products: state => state.product.items,
      idProduct: state => state.product.idProduct,
    }),
    profilePic() {
      if (this.myStore.imageUrl) {
        return this.myStore.imageUrl;
      }

      return 'https://cdn2.iconfinder.com/data/icons/facebook-ui-colored/48/JD-15-512.png';
    },
    buttonSize() {
      return 'xs';
    },
    visits() {
      return this.$t('shop.statistics.visits');
    },
    typeUrl() {
      if (this.myStore.settings?.url) {
        return this.myStore.settings.url;
      }
      return process.env.VUE_APP_BASE_URL_GASTRONOMIA;
    },
  },
  mounted() {
    this.loadStore();
  },
  watch: {
    '$route.params.id': function watchId(newState, oldState) {
      if (newState !== oldState) {
        this.loadStore();
      }
    },
  },
  methods: {
    ...mapActions({
      createProduct: 'product/save',
      duplicateProduct: 'product/duplicate',
      getSelectedStore: 'shop/getById',
      getAllProducts: 'product/getItems',
      getProducts: 'product/getAllProducts',
    }),
    getUrl() {
      return `${process.env.VUE_APP_SHOPPER}/${this.$route.params.id}`;
    },
    handleClick() {
      this.$emit('copy');
      this.$toasted.global.success(this.$t('messages.shopper-copy'));
    },
    async saveProduct(producto) {
      this.loading = true;
      const newProduct = {
        ...producto,
        storeId: this.myStore.id,
      };
      await this.createProduct(newProduct);
      await this.getProducts(this.$route.params.id);
      this.loading = false;
    },
    cancelAddProduct() {
      this.showFormProduct = false;
      this.selectedProduct = null;
    },
    async duplicateProduct(producto) {
      this.loading = true;
      const dataProduct = {
        id: producto.id,
        name: `${producto.nombre} - duplicate`,
        campaign: this.$route.params.id,
        active: producto.status,
        imageUrl: producto.imageURL,
        categoriesProduct: producto.categoriesProduct.map(idCategory => ({ idCategory })),
        descriptionProduct: producto.descripcion,
        purchasePrice: numeral(producto.purchase).value(),
        salePrice: numeral(producto.precio).value(),
        promotion: producto.promotion,
        optionsGroup: producto.optionsGroup,
        stock: producto.stock,
        length: producto.length,
        width: producto.width,
        height: producto.height,
        weight: producto.weight,
      };
      await this.$apollo.mutate({
        mutation: createProductStore,
        variables: dataProduct,
      });
      await this.getProducts(this.$route.params.id);
      this.loading = false;
      this.$toasted.global.success(this.$t('shop.product.success-duplicate-product'));
    },
    async confirmDuplicate(producto) {
      this.$modal.show('confirmation', {
        title: this.$t('shop.product.duplicate-product'),
        description: this.$t('shop.product.confirm-duplicate-product'),
        variant: 'default',
        confirm: () => this.duplicateProduct(producto),
      });
    },
    addProduct() {
      this.$router.push({
        name: 'store.product-info',
        params: {
          idStore: this.$route.params.id,
          idProduct: randomstring.generate({ length: 20 }),
        },
      });
    },
    async removeAndGetProducts(id) {
      this.loading = true;
      await this.removeProduct(id);
      await this.getProducts(this.$route.params.id);
      this.loading = false;
    },
    async removeProduct(id) {
      await this.$apollo.mutate({
        mutation: deleteProductStoreGql,
        variables: {
          productId: id,
        },
      });
    },
    deleteProduct({ id }) {
      this.$modal.show('confirmation', {
        title: this.$t('shop.product.remove-product'),
        description: this.$t('shop.product.confirm-remove-product'),
        variant: 'default',
        confirm: () => this.removeAndGetProducts(id),
      });
    },
    async loadStore() {
      let campaign = this.$route.params.id;

      if (!campaign || campaign === 'undefined' || campaign === 'null') {
        campaign = JSON.parse(sessionStorage.getItem('campaign'));
      }

      this.loading = true;
      await this.getSelectedStore(campaign);
      await this.getProducts(campaign);
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~styles/views/_store.scss";
</style>
