<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <chat-button v-if="showChatButton" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { auth } from '@/utils/firebase';
import { mapMutations, mapState, mapGetters } from 'vuex';
import ChatButton from '@/components/chatButton/index.vue';
import companyByIdQuery from '@/graphql/queries/companyById.gql';

const defaultLayout = 'without-layout';

export default {
  components: {
    ChatButton,
  },
  computed: {
    ...mapState({
      user: state => state.shared.user,
    }),
    ...mapGetters({
      userInfo: 'shared/userInfo',
      userRole: 'shared/getUserRole',
      currentCompany: 'shared/getCurrentCompany',
    }),
    layout() {
      return (this.$route.meta.layout || defaultLayout);
    },
    showChatButton() {
      return ['super', 'admin', 'owner'].includes(this.userRole)
        && this.$route.meta.forAuth;
    },
  },
  watch: {
    user() {
      if (this.user && this.user.id) {
        this.setIdentity();
      }
    },
  },
  apollo: {
    companyStyle: {
      query: gql`${companyByIdQuery}`,
      skip() {
        return !this.currentCompany?.id;
      },
      update({ companyById }) {
        if (companyById) {
          document.title = `${companyById.name} | Sellia Admin`;
          this.setStylesCompany(companyById.colors || {}, companyById.logo);
        }
      },
      variables() {
        return { id: this.currentCompany?.id };
      },
    },
  },
  mounted() {
    if (this.user && this.user.id) {
      auth.signInAnonymously();
      this.setIdentity();
    }
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapMutations(['setWindowWidth']),
    onResize() {
      this.setWindowWidth(window.innerWidth);
    },
    setIdentity() {
      this.$analytics.setUserId(this.user.id);
      this.$analytics.setUserProperties({
        account_type: this.userRole,
      });
    },
    setStylesCompany({
      accent,
      primary,
      secondary,
    }, logo) {
      const root = document.documentElement;
      root.removeAttribute('style');
      if (primary) {
        const [primaryColor, primaryLight, primaryDark] = primary.split(',');
        if (primaryColor) {
          root.style.setProperty('--primary', primaryColor);
        }
        if (primaryLight) {
          root.style.setProperty('--primary-light', primaryLight);
        }
        if (primaryDark) {
          root.style.setProperty('--primary-dark', primaryDark);
        }
      }
      if (secondary) {
        const [secondaryColor, secondaryLight, secondaryDark] = secondary.split(',');
        if (secondaryColor) {
          root.style.setProperty('--secondary', secondaryColor);
        }
        if (secondaryLight) {
          root.style.setProperty('--secondary-light', secondaryLight);
        }
        if (secondaryDark) {
          root.style.setProperty('--secondary-dark', secondaryDark);
        }
      }
      if (accent) {
        root.style.setProperty('--accent', accent);
      }
      if (logo) {
        root.style.setProperty('--icon-mini', `url(${logo})`);
        root.style.setProperty('--icon', `url(${logo})`);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~styles/_app.scss";
</style>
