<template>
  <div>
    <vertical-tabs
      v-if="!isAdmin"
      :tabs="tabs"
      :activeTab="currentTab"
      @change="updateCurrentTab"
    >
      <div class="tabs-content vertical">
        <div class="tabs-panel is-active">
          <keep-alive>
            <component v-bind:is="currentTab" />
          </keep-alive>
        </div>
      </div>
    </vertical-tabs>
    <div v-else>
      <agents-tab />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VerticalTabs from '@/components/VerticalTabs.vue';
import AdminsTab from '@/components/settings/Users/Admins/AdminsTab.vue';
import AgentsTab from '@/components/settings/Users/Agents/AgentsTab.vue';
import OperatorsTab from '@/components/settings/Users/Operators/OperatorsTab.vue';
import SupervisorsTab from '@/components/settings/Users/Supervisors/SupervisorsTab.vue';

export default {
  components: {
    VerticalTabs,
    AdminsTab,
    AgentsTab,
    OperatorsTab,
    SupervisorsTab,
  },
  data() {
    return {
      currentTab: 'admins-tab',
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'shared/userInfo',
    }),
    tabs() {
      return [
        {
          icon: 'account-icon',
          title: this.$t('campaign-settings.assigned-users.admins'),
          component: 'admins-tab',
        },
        {
          icon: 'account-icon',
          title: this.$t('campaign-settings.assigned-users.agents'),
          component: 'agents-tab',
        },
        {
          icon: 'account-icon',
          title: this.$t('campaign-settings.assigned-users.supervisors'),
          component: 'supervisors-tab',
        },
        {
          icon: 'account-icon',
          title: this.$t('campaign-settings.assigned-users.operators'),
          component: 'operators-tab',
        },
      ];
    },
    isAdmin() {
      return this.userInfo.role === 'admin' || this.userInfo.role === 'supervisor';
    },
  },
  methods: {
    updateCurrentTab(tab) {
      this.currentTab = tab.component;
    },
  },
  created() {
    this.$emit('changed', false);
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/settings/_vertical-tabs";
</style>
