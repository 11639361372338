import Vue from 'vue';
import saleAction from './actions/saleActions';

export default {
  namespaced: true,
  state: {
    sales: {},
    percentageDelivery: [],
    percentagePaymentMethods: [],
    topProducts: [],
    salesDensity: [],
  },
  mutations: {
    updateItem(state, { order: value, status }) {
      const { data, total } = state.sales;
      const indexSale = data.findIndex(({ id }) => id === value.id);
      const filterStatus = status.length === 0 || status.includes(value.statecart.key);

      if (indexSale === -1 && filterStatus) {
        state.sales = {
          data: [{ ...value }, ...data],
          total: total + 1,
        };
      } else if (filterStatus) {
        Vue.set(state.sales.data, indexSale, value);
      } else if (indexSale > -1) {
        state.sales.data.splice(indexSale, 1);
        state.sales.total -= 1;
      }
    },
    setItems(state, value) {
      state.sales = value;
    },
    setPercentageDelivery(state, value) {
      state.percentageDelivery = value;
    },
    setPaymentMethods(state, value) {
      state.percentagePaymentMethods = value;
    },
    setTopProducts(state, value) {
      state.topProducts = value;
    },
    setSalesDensity(state, value) {
      state.salesDensity = value;
    },
  },
  actions: {
    ...saleAction,
  },
};
