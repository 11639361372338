<template>
  <hcc-modal
    :title="$t('supervisor.title')"
    class="user"
    :width="600"
    name="Supervisor"
    @confirm="permissionAction"
    @closed="clear"
    @cancel="clear"
    @opened="focusBuildModal"
    :confirmDisableButton="!validForm"
  >
    <div class="user__property">
      <hcc-select
        :label="$t('users.profile')"
        custom-class="select"
        v-model="role"
        :options="allowedRoles"
        option-label="display_name"
        track-by="id"
      />
      <span />
      <config-card
        :title="$t('supervisor.dashboard')"
        :key="`${this.permission.id}1`"
        v-model="permissions.dashboard"
        :text="$t('supervisor.dashboard')"
        name="dashboard"
        :disabled="hideConfig"
        :disabledMessage="$t('configuration.options.error-disabled-config')"
      />
      <config-card
        :title="$t('supervisor.templates')"
        :key="`${this.permission.id}2`"
        v-model="permissions.templates"
        :text="$t('supervisor.templates')"
        name="templates"
      />
      <config-card
        :title="$t('supervisor.config')"
        :key="`${this.permission.id}3`"
        v-model="permissions.config"
        :text="$t('supervisor.config')"
        name="config"
      />
      <config-card
        :title="$t('supervisor.messages')"
        :key="`${this.permission.id}4`"
        v-model="permissions.messages"
        :text="$t('supervisor.messages')"
        name="messages"
      />
      <config-card
        :title="$t('supervisor.team')"
        :key="`${this.permission.id}5`"
        v-model="permissions.team"
        :text="$t('supervisor.team')"
        name="team"
      />
      <config-card
        :title="$t('supervisor.stores')"
        :key="`${this.permission.id}7`"
        v-model="permissions.stores"
        :text="$t('supervisor.stores')"
        name="stores"
      />
      <config-card
        :title="$t('supervisor.reports')"
        :key="`${this.permission.id}8`"
        v-model="permissions.reports"
        :text="$t('supervisor.reports')"
        name="reports"
      />
    </div>
  </hcc-modal>
</template>

<script>
import {
  required,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import EventBus from '@/eventBus';
import { NEW_PERMISSION } from '@/eventTypes';

export default {
  props: {
    permission: {
      required: true,
      type: Object,
    },
    edit: {
      required: true,
      type: Boolean,
    },
    permissionCompany: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      company: { id: '' },
      role: { name: '' },
      hideConfig: true,
      permissions: {
        dashboard: true,
        templates: false,
        config: false,
        messages: false,
        team: false,
        users: false,
        stores: false,
        reports: false,
      },
    };
  },
  validations: {
    role: { required },
  },
  components: {
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
    ConfigCard: () => import('@/components/settings/ConfigCard.vue'),
  },
  computed: {
    ...mapGetters({
      companies: 'shared/getFilterCompanies',
      isOwner: 'shared/isOwner',
      roles: 'shared/getRoles',
    }),
    allowedRoles() {
      const excludeRoles = ['admin', 'agent', 'super'];
      return this.roles.filter(c => !excludeRoles.includes(c.name));
    },
    companyID() {
      return this.company.id ? this.company.id : this.permissionCompany;
    },
    validForm() {
      return !this.$v.$invalid;
    },
  },
  methods: {
    buildPermissionForm() {
      this.company = this.findCompany(this.permissionCompany);
      this.role = this.permission.role;

      this.permissions.dashboard = this.permission.permissions.includes('dashboard') || false;
      this.permissions.templates = this.permission.permissions.includes('templates') || false;
      this.permissions.config = this.permission.permissions.includes('config') || false;
      this.permissions.messages = this.permission.permissions.includes('messages') || false;
      this.permissions.team = this.permission.permissions.includes('team') || false;
      this.permissions.users = false;
      this.permissions.stores = this.permission.permissions.includes('stores') || false;
      this.permissions.reports = this.permission.permissions.includes('reports') || false;
    },
    permissionAction() {
      let payload = {
        role: this.role.id,
        company: this.companyID,
        permissions: Object.keys(this.permissions).filter(key => this.permissions[key]),
      };

      if (this.edit) {
        payload = {
          ...payload,
          id: this.permission.id,
        };
      }

      EventBus.$emit(NEW_PERMISSION, { payload });
    },
    clear() {
      this.$v.$reset();
      this.company = { id: '' };
      this.role = { name: '' };
      this.permissions = {
        dashboard: true,
        templates: false,
        config: false,
        messages: false,
        team: false,
        users: false,
        stores: false,
        reports: false,
      };
      this.$modal.hide('Supervisor');
    },
    createNew() {
      this.company = this.findCompany(this.companyID);
      this.role = this.findRole('supervisor');
    },
    findCompany(id) {
      return this.companies.length > 0
        ? this.companies.find(c => c.id === id)
        : { id };
    },
    findRole(name) {
      return this.roles.length > 0
        ? this.roles.find(c => c.name === name)
        : { name };
    },
    focusBuildModal() {
      if (this.edit) {
        this.buildPermissionForm();
      } else {
        this.createNew();
      }
      this.$refs.hccInput.focus();
    },
    submit() {
      if (this.validForm) {
        this.permissionAction();
        this.$modal.hide('Supervisor');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_user.scss";
</style>
