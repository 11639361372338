<template>
  <div class="campaigns__table">
    <hcc-table
      enableSearch
      :enableAdd="canCreate"
      :columns="columns"
      :rows="rows"
      @add="addCampaign"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'status'">
          <hcc-status-dot
            size="md"
            :color="props.row.status ? 'active' : 'inactive'"
          />
        </span>
        <span v-else-if="props.column.field == 'channels'">
          <div v-if="props.row.channels.length === 0">
            {{ "--" }}
          </div>
          <div v-else>
            <div
              v-for="channel in displayedChannels(props.row.channels)"
              :key="channel.id"
              class="table__badge"
            >
              <channel-badge
                :type="channel.channelType.key"
                v-tooltip="channel.name"
              />
            </div>
            <span v-if="props.row.channels.length > 5">...</span>
          </div>
        </span>
        <span v-else-if="props.column.field == 'actions'">
          <hcc-dropdown
            v-if="showActions"
            position="bottom-left"
            customClass="custom-position"
          >
            <template #button>
              <hcc-button-icon><more-icon /> </hcc-button-icon>
            </template>
            <hcc-dropdown-item
              v-if="isOwner"
              icon="pencil-icon"
              @click="editCampaign(props.row)"
            >
              <span>{{ $t("common.edit") }}</span>
            </hcc-dropdown-item>
            <hcc-dropdown-item
              v-if="showDuplicateButton"
              icon="copy-icon"
              @click="openConfirmDuplicate(props.row)"
            >
              <span>{{ $t("campaigns.cards.duplicate") }}</span>
            </hcc-dropdown-item>
            <hcc-dropdown-item
              v-if="canDeleteCampaign(props.row)"
              icon="delete-icon"
              @click="openConfirmDelete"
            >
              <span>{{ $t("campaigns.cards.delete") }}</span>
            </hcc-dropdown-item>
            <hcc-dropdown-item
              icon="account-icon"
              @click="openBlockedClients(props.row)"
            >
              <span>{{ $t("campaigns.cards.blocked-client") }}</span>
            </hcc-dropdown-item>
          </hcc-dropdown>

          <hcc-dropdown position="bottom-left" customClass="custom-position">
            <template #button>
              <hcc-button-icon><settings-icon /></hcc-button-icon>
            </template>
            <hcc-dropdown-item
              v-if="isOwner"
              @click="openChannelsSettings(props.row)"
            >
              <span>{{ $t("campaigns.cards.channels-settings") }}</span>
            </hcc-dropdown-item>
            <hcc-dropdown-item @click="openCampaignSettings(props.row)">
              <span>{{ $t("campaigns.cards.campaign-settings") }}</span>
            </hcc-dropdown-item>
          </hcc-dropdown>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import HccTable from '@/components/shared/HccTable/index.vue';
import HccDropdown from '@/components/shared/HccDropdown/index.vue';
import HccDropdownItem from '@/components/shared/HccDropdown/DropdownItem.vue';
import HccStatusDot from '@/components/shared/HccStatusDot/index.vue';
import HccButtonIcon from '@/components/shared/HccButtonIcon/index.vue';
import ChannelBadge from '@/components/ChannelBadge.vue';

export default {
  components: {
    HccTable,
    HccDropdown,
    HccDropdownItem,
    HccStatusDot,
    HccButtonIcon,
    ChannelBadge,
  },
  props: {
    campaigns: {
      type: Array,
      required: true,
    },
    isOwner: {
      type: Boolean,
      required: true,
    },
    isSuper: {
      type: Boolean,
      required: true,
    },
    canCreate: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      rows: [],
    };
  },
  computed: {
    ...mapGetters({
      companies: 'shared/getFilterCompanies',
    }),
    ...mapState({
      selectedStore: state => state.shop.item,
      currentProducts: state => state.product.items,
    }),
    columns() {
      return [{
        label: this.$t('filter.status'),
        field: 'status',
      }, {
        label: this.$t('common.name'),
        field: 'name',
      },
      {
        label: this.$t('campaigns.created-at'),
        field: 'created',
      },
      {
        label: this.$t('campaigns.cards.admins'),
        field: 'admins',
      },
      {
        label: this.$t('campaigns.cards.agents'),
        field: 'agents',
      },
      {
        label: this.$t('campaigns.cards.channels'),
        field: 'channels',
      },
      {
        label: this.$t('shared-components.table.actions'),
        field: 'actions',
      },
      ];
    },
    showActions() {
      return this.isOwner || this.isSuper;
    },
    showDuplicateButton() {
      return this.canCreate;
    },
  },
  watch: {
    campaigns(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.createRows();
      }
    },
  },
  methods: {
    ...mapActions({
      store: 'shop/getById',
      products: 'product/getItems',
    }),
    createRows() {
      this.rows = this.campaigns.map(campaign => ({
        id: campaign.id,
        status: campaign.active,
        name: campaign.name,
        created: this.getCreatedAt(campaign),
        admins: campaign.admins,
        agents: campaign.agents,
        channels: campaign.channels,
      }));
    },
    displayedChannels(channels) {
      if (channels) {
        if (channels.length > 5) {
          return channels.slice(0, 5);
        }
      }

      return channels || [];
    },
    findSelectedCampaign(campaignId) {
      return this.campaigns.find(campaign => campaign.id === campaignId);
    },
    getCreatedAt(campaign) {
      if (campaign.createdAt) {
        const createdAt = this.$moment(campaign.createdAt);
        return createdAt.format('DD/MM/YYYY');
      }

      return 'N/A';
    },
    editCampaign(campaign) {
      this.$emit('edit', this.findSelectedCampaign(campaign.id));
    },
    openBlockedClients(campaign) {
      const selectedCampaign = this.findSelectedCampaign(campaign.id);
      this.$router.push(`/campaigns/${selectedCampaign.id}/blocked-clients`);
    },
    canDeleteCampaign(campaign) {
      return !campaign.status;
    },
    openConfirmDelete(campaign) {
      this.$modal.show('confirmation', {
        title: this.$t('campaigns.cards.delete-campaign'),
        description: this.$t('campaigns.cards.delete-confirmation'),
        variant: 'error',
        confirm: () => this.$emit('delete-campaign', campaign),
      });
    },
    validateAllDataStore() {
      return this.selectedStore.descripcion
        && this.selectedStore.categoria
        && this.selectedStore.direccion;
    },
    findCompany({ company }) {
      return this.companies.find(({ id }) => id === company);
    },
    async canDuplicate(campaign) {
      const {
        id,
        schedules,
        categories,
        channels,
        admins,
        agents,
      } = campaign;
      await this.store(id);
      await this.products(id);

      return schedules.length > 0
        && categories.length > 0
        && channels.length > 0
        && this.validateAllDataStore
        && this.currentProducts.length > 0
        && admins > 0
        && agents > 0;
    },
    async openConfirmDuplicate(campaign) {
      const valid = await this.canDuplicate(campaign);
      if (!valid) {
        this.$toasted.global.error(this.$t('campaigns.cards.duplicate-error'));
        return;
      }
      const company = this.findCompany(this.campaign);
      const maxAllowed = Math.floor(company ? company.maxUsers / 2 : 0);
      if (maxAllowed <= this.campaigns.length) {
        this.$toasted.global.error(this.$t('campaigns.cards.duplicate-error', { max: maxAllowed }));
        return;
      }

      this.$modal.show('confirmation', {
        title: this.$t('campaigns.cards.duplicate-campaign'),
        description: this.$t('campaigns.cards.duplicate-confirmation'),
        variant: 'default',
        confirm: () => this.$emit('duplicate-campaign', campaign),
      });
    },
    openCampaignSettings(campaign) {
      this.$emit('campaign-settings', campaign.id);
    },
    openChannelsSettings(campaign) {
      this.$emit('channels-settings', campaign.id);
    },
    addCampaign() {
      this.$emit('create');
    },
  },
  mounted() {
    this.createRows();
  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/_campaigns.scss";
</style>
