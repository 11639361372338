<template>
  <hcc-modal
    :title="$t('shop.categories.title-modal')"
    name="form-category"
    :width="300"
    @confirm="createOrUpdateCategory"
    @cancel="cancelar"
    @closed="cancelar"
    :resizable="true"
    :confirmDisableButton="!validForm"
  >
    <div class="reports">
      <hcc-input
        :placeholder="$t('shop.category')"
        ref="templateName"
        v-model.trim="name"
        :label="$t('shop.category')"
        class="add-template__row"
        @blur="$v.name.$touch()"
        :error="$v.name.$error"
        :errorMessage="
          $t('shop.errors.required', { field: $t('shop.category') })
        "
      />
      <div class="shop__container">
        <div class="shop__group">
          <span>{{ $t('shop.product.options.active') }}</span>
          <div class="shop__status">
            <hcc-toggle-switch
              name="toggle-activo"
              :value="active"
              @change="toggleStatus"
            />
          </div>
        </div>
      </div>
      <div class="cell small-12 medium-6">
      <div class="multimedia__drop">
        <vue-file-agent
          ref="dropZone"
          :multiple="false"
          :helpText="$t('shop.product.image')"
          :maxFiles="1"
          :meta="true"
          :compact="true"
          theme="grid"
          accept="image/*"
          :maxSize="maxSize"
          @select="selectImage"
          v-model="imagecategory"
        >
        </vue-file-agent>
      </div>
    </div>
    </div>
  </hcc-modal>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import { required } from 'vuelidate/lib/validators';
import createCategoryStore from '@/graphql/mutations/categoriesProducts/createCategoryStore.gql';
import updateCategoryStore from '@/graphql/mutations/categoriesProducts/updateCategorieStore.gql';
import { saveImage, getDataImageStore } from '@/utils/helper';

export default {
  props: {
    params: {
      type: Object,
    },
    idCategory: {
      type: String,
      default: '',
    },
  },
  components: {
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccToggleSwitch: () => import('@/components/shared/HccToggleSwitch/index.vue'),
  },
  data() {
    return {
      name: '',
      active: false,
      imagecategory: null,
      file: {},
      imgUrl: '',
    };
  },
  validations: {
    name: {
      required,
    },
    imagecategory: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      getCategoryById: 'categorie/getCategoryById',
    }),
    validForm() {
      return !this.$v.$invalid;
    },
    maxSize() {
      return process.env.VUE_APP_UPLOAD_MAX_FILESIZE;
    },
  },
  created() {
  },
  methods: {
    async getCategory(value) {
      if (value) {
        // eslint-disable-next-line
        const { name, active, img_url } = this.getCategoryById(value);
        this.name = name;
        this.active = active;
        // eslint-disable-next-line
        this.imgUrl = img_url;
        const dataImage = {
          nombre: this.name,
          imageURL: img_url, // eslint-disable-next-line
        }
        const imageUrl = await getDataImageStore(dataImage);
        this.imagecategory = imageUrl;
      } else {
        this.name = '';
        this.active = false;
      }
    },
    async cancelar() {
      this.file = {};
      this.imagecategory = '';
      this.name = '';
      this.$emit('cancel');
    },
    async selectImage(files) {
      const { file } = files[0];
      this.file = file;
    },
    toggleStatus() {
      this.active = !this.active;
    },
    async createOrUpdateCategory() {
      this.$emit('onLoad', true);
      if (this.idCategory) {
        await this.updateCategory();
      } else {
        await this.createCategory();
        this.name = '';
        this.imgUrl = '';
        this.active = false;
        this.$v.$reset();
      }
      this.$emit('onGetCategorys');
      this.$emit('onLoad', false);
    },
    async updateCategory() {
      let urlImageCategory = this.imgUrl;
      const nameCategory = this.name;
      if (this.file instanceof File) {
        urlImageCategory = await saveImage(this.file, this.$route.params.id);
      }
      await this.$apollo.mutate({
        mutation: updateCategoryStore,
        variables: {
          categoryId: this.idCategory,
          name: nameCategory,
          campaign: this.$route.params.id,
          active: this.active,
          img_url: urlImageCategory,
        },
      });
    },
    async createCategory() {
      const nameCategory = this.name;
      this.imgUrl = await saveImage(this.file, this.$route.params.id);
      await this.$apollo.mutate({
        mutation: createCategoryStore,
        variables: {
          name: nameCategory,
          campaign: this.$route.params.id,
          active: this.active,
          img_url: this.imgUrl,
        },
      });
    },
  },
};

</script>

<style scoped lang="scss">
 @import "~styles/views/_shop.scss";
 .shop__container {
   margin-top: 20px;

   span {
      margin-left: 2px;
      margin-bottom: 5px;
      font-weight: bold;
      color: #4d0090;
   }
 }
</style>
