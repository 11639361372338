<template>
  <div class="grid-x grid-margin-x">
    <div class="cell small-12 medium-6">
      <hcc-input
        :placeholder="$t('shop.product.name-character')"
        v-model="newProduct.nombre"
        @blur="$v.newProduct.nombre.$touch()"
        :error="$v.newProduct.nombre.$error"
        :errorMessage="
        $t('shop.errors.required', { field: $t('shop.product.name') })"
        :label="$t('shop.product.name')"
        :maxLength="100"
        :requiredInput="true"
        ref="hccInput"
      />
      <hcc-select
        :label="$t('shop.product.category')"
        custom-class="select"
        class="form-item"
        v-model="newProduct.categoria"
        :errorMessage="$t('shop.errors.required', { field: $t('shop.product.category') })"
        :placeholder="$t('shop.product.select-option')"
        :options="elementGroups"
        option-label="name"
        group-values="lastChildren"
        group-label="listCategories"
        :group-select="true"
        :requiredInput="true"
        track-by="id"
        :allow-empty="true"
        :close-on-select="false"
        :multiple="true"
      />
      <hcc-money
        class="mt-2"
        :placeholder="$t('shop.product.purchase_price')"
        v-model="newProduct.purchase"
        v-on:keypress="NumbersOnly"
        @blur="$v.newProduct.purchase.$touch()"
        :error="$v.newProduct.purchase.$error"
        :errorMessage="
        $t('shop.errors.required', { field: $t('shop.product.purchase_price') })"
        :label="$t('shop.product.purchase_price')"
        :requiredInput="true"
      />
      <hcc-money
        class="mt-2"
        :placeholder="$t('shop.product.price')"
        v-model="newProduct.precio"
        v-on:keypress="NumbersOnly"
        @blur="$v.newProduct.precio.$touch()"
        min="0"
        :error="$v.newProduct.precio.$error"
        :errorMessage="
        $t('shop.errors.required', { field: $t('shop.product.price') })"
        :label="$t('shop.product.price')"
        :requiredInput="true"
      />
      <hcc-input
        v-if="myStore.category === 'Tienda'"
        :placeholder="$t('shop.product.stock')"
        v-model="newProduct.stock"
        @blur="$v.newProduct.stock.$touch()"
        min="0"
        :error="$v.newProduct.stock.$error"
        :errorMessage="
        $t('shop.errors.required', { field: $t('shop.product.stock') })"
        :label="$t('shop.product.stock')"
        :requiredInput="myStore.category === 'Tienda'"
        v-on:keypress="NumbersOnly"
      />
      <hcc-textarea
        name="description"
        :placeholder="$t('shop.product.description')"
        v-model="newProduct.descripcion"
        @blur="$v.newProduct.descripcion.$touch()"
        :error="$v.newProduct.descripcion.$error"
        :errorMessage="$t('shop.errors.required', { field: $t('shop.product.description') })"
        :label="$t('shop.product.description')"
        :maxLength="maxLengthDescription"
        :requiredInput="true"
      />
      <p align="right"
        v-bind:style="charactersLeft <= 10 ? 'color: red':''"
      >
        {{$t('shop.product.remain')}} {{charactersLeft}} {{$t('shop.product.characters')}}
      </p>
      <div class="shop__container" v-if="!isProduct">
        <div class="shop__group">
        <span>{{ $t("shop.product.promotions") }}</span>
        <div class="shop__status">
          <hcc-toggle-switch
            name="toggle-status-promotion"
            :value="newProduct.promotion"
            @change="toggleStatusPromotion"
          />
        </div>
        </div>
      </div>
      <div class="shop__container">
        <hcc-input
          v-if="newProduct.promotion"
          :placeholder="$t('shop.product.product-description-character')"
          v-model="newProduct.promotionDescription"
          @blur="$v.newProduct.promotionDescription.$touch()"
          :error="$v.newProduct.promotionDescription.$error"
          :errorMessage="
          $t('shop.errors.required', { field: $t('shop.product.product-description') })"
          :label="$t('shop.product.product-description')"
          :maxLength="100"
          :requiredInput="newProduct.promotion"
          ref="hccInput"
        />
      </div>
      <div class="shop__container" v-if="!isProduct">
        <div class="shop__group">
        <span>{{ $t("shop.product.status") }}</span>
        <div class="shop__status">
          <hcc-toggle-switch
            name="toggle-status"
            :value="newProduct.status"
            @change="toggleStatus"
          />
        </div>
        </div>
      </div>

      <template
        v-if="myStore.category === 'Tienda'"
      >
      <h2>{{ $t("shop.product.package-data") }}</h2>
      <div class="shop__package">
        <hcc-input
          type="number"
          :placeholder="$t('shop.product.length')"
          v-model="newProduct.length"
          min="0"
          v-on:keypress="NumbersOnly"
          :label="$t('shop.product.length')"
        />
        <hcc-input
          type="number"
          :placeholder="$t('shop.product.width')"
          v-model="newProduct.width"
          min="0"
          v-on:keypress="NumbersOnly"
          :label="$t('shop.product.width')"
        />
        <hcc-input
          type="number"
          :placeholder="$t('shop.product.height')"
          v-model="newProduct.height"
          min="0"
          v-on:keypress="NumbersOnly"
          :label="$t('shop.product.height')"
        />
        <hcc-input
          type="number"
          :placeholder="$t('shop.product.weight')"
          v-model="newProduct.weight"
          min="0"
          v-on:keypress="NumbersOnly"
          :label="$t('shop.product.weight')"
        />
      </div>
      </template>
    </div>
    <div class="cell small-12 medium-4">
      <div class="multimedia__drop">
      <div class="shop__timage">{{$t('shop.image')}}<span>*</span></div>
        <vue-file-agent
          ref="dropZone"
          :theme="'list'"
          :multiple="true"
          :deletable="true"
          :helpText="$t('shop.product.image-size')"
          :maxFiles="5"
          :meta="true"
          :compact="true"
          accept="image/*"
          :maxSize="maxSize"
          @select="selectImage"
          @beforedelete="onBeforeDelete($event)"
          v-model="imageProduct"
        >
        </vue-file-agent>
      </div>
      <div class="shop__text">{{$t('shop.product.image-dimensions')}} </div>
    </div>
    <div class="cell flex-container align-right save-buttons">
      <router-link
        :to="`/store/${idStore}/edit`"
      >
        <hcc-button
          size="sm"
          variant="outline"
          color="muted"
        >
          <span >
            {{ $t("shop.cancel") }}
          </span>
        </hcc-button>
      </router-link>
      <hcc-button
        v-if="!isUpdate"
        :disabled="!change || !validForm"
        size="sm"
        @click="updateProduct(false)"
      >
        <span >
          {{ $t("shop.save-and-continue") }}
        </span>
      </hcc-button>
      <hcc-button
        :disabled="!change || !validForm"
        size="sm"
        @click="updateProduct()"
      >
        <span >
          {{ $t("shop.save") }}
        </span>
      </hcc-button>
      <overlay-loader :loading="isLoading" />
    </div>
  </div>
</template>

<script>
import numeral from 'numeral';
import { mapActions, mapState } from 'vuex';
import { UPDATE_PRODUCT } from '@/eventTypes';
import {
  required, maxLength, requiredIf,
} from 'vuelidate/lib/validators';
import createProductStore from '@/graphql/mutations/product/createProductStore.gql';
import updateProductStore from '@/graphql/mutations/product/updateProductStore.gql';
import { saveImage, getDataImage } from '@/utils/helper';
import EventBus from '@/eventBus';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';

export default {
  components: {
    HccInput: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccInput/index.vue'),
    HccMoney: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccMoney/index.vue'),
    HccTextarea: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccTextarea/index.vue'),
    HccSelect: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccSelect/index.vue'),
    HccToggleSwitch: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccToggleSwitch/index.vue'),
    HccButton: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccButton/index.vue'),
    OverlayLoader,
  },
  props: {
    product: {
      require: true,
    },
    idProduct: {
      type: String,
      default: '',
      required: true,
    },
    categoriesProduct: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: false,
      reload: false,
      valueProduct: {
        status: false,
        promotion: false,
      },
      newProduct: {
        status: false,
        promotion: false,
        categoria: [],
        stock: 1,
        promotionDescription: '',
        imageURL: [],
      },
      query: '',
      tagsValues: [],
      imageProduct: [],
      isUpdate: false,
      change: false,
      maxLengthDescription: 2000,
      changeImage: false,
    };
  },
  validations: {
    newProduct: {
      nombre: {
        required,
        maxLength: maxLength(100),
      },
      descripcion: {
        required,
      },
      precio: { required },
      purchase: { required },
      categoria: { required },
      stock: { required },
      promotionDescription: {
        required: requiredIf(function requiredIfPromotion() {
          return this.newProduct.promotion;
        }),
        maxLength: maxLength(100),
      },
    },
    imageProduct: { required },
  },
  computed: {
    ...mapState({
      myStore: state => state.shop.item,
      idProductState: state => state.product.idProduct,
      elementGroups: state => state.categorie.elementGroups,
    }),
    charactersLeft() {
      const char = this.newProduct.descripcion?.length || 0;
      return this.maxLengthDescription - char;
    },
    validForm() {
      return !this.$v.$invalid;
    },
    maxSize() {
      return process.env.VUE_APP_UPLOAD_MAX_FILESIZE;
    },
    isProduct() {
      return this.newProduct.categoria?.id === 'Producto';
    },
    idStore() {
      const id = this.$route.params.idStore;
      return id || JSON.parse(sessionStorage.getItem('campaign'));
    },
  },
  watch: {
    newProduct: {
      handler() {
        const keys = Object.keys(this.valueProduct);
        this.change = false;
        if (this.newProduct?.imageURL?.length === 0) {
          this.change = true;
        }
        keys.forEach((key) => {
          if (!this.change) {
            if (key === 'categoria') {
              if (this.newProduct[key].length < this.valueProduct[key].length) {
                this.change = true;
              } else {
                this.newProduct[key].forEach(({ id }) => {
                  const findCategory = this.valueProduct[key].some(item => id === item.id);
                  if (!findCategory && !this.change) {
                    this.change = true;
                  }
                });
              }
            } else if (this.newProduct[key] !== this.valueProduct[key]) {
              this.change = true;
            } else if (this.imageProduct.length && this.changeImage) {
              this.change = true;
            }
          }
          if (this.newProduct?.imageURL?.length === 0) {
            this.change = true;
          }
        });
        EventBus.$emit(UPDATE_PRODUCT, this.change);
      },
      deep: true,
    },
    async product(newValue) {
      const value = newValue;

      if (value.precio) {
        value.precio = numeral(newValue.precio).format('$0,0.00');
      }
      value.purchase = numeral(newValue.purchase).format('$0,0.00');

      if (value.id) {
        this.isUpdate = true;
      }

      if (this.idProductState.length > 20 && value.id) {
        this.newProduct = value;
        this.valueProduct = { ...value };
      }

      if (value.imageURL) {
        this.imageProduct = await getDataImage(value.imageURL, value.nombre);
      }
    },
  },
  methods: {
    ...mapActions({
      createProduct: 'product/save',
      getProducts: 'product/getAllProducts',
    }),
    onBeforeDelete(fileRecord) {
      this.$refs.dropZone.deleteFileRecord(fileRecord);
      this.change = true;
      EventBus.$emit(UPDATE_PRODUCT, this.change);
    },
    NumbersOnly(evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
    },
    addTag(newTag) {
      const tag = {
        id: 0,
        name: newTag,
      };
      this.tagsValues.push(tag);
      this.categoriesProduct.push(tag);
    },
    async showChildrensAndParents() {
      await this.getCategoriesLastChildren(this.$route.params.id);
    },
    SearchChange(query) {
      this.query = query;
    },
    async createOrUpdateImages() {
      this.change = false;
      let added = !this.isUpdate ? this.imageProduct : this.imageProduct
        .filter((img) => {
          const url = (typeof img.url === 'string') ? img.url : img.urlResized;
          return !url.includes(process.env.VUE_APP_BASE_IMAGES);
        });

      if (added.length > 0) {
        const mapImages = added.map(({ file }) => {
          const test = saveImage(file, this.$route.params.id);
          return test;
        });
        added = await Promise.resolve(Promise.all(mapImages));
      }

      const existed = this.imageProduct.map(({ url, urlResized }) => ((typeof url === 'string') ? url : urlResized))
        .filter(url => url.includes(process.env.VUE_APP_BASE_IMAGES));

      return existed.concat(added);
    },
    async updateProduct(goToProduct = true) {
      this.isLoading = true;
      const categoriesProduct = this.newProduct.categoria.map(({ id }) => ({ idCategory: id }));
      const imageURL = await this.createOrUpdateImages();

      const dataProduct = this.buildProduct(imageURL, categoriesProduct);
      let responseProduct = {};

      if (this.isUpdate) {
        await this.update(dataProduct);
        this.change = true;
      } else {
        responseProduct = await this.create(dataProduct);
        this.change = true;
      }

      await this.getProducts(this.$route.params.id);

      this.$toasted.global.success(this.$t('shop.product.options.edit-success-text'));
      this.change = false;
      this.isLoading = false;
      EventBus.$emit(UPDATE_PRODUCT, this.change);
      if (!this.isUpdate) {
        if (goToProduct) {
          this.isUpdate = true;
          this.$router.push({
            path: `/store/${this.$route.params.idStore}/product/${responseProduct.data.createProductStore.id}`,
          });
        } else this.cleanForm();
      } else {
        this.valueProduct = { ...this.newProduct };
      }
    },
    create(variables) {
      return this.$apollo.mutate({
        mutation: createProductStore,
        variables,
      });
    },
    update(data) {
      return this.$apollo.mutate({
        mutation: updateProductStore,
        variables: { ...data, id: this.$route.params.idProduct },
      });
    },
    buildProduct(img, categories) {
      return {
        name: this.newProduct.nombre,
        campaign: this.$route.params.id,
        active: this.newProduct.status,
        imageUrl: img,
        categoriesProduct: categories,
        descriptionProduct: this.newProduct.descripcion,
        salePrice: numeral(this.newProduct.precio).value(),
        purchasePrice: numeral(this.newProduct.purchase).value(),
        promotion: this.newProduct.promotion,
        promotionDescription: this.newProduct.promotionDescription,
        stock: Number(this.newProduct.stock) || 0,
        length: Number(this.newProduct.length) || 0,
        width: Number(this.newProduct.width) || 0,
        height: Number(this.newProduct.height) || 0,
        weight: Number(this.newProduct.weight) || 0,
      };
    },
    cleanForm() {
      this.newProduct = {
        status: false,
        promotion: false,
        stock: 1,
      };
      this.imageProduct = [];
      this.$v.$reset();
    },
    focusBuildModal() {
      this.$refs.hccInput.focus();
    },
    selectImage() {
      this.changeImage = true;
      this.change = true;
      EventBus.$emit(UPDATE_PRODUCT, this.change);
    },
    toggleStatus() {
      this.newProduct.status = !this.newProduct.status;
    },
    toggleStatusPromotion() {
      this.newProduct.promotion = !this.newProduct.promotion;
    },
  },
};
</script>
<style scoped lang="scss">
@import "~styles/views/_shop.scss";
</style>
