import { i18n } from '@/i18n';
import categoriesAction from './actions/categorieProduct';
import show from '../../assets/images/categories/mostrar.svg';
import disguise from '../../assets/images/categories/ocultar.svg';

export default {
  namespaced: true,
  state: {
    categories: [],
    categoriesSelected: [],
    elementGroups: [],
    breadcrumbItems: [
      {
        text: 'Categorías',
        disabled: false,
        href: '',
      },
    ],
  },
  getters: {
    getCategoryById: state => idCategory => (
      state.categories.response.find(({ id }) => idCategory === id)
    ),
    getCategoryByIdChildren: state => idCategory => (
      state.categories.response.find(({ id }) => idCategory === id)
    ),
    getSubcategoryById: state => idSubcategorie => state.categories.map(({ subcategories }) => (
      subcategories.find(({ id }) => id === idSubcategorie)
    )).filter(item => item)[0],
  },
  mutations: {
    setItems(state, value) {
      if (value?.name) {
        state.breadcrumbItems.push({
          id: value.id,
          text: value.name,
          disabled: true,
          href: '',
        });
      }
      state.categories = value;
    },
    setElementGroups(state, value) {
      state.elementGroups = (value || []).map(({ listCategories, lastChildren }) => ({
        listCategories: listCategories.replace(/\{(inactive|active)\}/gi, matches => (
          `(${i18n.t(`shop.${matches.replace(/(\{|\})/gi, '')}`)})`
        )),
        lastChildren: lastChildren.map(child => ({
          ...child,
          desc: child.name,
          img: child.active ? show : disguise,
        })),
      }));
    },
    setItemsSelected(state, value) {
      state.categoriesSelected = value;
    },
    setDefaultItems(state) {
      state.breadcrumbItems = [
        {
          text: 'Categorías',
          disabled: false,
          href: '',
        },
      ];
    },
    removeItems(state, value) {
      state.breadcrumbItems = state.breadcrumbItems.filter((item, key) => key <= value);
    },
  },
  actions: {
    ...categoriesAction,
  },
};
