<template>
  <div class="loader-container">
    <overlay-loader :loading="loading" />
    <hcc-table
      :initialPage="1"
      :columns="columns"
      :rows="groups"
      :group-options="{ enabled: true, collapsable: true }"
      @edit="edit"
      @add="openAddGroup"
      @delete="deleteOption"
      @addSubgroup="addSubCategory"
      :pagination="false"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'activo'">
          <div class="badget">
            {{ $t(props.row.activo ? 'shop.product.active' : 'shop.product.inactive') }}
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <transition name="fade">
      <hcc-modal
        :clickToClose="false"
        name="group-options-modal"
        :title="$t('shop.product.options.title-group-form')"
        @confirm="createNewGroup"
        @cancel="cancel"
      >
        <div class="form-modal">
          <hcc-input
            class="modal-input cell"
            v-focuseModal
            :label="$t('common.name')"
            :requiredInput="true"
            :placeholder="$t('shop.product.name-character')"
            :maxLength="100"
            v-model.trim="newGroup.nombre"
          />
          <hcc-select
            :label="$t('shop.product.category')"
            class="cell"
            custom-class="select"
            v-model="newGroup.tipo"
            :placeholder="$t('shop.product.select-option')"
            :options="selections"
            :error="$v.newGroup.tipo.$error"
            :errorMessage="$t('shop.errors.required', { field: $t('shop.product.category') })"
            option-label="value"
            track-by="id"
          />
          <div class="shop__container cell">
            <div class="shop__group">
            <span>{{ $t("shop.product.compulsory-selection") }}</span>
            <div class="shop__status">
              <hcc-toggle-switch
                name="toggle-status-promotion"
                :value="newGroup.required"
                @change="toggleStatusGroup"
              />
            </div>
            </div>
          </div>
        </div>
      </hcc-modal>
    </transition>
    <transition name="fade">
      <ModalFormOptions :option="newOption" @save="createOrUpdateOption" />
    </transition>
    <transition name="fade">
      <error-modal :message="errorMessage" @error="handleErrorConfirm" />
    </transition>
    <transition name="fade">
      <hcc-confirmation />
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import addGroupOptionGql from '@/graphql/mutations/product/addGroupOption.gql';
import removeOptionInGroupGql from '@/graphql/mutations/product/removeOptionInGroup.gql';
import editOptionInGroupGql from '@/graphql/mutations/product/editOptionInGroup.gql';
import addOptionInGroupOptionGql from '@/graphql/mutations/product/addOptionInGroupOption.gql';
import removeOptionInGroupOption from '@/graphql/mutations/product/removeOptionInGroupOption.gql';
import editOptionInGroupOptionGql from '@/graphql/mutations/product/editOptionInGroupOption.gql';

export default {
  components: {
    OverlayLoader,
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
    HccToggleSwitch: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccToggleSwitch/index.vue'),
    ErrorModal: () => import('@/components/ErrorModal.vue'),
    ModalFormOptions: () => import('@/components/shop/options/ModalFormOptions.vue'),
  },
  directives: {
    focus: {
      inserted(el) {
        el.children[0].children[0].focus();
      },
    },
  },
  data() {
    return {
      loading: true,
      options: {},
      newGroup: {
        opciones: [],
      },
      newOption: {},
      errorMessage: null,
    };
  },
  computed: {
    ...mapState({
      groupOptions: state => state.product.groupOptionsItems,
      product: state => state.product.item,
    }),
    columns() {
      return [
        {
          label: this.$t('shop.product.options.name'),
          field: 'name',
        }, {
          label: this.$t('shop.product.options.required'),
          field: 'requiredMsg',
        }, {
          label: this.$t('shop.product.options.extra-cost'),
          field: 'costoExtra',
        }, {
          label: this.$t('shop.product.options.active'),
          field: 'activo',
        },
      ];
    },
    selections() {
      return [
        { id: 'Unica', value: this.$t('shop.product.options.unique-option') },
        { id: 'Multiple', value: this.$t('shop.product.options.multiple-choice') },
      ];
    },
    idProduct() {
      return this.$route.params.idProduct;
    },
    groups() {
      if (!this.product.optionsGroup) {
        return [];
      }
      return this.product.optionsGroup.map((item, index) => ({
        ...item,
        index,
        requiredMsg: item.required ? this.$t('confirm.yes') : this.$t('confirm.no'),
        name: `${item.name} - ${item.type}`,
        originalName: item.name,
        children: (item.opciones || []).map((option, indexSub) => ({
          ...option,
          id: indexSub,
          optionId: option.id,
          parent: item.id,
          editable: false,
          name: option.name,
          lista: option.name,
          title: option.name,
          costoExtra: option.salePrice,
          activo: option.active,
        })),
      }));
    },
  },
  validations: {
    newGroup: {
      nombre: {
        required,
        maxLength: maxLength(100),
      },
      tipo: { required },
    },
  },
  methods: {
    ...mapActions({
      getGroupOption: 'product/getGroupOption',
      removeGroup: 'product/removeGroup',
      saveGroupOptions: 'product/saveGroupOptions',
      getProductById: 'product/getProductById',
    }),
    toggleStatusGroup() {
      this.newGroup.required = !this.newGroup.required;
    },
    edit(row) {
      if (row.parent) {
        this.editSubCategory(row);
      } else {
        const tipo = {
          id: row.type,
          value: row.type === 'Multiple' ? 'Multiple seleccion' : 'Unica (Radio)',
        };
        this.newGroup = {
          id: row.id,
          tipo,
          nombre: row.originalName,
          required: row.required,
        };
        this.$modal.show('group-options-modal');
      }
    },
    deleteOption(row) {
      this.$modal.show('confirmation', {
        title: this.$t('shop.product.remove-product'),
        description: this.$t('shop.product.confirm-remove-product'),
        variant: 'default',
        confirm: () => this.removeGroupOrOption(row),
      });
    },
    openAddGroup() {
      this.newGroup = { opciones: [] };
      this.$modal.show('group-options-modal');
    },
    addSubCategory(row) {
      this.newOption = {
        parent: row.id,
      };
      this.$modal.show('product-form-options');
    },
    editSubCategory(row) {
      this.newOption = { ...row };
      this.$modal.show('product-form-options');
    },
    async createNewGroup() {
      this.loading = true;
      const variables = {
        productId: this.$route.params.idProduct,
        name: this.newGroup.nombre,
        type: this.newGroup.tipo.id,
        required: this.newGroup.required,
      };
      if (this.newGroup.id) {
        variables.groupOptionId = this.newGroup.id;
        await this.$apollo.mutate({
          mutation: editOptionInGroupGql,
          variables,
        });
      } else {
        await this.$apollo.mutate({
          mutation: addGroupOptionGql,
          variables,
        });
      }
      await this.getProductById(this.idProduct);
      this.loading = false;
    },
    async createOrUpdateOption(data) {
      this.loading = true;
      await this.$apollo.mutate({
        mutation: data.optionId ? editOptionInGroupOptionGql : addOptionInGroupOptionGql,
        variables: {
          productId: this.idProduct,
          ...data,
        },
      });

      await this.getProductById(this.idProduct);
      this.loading = false;
    },
    cancel() {
      this.$modal.hide('product-form-options');
      this.$modal.hide('group-options-modal');
    },
    handleErrorConfirm() {

    },
    async removeGroupOrOption(row) {
      this.loading = true;
      if (row.children) {
        await this.$apollo.mutate({
          mutation: removeOptionInGroupGql,
          variables: {
            optionId: row.id,
            productId: this.idProduct,
          },
        });
      } else {
        await this.$apollo.mutate({
          mutation: removeOptionInGroupOption,
          variables: {
            productId: this.idProduct,
            optionId: row.optionId,
            groupOptionId: row.id,
          },
        });
      }
      await this.getProductById(this.idProduct);
      this.loading = false;
    },
  },
  async mounted() {
    await this.getProductById(this.idProduct);
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/_shop.scss";
::v-deep .multiselect.select {
  width: 100%;
}
::v-deep .cell {
  margin-top: 10px;
}
</style>
