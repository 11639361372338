<template>
  <div>
    <section class="main-content">
      <div class="tabs">
        <ul>
          <button @click="redirectRoute('store.product-info')">
            <li>
              <span :class="routeName === 'store.product-info' ? 'is-active' : ''">
                {{ $t('shop.product.tabs.general') }}
              </span>
            </li>
          </button>
          <button
            v-if="idProduct.length > 20"
            @click="redirectRoute('store.product-options')"
          >
              <li>
                <span :class="routeName === 'store.product-options' ? 'is-active' : ''">
                  {{ $t('shop.product.tabs.options') }}
                </span>
              </li>
          </button>
          <button
            v-if="idProduct.length > 20 && myStore.category === 'Tienda'"
            @click="redirectRoute('store.wholesale-price')"
          >
              <li>
                <span :class="routeName === 'store.wholesale-price' ? 'is-active' : ''">
                  {{ $t('shop.product.tabs.wholesale-price') }}
                </span>
              </li>
          </button>
        </ul>
      </div>

      <router-view v-if="reloadView" />
    </section>
     <hcc-confirmation />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  UPDATE_PRODUCT,
} from '@/eventTypes';
import EventBus from '@/eventBus';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';

export default {
  components: {
    HccConfirmation,
  },
  props: {
    idProduct: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      groupData: {},
      component: 'group-options-table',
      reloadView: true,
      updateProduct: false,
    };
  },
  computed: {
    ...mapState({
      product: state => state.product.item,
      myStore: state => state.shop.item,
    }),
    selectedIdGroup() {
      return this.groupData.idGroup;
    },
    routeName() {
      return this.$route.name;
    },
  },
  watch: {
    '$route.name': function watchRoute(to) {
      if (to === 'store.product-info') {
        this.reloadView = false;
        setTimeout(() => {
          this.reloadView = true;
        }, 500);
      }
    },
  },
  methods: {
    ...mapActions({
      getProductById: 'product/getById',
      getGroupOption: 'product/getGroupOption',
    }),
    change({ component, ...data }) {
      this.groupData = data;
      this.component = component;
    },
    redirectRoute(name) {
      if (this.updateProduct) {
        this.$modal.show('confirmation', {
          title: this.$t('channels-settings.change-tab.title'),
          description: this.$t('channels-settings.change-tab.description'),
          confirm: () => {
            this.updateProduct = false;
            this.$router.push({ name });
          },
        });
      } else {
        this.$router.push({ name });
      }
    },
  },
  async mounted() {
    EventBus.$on(UPDATE_PRODUCT, (value) => {
      this.updateProduct = value;
    });
  },
};
</script>

<style scoped lang="scss">
@import '~styles/components/_campaign-configuration.scss';
</style>
