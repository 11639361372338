import { mapState } from 'vuex';
import apolloProvider from '@/apollo';
import { onLogout, getToken } from '@/vue-apollo';
import errorHandler from '@/utils/error.handler';

const redirectUrl = process.env.VUE_APP_REDIRECT_URL ?? 'https://app.heycenter.com';
const apollo = apolloProvider.defaultClient;

export default {
  directives: {
    focuseModal: {
      inserted(el) {
        el.children[1].children[0].focus();
      },
    },
  },
  computed: {
    ...mapState({
      windowWidth: state => state.windowWidth,
    }),
    isMobile() {
      return this.windowWidth <= 640;
    },
  },
  methods: {
    randomID() {
      return `_${Math.random().toString(36).substr(2, 9)}`;
    },
    networkErr(err) {
      if (!err.message.includes('Invalid Token') && !err.name.includes('TypeError')) {
        errorHandler.logErrors(err);
      }

      if ((err.message.includes('401') || err.message.includes('Invalid Token')) && getToken()) {
        onLogout(apollo)
          .then(() => {
            window.location.href = redirectUrl;
          })
          .catch((error) => {
            errorHandler.logErrors(error);
          });
      }
    },
    truncateWithDots(str, maxLength) {
      return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
    },
    globalFormatTime(dt) {
      return this.$moment(dt).local().format('h:mm A');
    },
    globalFormatDatetime(dt) {
      return this.$moment(dt).local().format('DD/MM/YYYY h:mm A');
    },
    globalFormatRelativeDateTime(dt, forceTime = false, showToday = false) {
      let format;
      const today = this.$moment().local().startOf('day');
      const local = this.$moment(dt).local().startOf('day');
      const isFromToday = today.isSame(local, 'day');
      const isWithinLastWeek = today.diff(local, 'days') < 7;

      if (isFromToday) {
        if (showToday) {
          return 'Today';
        }

        format = 'h:mm A';
      } else if (isWithinLastWeek) {
        format = forceTime ? 'dddd h:mm A' : 'dddd';
      } else {
        format = forceTime ? 'DD/MM/YYYY h:mm A' : 'DD/MM/YYYY';
      }

      return this.$moment(dt).local().format(format);
    },
    mapTime(dt) {
      if (dt) {
        const date = new Date(dt);
        return date.toLocaleDateString();
      }
      return 'No registrado';
    },
    capitalizeFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
