<template>
  <div class="card-buttons">
    <div class="card-buttons__settings">
      <hcc-button
        v-if="isValidUser"
        class="card-buttons__settings-button"
        @click="openChannelsSettings"
        color="primary"
      >
        {{ channelSettings }}
      </hcc-button>
      <hcc-button
        class="card-buttons__settings-button"
        color="secondary"
        @click="openCampaignSettings"
      >
        {{ campaignSettings }}
      </hcc-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HccButton from '@/components/shared/HccButton/index.vue';

export default {
  components: {
    HccButton,
  },
  props: {
    showDuplicateButton: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      userRole: 'shared/getUserRole',
    }),
    campaignSettings() {
      return this.$t('campaigns.cards.campaign-settings');
    },
    channelSettings() {
      return this.$t('campaigns.cards.channels-settings');
    },
    isValidUser() {
      return ['owner', 'super'].includes(this.userRole);
    },
  },
  methods: {
    openCampaignSettings() {
      this.$emit('campaign-settings');
    },
    openChannelsSettings() {
      this.$emit('channels-settings');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/_campaign-card.scss";
</style>
