import errorHandler from '@/utils/error.handler';
import getShopByCampaign from '@/graphql/queries/campaigns/getShopByCampaignId.gql';
import listShops from '@/graphql/queries/campaigns/listShops.gql';
import getStats from '@/graphql/queries/campaigns/getStats.gql';
import executeQuery from '@/utils/gql-api';

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    session: window.localStorage.getItem('SELLIA-SESSION'),
    currentCar: {},
    statistics: {},
    channelsStatics: [],
    category: null,
  },
  getters: {
    getCurrentShop(state) {
      return state.item;
    },
    getShops(state) {
      return state.items.map(el => ({
        ...el,
        loading: false,
      }));
    },
  },
  mutations: {
    setCategory(state, value) {
      state.category = value;
    },
    // setChannelsStatics(state, value) {
    //   state.channelsStatics = value;
    // },
    setItems(state, value) {
      state.items = value;
    },
    setItem(state, value) {
      state.item = value;
    },
    setCurrentCar(state, value) {
      state.currentCar = value;
    },
    createdOrUpdated(state, value) {
      const index = state.items.findIndex(({ id }) => value.id === id);
      if (index !== -1) {
        state.items.splice(index, 1, { ...value.data(), id: value.id });
      }
    },
    removeItem(state, elementId) {
      const index = state.items.findIndex(({ id }) => elementId === id);
      if (index !== -1) {
        state.items.splice(index, 1);
      }
    },
    setStatistic(state, value) {
      state.statistics = value;
    },
    setSession(state, value) {
      state.session = value;
    },
  },
  actions: {
    async getStoreOfUser({ commit }, campaigns) {
      if (campaigns?.length > 0) {
        executeQuery('listShops',
          listShops,
          {
            campaign: campaigns,
          }, false)
          .then((payload) => {
            commit('setItems', payload);
          })
          .catch((err) => {
            errorHandler.logErrors(err);
            commit('setItems', []);
          });
      } else {
        commit('setItems', []);
      }
    },
    async clearStoreOfUser({ commit }) {
      commit('setItems', []);
    },
    async getById({ commit }, id) {
      if (id?.length > 20) {
        executeQuery('getShopByCampaignId',
          getShopByCampaign,
          {
            id,
          }, false)
          .then((payload) => {
            const shop = {
              id: payload.id,
              campaign: id,
              description: payload.description,
              category: payload.category,
              address: payload.address,
              imageUrl: payload.imageUrl,
              backgroundUrl: payload.backgroundUrl,
              ext: payload.ext,
              placeId: payload.placeId,
              nameIdCanonical: payload.nameIdCanonical,
              name: payload.name,
              nameLegal: payload.nameLegal,
              updatedAt: payload.updatedAt,
              originalIndex: payload.originalIndex,
              size: payload.size,
              delivery: payload.delivery,
              settings: payload.settings,
              location: payload.location,
              paymentMethod: payload.paymentMethod,
            };
            commit('setItem', shop);
            commit('setCategory', shop.category);
          })
          .catch((err) => {
            errorHandler.logErrors(err);
            commit('setItem', {});
          });
      } else {
        commit('setItem', null);
      }
    },
    async update({ commit }, data) {
      try {
        commit('setItem', data);
        commit('setCategory', data.category);
      } catch (err) {
        errorHandler.logErrors(err);
      }
    },
    getStatistics({ commit }, { campaigns, endDate, startDate }) {
      try {
        if (campaigns?.length > 0) {
          executeQuery('getStats',
            getStats,
            {
              campaign: campaigns,
              start: startDate,
              end: endDate,
            }, false)
            .then((payload) => {
              commit('setStatistic', payload);
              // commit('setChannelsStatics', payload.channels);
            })
            .catch((err) => {
              errorHandler.logErrors(err);
              commit('setStatistic', []);
            });
        } else {
          commit('setStatistic', []);
        }
      } catch (err) {
        errorHandler.logErrors(err);
      }
    },
    removeSession({ commit }) {
      commit('setSession', null);
      window.localStorage.removeItem('SELLIA-SESSION');
    },
  },
};
