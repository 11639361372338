import Vue from 'vue';
import * as Sentry from '@sentry/vue';

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  tracesSampleRate: 1.0,
  logErrors: true,
});

async function logErrors(err) {
  if (process.env.NODE_ENV === 'production') {
    Sentry.captureException(err);
  } else {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export default { logErrors };
