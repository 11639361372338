<template>
  <div>
    <dashboard-agents-cards :filters="filters" />
    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-12">
        <data-chart
          ref="agentsByCategories"
          name="chart.agents.by-categories"
          chart-id="agentsByCategories"
          type="stackedColumn"
          :paginate="true"
          :per-page="8"
          :chart-data="finalByCategories"
          :doubleChart="doubleChart('agentsByCategories')"
          :stackName="emptyStack('agentsByCategories')"
          :filters="filters"
          @change="chartRangeChanged"
          @change-filter="filterChartData('agentsByCategories', 0, $event)"
          @update-filter="filterChartData('agentsByCategories', 1, $event)"
          @clear-filter="clearChartFilter('agentsByCategories', $event)"
          @clear-date="clearDateFilter('agentsByCategories', $event)"
        />
      </div>
      <div class="cell small-12 medium-12">
        <data-table
          :translatable="true"
          :formats="tableAgentsKpi.formats"
          :table-id="'agentsKpi'"
          :name="'chart.agents.kpi'"
          :headers="tableAgentsKpi.headers"
          :rows="tableAgentsKpi.rows"
        />
      </div>
      <div class="cell small-12 medium-12">
        <data-chart
          ref="agentsTotal"
          name="chart.agents.total"
          chart-id="agentsTotal"
          type="line"
          :filterSeries="true"
          :placeholder="$t('dashboard.filter.agents-placeholder')"
          :chart-data="finalTotal"
          :doubleChart="doubleChart('agentsTotal')"
          :stackName="emptyStack('agentsTotal')"
          :filters="filters"
          @change="chartRangeChanged"
          @change-filter="filterChartData('agentsTotal', 0, $event)"
          @update-filter="filterChartData('agentsTotal', 1, $event)"
          @clear-filter="clearChartFilter('agentsTotal', $event)"
          @clear-date="clearDateFilter('agentsTotal', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _uniq from 'lodash/uniq';
import _isEmpty from 'lodash/isEmpty';
import AgentsByCategories from '@/graphql/queries/agents/byCategories.gql';
import AgentsKpi from '@/graphql/queries/agents/kpi.gql';
import AgentsTotal from '@/graphql/queries/agents/total.gql';
import executeQuery from '@/utils/gql-api';
import DataChart from '@/components/DataChart.vue';
import DataTable from '@/components/DataTable.vue';
import DashboardAgentsCards from '@/components/DashboardAgentsCards.vue';

export default {
  components: {
    DataChart,
    DataTable,
    DashboardAgentsCards,
  },
  props: {
    filters: Object,
  },
  data() {
    return {
      tableAgentsKpi: {
        headers: [],
        rows: [],
        formats: [
          false,
          'number',
          'time',
          'time',
        ],
      },
      chart: {
        agentsByCategories: [],
        agentsTotal: [],
      },
      dateFilter: null,
    };
  },
  watch: {
    filters: {
      handler(newValue) {
        if (newValue !== null) {
          this.initAll();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      campaign: 'setting/getCurrentCampaign',
    }),
    finalByCategories() {
      return this.getChartData('agentsByCategories');
    },
    finalTotal() {
      return this.getChartData('agentsTotal');
    },
  },
  methods: {
    initAll() {
      this.loadAgentsKpi();

      this.resetDateInput();
      this.resetCharts();
      this.fetchChartData('agentsByCategories', 0);
      this.fetchChartData('agentsTotal', 0);
    },
    async loadAgentsKpi() {
      const data = await this.fetchData('agentsKpi', AgentsKpi, this.filters);

      this.tableAgentsKpi.headers = data.categories;
      this.tableAgentsKpi.rows = data.series;
    },
    async fetchData(id, query, variables = {}) {
      this.$store.commit('dashboard/enableLoader', id);
      const data = await executeQuery(id, query, variables);
      this.$store.commit('dashboard/disableLoader', id);

      return data;
    },
    chartRangeChanged(chartId, from, to) {
      if (from !== null && to !== null) {
        const startDate = this.$moment(from)
          .format('YYYY-MM-DD');
        const endDate = this.$moment(to)
          .format('YYYY-MM-DD');

        this.dateFilter = {
          startDate,
          endDate,
        };

        this.resetChart(chartId);
        this.filterChartData(chartId, 0, this.dateFilter);
      }
    },
    async fetchChartData(name, index, paramFilters = {}) {
      const filters = { ...this.filters, ...paramFilters };

      const filterName = paramFilters.name || this.campaign.name;

      const nameToQuery = {
        agentsByCategories: AgentsByCategories,
        agentsTotal: AgentsTotal,
      };

      const response = await this.fetchData(name, nameToQuery[name], filters);

      response.name = filterName;

      response.series = response.series
        .map(serie => ({ ...serie, stack: filterName }));

      const resultChart = [...this.chart[name]];

      resultChart[index] = response;

      this.chart[name] = resultChart;
    },
    getChartData(name) {
      if (this.chart[name].length === 0) {
        return {};
      }

      const final = {
        dateFormat: this.chart[name][0].dateFormat,
        categories: [],
        series: [],
      };

      this.chart[name].forEach((data) => {
        final.categories = [...final.categories, ...data.categories];
      });

      final.categories = _uniq(final.categories);

      const { categories } = final;

      this.chart[name].forEach((data) => {
        const series = data.series.map(serie => ({
          ...serie,
          filter: data.name,
          data: this.fillSeries(
            serie.data,
            data.categories,
            categories,
          ),
        }));
        final.series = [...final.series, ...series];
      });

      return final;
    },
    fillSeries(series, initCategories, finalCategories) {
      return finalCategories.map((final) => {
        const index = initCategories.findIndex(init => init === final);

        return index === -1 ? 0 : series[index];
      });
    },
    async filterChartData(from, index, filters) {
      await this.fetchChartData(from, index, this.getFilters(from, filters));
    },
    clearChartFilter(name, filters) {
      this.chart[name].splice(0, 1);
      this.fetchChartData(name, 0, this.getFilters(name, filters));
    },
    getFilters(name, filters) {
      const chartFilters = { ...filters };

      if (filters.startDate && filters.campaignId) {
        this.$refs[name].resetFilters();
        this.dateFilter = null;
      }

      if (this.dateFilter?.startDate) {
        chartFilters.startDate = this.dateFilter.startDate;
        chartFilters.endDate = this.dateFilter.endDate;
      }

      return chartFilters;
    },
    clearDateFilter(name, filters) {
      this.chart[name].splice(0, 2);
      this.fetchChartData(name, 0,
        {
          campaignId: filters.firstCampaign.id,
          name: filters.firstCampaign.name,
        });
      this.fetchChartData(name, 1,
        {
          campaignId: filters.secondCampaign.id,
          name: filters.secondCampaign.name,
        });
    },
    resetChart(name) {
      this.chart[name].splice(0, 2);
    },
    resetCharts() {
      this.chart.agentsByCategories.splice(0, 2);
      this.chart.agentsTotal.splice(0, 2);
    },
    resetDateInput() {
      if (!_isEmpty(this.$refs)) {
        this.$refs.agentsByCategories.resetFilters();
        this.$refs.agentsTotal.resetFilters();
      }
      this.dateFilter = null;
    },
    emptyStack(name) {
      const result = this.chart[name].filter(data => data.series.length === 0);
      return result.length === this.chart[name].length ? '' : result[0]?.name;
    },
    doubleChart(name) {
      return this.chart[name].length === 2;
    },
  },
  mounted() {
    if (this.filters !== null) {
      this.initAll();
    }
  },
};
</script>
