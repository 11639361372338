<template>
  <div class="card">
    <div class="loading" v-if="isLoading" />
    <div
      class="status"
      :class="{
        'status-editable': showStatusButton,
        'status-disabled': !isActive,
      }"
      v-on="showStatusButton ? { click: changeStatus } : {}"
      v-tooltip="{ content: deactivate, visible: showStatusButton }"
    ></div>
    <div class="grid-x">
      <div v-if="isBlank" class="loader-wrapper">
        <card-loader />
      </div>
      <template v-else>
        <div class="panel-container">
          <card-left-panel
            class="left-panel"
            :campaign="campaign"
            :isOwner="isOwner"
            @updated="updateCampaign"
          />
          <card-right-panel
            class="right-panel"
            :createdAt="createdAt"
            :mainCampaign="mainCampaign"
            :isOwner="isOwner"
            :isSuper="isSuper"
            :showDuplicateButton="showDuplicateButton"
            :showDeleteButton="canDelete"
            @edit="openEdit"
            @campaign-duplicate="openConfirmDuplicate"
            @delete-campaign="openConfirmDelete"
          />
        </div>
        <card-buttons
          class="card__buttons"
          @campaign-settings="openCampaignSettings"
          @channels-settings="openChannelsSettings"
        />
      </template>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import listProductsGql from '@/graphql/queries/products/listProducts.gql';
import CardLoader from '@/components/loaders/CampaignCard.vue';
import executeQuery from '@/utils/gql-api';
import CardLeftPanel from './CardLeftPanel.vue';
import CardRightPanel from './CardRightPanel.vue';
import CardButtons from './CardButtons.vue';

export default {
  components: {
    CardLoader,
    CardLeftPanel,
    CardRightPanel,
    CardButtons,
  },
  data: () => ({
    products: [],
  }),
  props: {
    campaign: {
      required: true,
    },
    isOwner: {
      required: true,
      type: Boolean,
    },
    showDuplicateButton: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      isSuper: 'shared/isSuper',
      campaigns: 'setting/getCampaigns',
      companies: 'shared/getFilterCompanies',
    }),
    ...mapState({
      selectedStore: state => state.shop.item,
    }),
    isBlank() {
      return !!this.campaign.isBlank;
    },
    isLoading() {
      return !!this.campaign.loading;
    },
    isActive() {
      return !!this.campaign.active;
    },
    showStatusButton() {
      return !this.isOwner && this.isActive;
    },
    createdAt() {
      if (this.campaign.createdAt) {
        const createdAt = this.$moment(this.campaign.createdAt);
        return createdAt.format('DD/MM/YYYY');
      }

      return 'N/A';
    },
    mainCampaign() {
      if (this.campaign.mainCampaign) {
        return true;
      }

      return false;
    },
    deactivateCampaign() {
      return this.$t('campaigns.cards.deactivate-campaign');
    },
    deactivate() {
      return this.$t('campaigns.cards.deactivate');
    },
    validateAllDataStore() {
      return this.selectedStore.name
        && this.selectedStore.description;
    },
    validateLocationsStore() {
      return this.selectedStore.address
        && this.selectedStore.location.coordinates[0] !== 0
        && this.selectedStore.location.coordinates[1] !== 0;
    },
    validateProducts() {
      return this.products.length > 0;
    },
    canDelete() {
      return !this.campaign.active;
    },
  },
  methods: {
    ...mapActions({
      store: 'shop/getById',
      selectCampaign: 'setting/selectCampaign',
    }),
    ...mapMutations({
      setCurrentCampaign: 'setting/setCurrentCampaign',
    }),
    changeStatus() {
      const data = {
        id: this.campaign.id,
        active: false,
      };

      this.$modal.show('confirmation', {
        title: this.deactivateCampaign,
        description: this.$t('campaigns.cards.activate-confirmation'),
        variant: 'default',
        confirm: () => this.$emit('updated', data),
      });
    },
    updateCampaign(campaign) {
      this.$emit('updated', campaign);
    },
    openEdit(action) {
      if (action === 'blocked-client') {
        this.selectCampaign(this.campaign.id);
        this.$router.push(`/campaigns/${this.campaign.id}/blocked-clients`);
      } else if (action === 'show-modal') {
        this.$emit('edit');
      }
    },
    openCampaignSettings() {
      localStorage.currentCampaign = JSON.stringify(this.campaign);
      this.$emit('campaign-settings', this.campaign.id);
    },
    openChannelsSettings() {
      localStorage.currentCampaign = JSON.stringify(this.campaign);
      this.$emit('channels-settings', this.campaign.id);
    },
    async getProducts(id) {
      const products = await executeQuery(
        'listProducts', listProductsGql, {
          campaign: id,
        },
        false,
      );
      this.products = products;
    },
    async getShopData(id) {
      await this.store(id);
      await this.getProducts(id);
    },
    async openConfirmDuplicate() {
      const {
        id,
        schedules,
        categories,
        channels,
        admins,
        agents,
      } = this.campaign;
      await this.getShopData(id);

      if (schedules.length <= 0) {
        this.$toasted.global.error(this.$t('campaigns.cards.schedules-error'));
        return;
      }

      if (categories.length <= 0) {
        this.$toasted.global.error(this.$t('campaigns.cards.categories-error'));
        return;
      }

      if (channels.length <= 0) {
        this.$toasted.global.error(this.$t('campaigns.cards.channels-error'));
        return;
      }

      if (!this.validateAllDataStore) {
        this.$toasted.global.error(this.$t('campaigns.cards.shop-error'));
        return;
      }

      if (!this.validateLocationsStore) {
        this.$toasted.global.error(this.$t('campaigns.cards.shop-locations-error'));
        return;
      }

      if (!this.validateProducts) {
        this.$toasted.global.error(this.$t('campaigns.cards.products-error'));
        return;
      }

      if (admins <= 0) {
        this.$toasted.global.error(this.$t('campaigns.cards.admins-error'));
        return;
      }

      if (agents <= 0) {
        this.$toasted.global.error(this.$t('campaigns.cards.agents-error'));
        return;
      }

      const company = this.findCompany(this.campaign);
      const maxAllowed = Math.floor(company ? company.maxUsers / 2 : 0);
      if (maxAllowed <= this.campaigns.length) {
        this.$toasted.global.error(this.$t('campaings.cards.max-allowed', { max: maxAllowed }));
        return;
      }

      this.$modal.show('confirmation', {
        title: this.$t('campaigns.cards.duplicate-campaign'),
        description: this.$t('campaigns.cards.duplicate-confirmation'),
        variant: 'default',
        confirm: () => this.$emit('duplicate-campaign', this.campaign),
      });
    },
    openConfirmDelete() {
      this.$modal.show('confirmation', {
        title: this.$t('campaigns.cards.delete-campaign'),
        description: this.$t('campaigns.cards.delete-confirmation'),
        variant: 'default',
        confirm: () => this.$emit('delete-campaign', this.campaign),
      });
    },
    findCompany({ company }) {
      return this.companies.find(({ id }) => id === company);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_campaign-card.scss";
</style>
