<template>
  <div class="orders-container">
    <div class="orders">
      <div v-if="orders.length === 0">
        <div class="orders__empty">
          <div class="orders__empty-container">
            <img :src="require('@/assets/images/paper.svg')"
              class="orders__icon" />
          </div>
          <span class="orders__text">{{ $t('multiselect.no-result') }}</span>
        </div>
      </div>
      <order-card v-else v-for="order in orders" :key="order.id" :order="order"
        @view-detail="viewOrderDetails" @update-status="updateOrderStatus"
        @cancel="cancelOrder" />
    </div>
    <hcc-pagination v-if="total > 6" :total="total" :rowsPerPage="6"
      :actualPage="actualPage" :pageChanged="changePage"
      class="orders__pagination" />
  </div>
</template>

<script>
import OrderCard from './OrderCard.vue';
import HccPagination from '../../components/shared/HccTable/HccPagination.vue';

export default {
  components: { OrderCard, HccPagination },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    total: Number,
    actualPage: Number,
    pageChanged: {
      type: Function,
    },
  },
  methods: {
    viewOrderDetails(order) {
      this.$emit('view-detail', order);
    },
    updateOrderStatus(order) {
      this.$emit('update-status', order);
    },
    cancelOrder(order) {
      this.$emit('cancel', order);
    },
    changePage(page) {
      this.$emit('change-page', page.currentPage);
    },
  },
};

</script>

<style scoped lang="scss">
@import "~styles/components/_order-card.scss";
</style>
