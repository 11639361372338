<template>
  <div>
    <span class="right-panel__main" v-if="mainCampaign">
      {{ $t('configuration.options.main-campaign.status') }}
    </span>
    <span class="right-panel__date">{{ createdAt }}</span>
    <hcc-dropdown
      v-if="showButton"
      position="bottom-left"
      customClass="custom-position"
    >
      <template #button>
        <hcc-button-icon
          size="xl"
          round
          color="primary"
          class="right-panel__icon"
          ><more-icon />
        </hcc-button-icon>
      </template>
      <hcc-dropdown-item
        @click="openEdit('show-modal')"
        icon="pencil-icon"
        v-if="isOwner"
      >
        <span>{{ edit }}</span>
      </hcc-dropdown-item>
      <hcc-dropdown-item
        icon="copy-icon"
        v-if="showDuplicateButton"
        @click="duplicateCampaign"
      >
        <span>{{ duplicate }}</span>
      </hcc-dropdown-item>
      <hcc-dropdown-item
        icon="delete-icon"
        v-if="showDeleteButton"
        @click="deleteCampaign"
      >
        <span>{{ deleteItem }}</span>
      </hcc-dropdown-item>
      <hcc-dropdown-item icon="delete-icon" @click="openEdit('blocked-client')">
        <span>{{ blocked }}</span>
      </hcc-dropdown-item>
    </hcc-dropdown>
  </div>
</template>

<script>
import HccButtonIcon from '@/components/shared/HccButtonIcon/index.vue';
import HccDropdown from '@/components/shared/HccDropdown/index.vue';
import HccDropdownItem from '@/components/shared/HccDropdown/DropdownItem.vue';


export default {
  components: {
    HccButtonIcon,
    HccDropdown,
    HccDropdownItem,
  },
  props: {
    createdAt: {
      type: String,
      required: true,
    },
    mainCampaign: {
      type: Boolean,
      required: true,
    },
    isOwner: {
      type: Boolean,
      required: true,
    },
    isSuper: {
      type: Boolean,
      required: true,
    },
    showDuplicateButton: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    options() {
      return this.$t('configuration.tabs.options');
    },
    edit() {
      return this.$t('common.edit');
    },
    duplicate() {
      return this.$t('campaigns.cards.duplicate');
    },
    deleteItem() {
      return this.$t('campaigns.cards.delete');
    },
    showButton() {
      return this.isOwner || this.isSuper;
    },
    blocked() {
      return this.$t('campaigns.cards.blocked-client');
    },
  },
  methods: {
    openEdit(action) {
      this.$emit('edit', action);
    },
    duplicateCampaign() {
      this.$emit('campaign-duplicate');
    },
    deleteCampaign() {
      this.$emit('delete-campaign');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/_campaign-card.scss";
</style>
