<template>
  <div class="select-container">
    <label
      v-if="label"
      class="select__label"
      :class="requiredInput && 'select__label-required'">
      {{ label }}
    </label>
    <multiselect
      v-bind="$attrs"
      v-on="$listeners"
      :label="optionLabel"
      :show-labels="showLabels"
      :searchable="searchable"
      :allow-empty="allowEmpty"
      :deselect-label="deselectLabel"
      :disabled="disabled"
      :class="[customClass, { error: showMessagesError }, { disabled }]"
      :limit-text="limitText"
      @close="close"
      @open="toggle"
      ref="multiselect"
      @search-change="asyncFind"
    >
      <template slot="option" slot-scope="props" v-if="props.option.icon">
        <component
          class="select__icon"
          :is="props.option.icon"
          :class="props.option.color"
        />
        <span class="option__title">{{ props.option.title }}</span>
      </template>
      <template
        slot="information"
        slot-scope="props"
        v-if="props.option.information"
      >
        <span class="option__title">{{ props.option.name }} : </span>
        <span>{{ props.option.information }}</span>
      </template>
      <template slot="option" slot-scope="props" v-if="props.option.img">
        <div>
          <div class="img__align">
            <img class="img" :src="props.option.img">
          </div>
          <div class="option__desc">
            <span class="option__title">{{ props.option.title }}</span>
            <span class="option__small">{{ props.option.desc }}</span>
          </div>
        </div>
      </template>
      <template slot="selection" slot-scope="{ values, isOpen }">
        <span class="multiselect__single" v-if="values.length > 2 && !isOpen">
          {{ values.length }} {{ selectedText }}
        </span>
        <span v-else-if="isOpen"></span>
      </template>
      <template slot="noResult">{{ $t("multiselect.no-result") }}</template>
      <template slot="noOptions">{{ $t("multiselect.no-options") }}</template>
      <template slot="caret" v-if="showMessagesError">
        <div class="icon error"><alert-circle /></div>
      </template>
      <slot />
    </multiselect>
    <div v-if="showMessagesError" class="select__error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    showLabels: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    optionLabel: {
      type: String,
      required: true,
    },
    deselectLabel: {
      type: String,
    },
    label: String,
    customClass: {
      type: [String, Object, Array],
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedText: {
      type: String,
      default: 'Opciones seleccionadas',
    },
  },
  data() {
    return {
      isOpen: false,
      showMessagesError: false,
    };
  },
  methods: {
    focus() {
      this.$refs.multiselect.$el.focus();
    },
    close() {
      if (this.requiredInput && this.$attrs.value?.length === 0) {
        this.isOpen = true;
        this.showMessagesError = true;
      }
      this.$refs.multiselect.$el.blur();
    },
    toggle() {
      if (this.isOpen) {
        this.$refs.multiselect.$el.blur();
        this.isOpen = false;
      } else {
        this.$refs.multiselect.$el.focus();
        this.isOpen = true;
      }
    },
    asyncFind(query) {
      if (this.search && query.length >= 3) {
        this.$emit('OnSearchChange', query);
      }
    },
    limitText(count) {
      return this.$t('multiselect.limit-suffix', { count });
    },
  },
  watch: {
    '$attrs.value': function valueSelect(newState, oldState) {
      this.isOpen = false;
      if (this.requiredInput && newState?.length === 0 && oldState?.length === 1) {
        this.showMessagesError = true;
      } else {
        this.showMessagesError = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "~styles/shared/textarea.scss";
@import "~styles/shared/inputs.scss";
</style>
