<template>
  <div class="channels__info">
    <div class="channels__group">
      <span>{{ whatsappSettings["api-key"] }}</span>
      <span class="channels__description">{{ apiKey }}</span>
    </div>
    <div class="channels__group">
      <span>{{ whatsappSettings.provider }}</span>
      <span class="channels__select">{{ provider }}</span>
    </div>
    <div class="channels__group" v-if="isGupshup">
      <span>Source</span>
      <span class="channels__select">{{ source }}</span>
    </div>
    <div class="channels__group" v-if="isGupshup">
      <span>APP Name</span>
      <span class="channels__select">{{ appName }}</span>
    </div>
    <div class="channels__group">
      <span>{{ whatsappSettings["id-admin"] }}</span>
      <span class="channels__description">{{ id }}</span>
    </div>
    <div class="channels__group">
      <span>{{ whatsappSettings["attach-logo"] }}</span>
      <span class="channels__description">{{ logo }} </span>
    </div>
    <hcc-card
      :title="businessProfile"
      class="channels__profile"
      bodyClass="channels__profile-body"
    >
      <div class="channels__group">
        <span>{{ whatsappSettings.about }}</span>
        <span class="channels__description">{{ about }}</span>
      </div>
      <div class="channels__group">
        <span>{{ whatsappSettings.address }}</span>
        <span class="channels__description">{{ address }}</span>
      </div>
      <div class="channels__group">
        <span>{{ whatsappSettings["business-desc"] }}</span>
        <span class="channels__description">{{ businessDescription }}</span>
      </div>
      <div class="channels__group">
        <span>{{ whatsappSettings.category }}</span>
        <span class="channels__description">{{ category }}</span>
      </div>
      <div class="channels__group">
        <span>{{ whatsappSettings["contact-email"] }}</span>
        <span class="channels__description">{{ contactEmail }}</span>
      </div>
      <div class="channels__group">
        <span>{{ whatsappSettings.website }}</span>
        <span class="channels__description">{{ website }}</span>
      </div>
    </hcc-card>
  </div>
</template>

<script>
import HccCard from '@/components/shared/HccCard/index.vue';

export default {
  components: {
    HccCard,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    whatsappSettings() {
      return this.$t('channels-settings.whatsapp');
    },
    businessProfile() {
      return this.$t('channels-settings.whatsapp.business-profile');
    },
    apiKey() {
      return this.channel.extras ? this.channel.extras.apiKey : '';
    },
    logo() {
      return this.$t('channels-settings.whatsapp.no-file');
    },
    id() {
      return this.channel.extras ? this.channel.extras.businessId : '';
    },
    about() {
      return this.channel.extras ? this.channel.extras.about : '';
    },
    address() {
      return this.channel.extras ? this.channel.extras.address : '';
    },
    businessDescription() {
      return this.channel.extras ? this.channel.extras.description : '';
    },
    category() {
      return this.channel.extras ? this.channel.extras.category : '';
    },
    provider() {
      return this.channel.extras ? this.channel.extras.waProvider : '';
    },
    contactEmail() {
      return this.channel.extras ? this.channel.extras.email : '';
    },
    website() {
      return this.channel.extras ? this.channel.extras.website : '';
    },
    source() {
      return this.channel.extras ? this.channel.extras.source : '';
    },
    appName() {
      return this.channel.extras ? this.channel.extras.appName : '';
    },
    isGupshup() {
      return this.provider === 'gupshup';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
