<template>
  <div>
    <div class="grid-x grid-margin-x">
      <div class="cell small-6 medium-4 large-3">
        <data-card v-bind:card-id="'storeDataCards'" v-bind:theme="'green'"
          v-bind:name="'shop.statistics.sales'"
          v-bind:value="statistics.sales || 0">
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card v-bind:card-id="'storeDataCards'" v-bind:theme="'yellow'"
          v-bind:name="'shop.statistics.visits'"
          v-bind:value="statistics.sessions || 0">
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card v-bind:card-id="'storeDataCards'" v-bind:theme="'red'"
          v-bind:name="'shop.statistics.prospects'"
          v-bind:value="statistics.leads || 0">
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card v-bind:card-id="'storeDataCards'" v-bind:theme="'green'"
          v-bind:name="'shop.statistics.full-carts'"
          v-bind:value="statistics.fullCarts || 0">
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card v-bind:card-id="'storeDataCards'" v-bind:theme="'green'"
          v-bind:name="'shop.statistics.total-amount'"
          v-bind:value="formatNumber(statistics.salesAmount || 0)">
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card v-bind:card-id="'storeDataCards'" v-bind:theme="'yellow'"
          v-bind:name="'shop.statistics.profits'"
          v-bind:value="formatNumber(statistics.profits || 0)">
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card v-bind:card-id="'storeDataCards'" v-bind:theme="'red'"
          v-bind:name="'shop.statistics.sold-products'"
          v-bind:value="statistics.cantidad || 0">
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card v-bind:card-id="'storeDataCards'" v-bind:theme="'green'"
          v-bind:name="'shop.statistics.qr-visits'"
          v-bind:value="statistics.visitorsQr || 0">
          <message-icon />
        </data-card>
      </div>
    </div>
    <div class="cell small-12 medium-12">
      <data-chart ref="topProducts" :name="'shop.statistics.top-products'"
        :chart-id="'topProducts'" :chart-data="dataTopProducts" type="pie"
        :doubleChart="doubleChart(stackNameTopProduct)"
        :stackName="stackNameTopProduct" :type-loader="'piechart'"
        :activeFilterDate="true" @change="chartRangeChanged"
        @change-filter="filterChartData('topProducts', 0, $event)"
        @update-filter="filterChartData('topProducts', 1, $event)"
        @clear-filter="clearDateFilter('topProducts')" />
    </div>
    <div class="cell small-12 medium-12">
      <data-chart ref="deliveryMethods" :name="'shop.sale.delivery-method'"
        :chart-id="'deliveryMethods'" :chart-data="dataDelivery" type="pie"
        :doubleChart="doubleChart(stackNameDelivery)"
        :stackName="stackNameDelivery" :type-loader="'piechart'"
        :activeFilterDate="true" @change="chartRangeChanged"
        @change-filter="filterChartData('deliveryMethods', 0, $event)"
        @update-filter="filterChartData('deliveryMethods', 1, $event)"
        @clear-filter="clearDateFilter('deliveryMethods')" />
    </div>
    <div class="cell small-12 medium-12">
      <data-chart ref="paymentMethods" :name="'shop.sale.paymen-method'"
        :chart-id="'paymentMethods'" :chart-data="dataPaymentMethod" type="pie"
        :doubleChart="doubleChart(stackNamePayment)"
        :stackName="stackNamePayment" :type-loader="'piechart'"
        :activeFilterDate="true" @change="chartRangeChanged"
        @change-filter="filterChartData('paymentMethods', 0, $event)"
        @update-filter="filterChartData('paymentMethods', 1, $event)"
        @clear-filter="clearDateFilter('paymentMethods')" />
    </div>
    <data-chart
        ref="salesDensity"
        name="shop.sale.sales-density"
        chart-id="salesDensity"
        type="heatmap"
        :null-to-zeroes="true"
        :chart-data="finalSalesDensity"
        :filters="filters"
        :stackName="'salesDensity'"
        :doubleChart="true"
        :secondHeatmapSeries="secondHeatmapSeries"
        @change="chartRangeChanged"
        @change-filter="filterChartData('salesDensity', 0, $event)"
        @update-filter="filterChartData('salesDensity', 1, $event)"
        @clear-filter="clearDateFilter('salesDensity')"
      />
  </div>
</template>

<script>
import numeral from 'numeral';
import { mapActions, mapGetters, mapState } from 'vuex';
import DataCard from '@/components/DataCard.vue';
import getStats from '@/graphql/queries/campaigns/getStats.gql';
import executeQuery from '@/utils/gql-api';
import DataChart from '@/components/DataChart.vue';

export default {
  props: {
    filters: {
      required: true,
    },
  },
  components: {
    DataChart,
    DataCard,
  },
  data() {
    return {
      stackNamePayment: '',
      stackNameDelivery: '',
      stackNameTopProduct: '',
      tableAgentsKpi: {
        headers: [
          this.$t('shop.product.title'),
          this.$t('shop.statistics.campaign'),
        ],
        formats: [
          false,
        ],
      },
      campaignId: null,
      labels: [],
      series: [],
      chartOptions: {},
      json_meta: [
        [{
          key: 'charset',
          value: 'utf-8',
        }],
      ],
      campaigns: [],
      statistics: {},
      dataDelivery: {
        series: [],
      },
      dataPaymentMethod: {
        series: [],
      },
      dataTopProducts: {
        series: [],
      },
      dataDeliveryOne: [],
      dataDeliveryTwo: [],
      dataPaymentMethodOne: [],
      dataPaymentMethodTwo: [],
      dataTopProductOne: [],
      dataTopProductTwo: [],
      dateFilter: null,
      finalSalesDensity: {
        series: [],
      },
      secondHeatmapSeries: {
        series: [],
      },
    };
  },
  computed: {
    ...mapState({
      percentageDelivery: state => state.sale.percentageDelivery,
      percentagePaymentMethods: state => state.sale.percentagePaymentMethods,
      topProducts: state => state.sale.topProducts,
      salesDensity: state => state.sale.salesDensity,
    }),
    ...mapGetters({
      company: 'shared/getCurrentCompany',
      isOwner: 'shared/isOwner',
      isSuper: 'shared/isSuper',
      campaign: 'setting/getCurrentCampaign',
    }),
    json_fields() {
      return {
        Step: 'name',
        Total: 'value',
      };
    },
    titleExcel() {
      return `Reporte de ${this.getCampaign(this.campaignId)}`;
    },
    campaignName() {
      return this.campaign ? this.campaign.name : '';
    },
    companyName() {
      return this.company ? this.company.name : '';
    },
  },
  watch: {
    filters(newValue) {
      const { campaignId } = newValue || {};
      this.campaignId = campaignId;
      this.loadStatistics();
      this.clearDateFilter();
      this.filterChartData('deliveryMethods', 0, newValue);
      this.filterChartData('paymentMethods', 0, newValue);
      this.filterChartData('salesDensity', 0, newValue);
      this.filterChartData('topProducts', 0, this.filters);
      this.stackNamePayment = '';
      this.stackNameDelivery = '';
    },
  },
  async mounted() {
    this.campaignId = this.filters?.campaignId;
    await this.loadStatistics();
    await this.filterChartData('deliveryMethods', 0, this.filters);
    await this.filterChartData('paymentMethods', 0, this.filters);
    await this.filterChartData('salesDensity', 0, this.filters);
    await this.filterChartData('topProducts', 0, this.filters);
  },
  methods: {
    ...mapActions({
      getProductos: 'product/getItemsOutOfStock',
      getPercentageDeliveryMethods: 'sale/getPercentageDeliveryMethods',
      getPaymentMethodsPercentage: 'sale/getPaymentMethodsPercentage',
      getSalesDensity: 'sale/salesDensity',
      getTopProducts: 'sale/topProducts',
    }),
    doubleChart(stackName) {
      return stackName !== '' && stackName !== undefined;
    },
    async clearDateFilter(name) {
      if (name === 'deliveryMethods') {
        this.dataDelivery = {
          series: [],
        };
        this.dataDeliveryOne = [];
        this.dataDeliveryTwo = [];
        this.stackNameDelivery = '';
      } else if (name === 'paymentMethods') {
        this.dataPaymentMethod = {
          series: [],
        };
        this.dataPaymentMethodOne = [];
        this.dataPaymentMethodTwo = [];
      } else if (name === 'salesDensity') {
        this.finalSalesDensity = {
          series: [],
        };
        this.secondHeatmapSeries = {
          series: [],
        };
        this.stackNamePayment = '';
      } else if (name === 'topProducts') {
        this.dataTopProducts = {
          series: [],
        };
        this.dataTopProductOne = [];
        this.dataTopProductTwo = [];
        this.stackNameTopProduct = '';
      } else {
        this.dataPaymentMethod = {
          series: [],
        };
        this.dataDelivery = {
          series: [],
        };
        this.finalSalesDensity = {
          series: [],
        };
        this.secondHeatmapSeries = {
          series: [],
        };
        this.dataTopProducts = {
          series: [],
        };
        this.dataPaymentMethodOne = [];
        this.dataPaymentMethodTwo = [];
        this.dataDeliveryOne = [];
        this.dataDeliveryTwo = [];
        this.dataTopProductsOne = [];
        this.dataTopProductsTwo = [];
        this.stackNameTopProduct = '';
        this.stackNamePayment = '';
        this.stackNameDelivery = '';
      }
      if (name) {
        await this.filterChartData(name, 0, this.filters);
      }
    },
    async chartRangeChanged(chartId, from, to) {
      if (from !== null && to !== null) {
        const startDate = this.$moment(from)
          .format('YYYY-MM-DD');
        const endDate = this.$moment(to)
          .format('YYYY-MM-DD');
        this.dateFilter = {
          startDate,
          endDate,
        };
        if (chartId === 'deliveryMethods') {
          this.stackNameDelivery = '';
          this.clearDateFilter('deliveryMethods');
          await this.chartPercentageDelivery(0, { ...this.filters, ...this.dateFilter });
        } else if (chartId === 'paymentMethods') {
          this.stackNamePayment = '';
          this.clearDateFilter('paymentMethods');
          await this.chartPaymentMethods(0, { ...this.filters, ...this.dateFilter });
        } else if (chartId === 'salesDensity') {
          this.clearDateFilter('salesDensity');
          await this.chartSalesDensity(0, { ...this.filters, ...this.dateFilter });
        } else if (chartId === 'topProducts') {
          this.stackNameTopProduct = '';
          this.clearDateFilter('topProducts');
          await this.chartTopProducts(0, { ...this.filters, ...this.dateFilter });
        }
      }
    },
    async filterChartData(from, index, filters) {
      if (from === 'deliveryMethods') {
        await this.chartPercentageDelivery(index, filters);
      } else if (from === 'paymentMethods') {
        await this.chartPaymentMethods(index, filters);
      } else if (from === 'salesDensity') {
        await this.chartSalesDensity(index, filters);
      } else if (from === 'topProducts') {
        await this.chartTopProducts(index, filters);
      }
    },
    async chartSalesDensity(index, filters) {
      await this.getSalesDensity({
        campaignId: this.campaigns,
        companyId: filters.companyId,
        endDate: filters.endDate ? filters.endDate : this.filters.endDate,
        startDate: filters.startDate ? filters.startDate : this.filters.startDate,
      });
      if (!this.salesDensity.series) return;
      if (index === 0) {
        const nameChart = filters.name ? filters.name : this.campaignName;
        const data = this.salesDensity.series.map(item => ({ stack: nameChart, ...item }));
        this.finalSalesDensity = {
          categories: this.salesDensity.categories,
          dateFormat: this.salesDensity.dateFormat,
          dateYFormat: this.salesDensity.dateYFormat,
          name: nameChart,
          series: data,
        };
      } else {
        const nameChart = filters.name ? filters.name : this.campaignName;
        const data = this.salesDensity.series.map(item => ({ stack: nameChart, ...item }));
        this.secondHeatmapSeries = {
          categories: this.salesDensity.categories,
          dateFormat: this.salesDensity.dateFormat,
          dateYFormat: this.salesDensity.dateYFormat,
          name: nameChart,
          series: data,
        };
      }
    },
    async chartTopProducts(index, filters) {
      this.dataTopProducts = {
        series: [],
      };
      await this.getTopProducts({
        campaign: filters.campaignId,
        top: 10,
        companyId: filters.companyId || this.filters.companyId,
        ...filters,
      });
      if (index === 0) {
        this.dataTopProductOne = [];
        this.topProducts.forEach((item) => {
          const nameChart = filters.name ? filters.name : this.campaignName;
          this.dataTopProductOne.push({
            name: item.product,
            y: item.total,
            children: [],
            pie: nameChart,
            stack: nameChart,
          });
        });
        if (this.topProducts.length <= 0) this.stackNameTopProduct = filters.name;
      } else {
        this.dataTopProductTwo = [];
        this.topProducts.forEach((item) => {
          const nameChart = filters.name ? filters.name : this.campaignName;
          this.dataTopProductTwo.push({
            name: item.product,
            y: item.total,
            children: [],
            pie: nameChart,
            stack: nameChart,
          });
        });
        this.stackNameTopProduct = filters.name;
      }
      this.dataTopProducts = {
        series: [...this.dataTopProductOne, ...this.dataTopProductTwo],
      };
    },
    async chartPaymentMethods(index, filters) {
      this.dataPaymentMethod = {
        series: [],
      };
      await this.getPaymentMethodsPercentage({ campaigns: this.campaignId, ...filters });
      if (index === 0) {
        this.dataPaymentMethodOne = [];
        this.percentagePaymentMethods.forEach((item) => {
          const nameChart = filters.name ? filters.name : this.campaignName;
          this.dataPaymentMethodOne.push({
            name: item.name === 'cash'
              ? this.$t('shop.sale.cash')
              : this.$t('shop.sale.card'),
            y: item.total,
            children: [],
            pie: nameChart,
            stack: nameChart,
          });
        });
        if (this.percentagePaymentMethods.length <= 0) this.stackNamePayment = filters.name;
      } else {
        this.dataPaymentMethodTwo = [];
        this.percentagePaymentMethods.forEach((item) => {
          this.dataPaymentMethodTwo.push({
            name: item.name === 'cash'
              ? this.$t('shop.sale.cash')
              : this.$t('shop.sale.card'),
            y: item.total,
            children: [],
            pie: filters?.name,
            stack: filters?.name,
          });
        });
        this.stackNamePayment = filters.name;
      }

      this.dataPaymentMethod = {
        series: [...this.dataPaymentMethodOne, ...this.dataPaymentMethodTwo],
      };
    },
    async chartPercentageDelivery(index, filters) {
      this.dataDelivery = {
        series: [],
      };
      await this.getPercentageDeliveryMethods({ campaigns: this.campaignId, ...filters });
      if (index === 0) {
        this.dataDeliveryOne = [];
        this.percentageDelivery.forEach((item) => {
          if (item.total > 0) {
            const nameChart = filters.name ? filters.name : this.campaignName;
            this.dataDeliveryOne.push({
              name: item.delivery === 'collect order'
                ? this.$t('shop.sale.go-through-the-order')
                : this.$t('shop.sale.at-home'),
              y: item.total,
              children: [],
              pie: nameChart,
              stack: nameChart,
            });
          }
        });
      } else {
        this.dataDeliveryTwo = [];
        this.percentageDelivery.forEach((item) => {
          if (item.total > 0) {
            this.dataDeliveryTwo.push({
              name: item.delivery === 'collect order'
                ? this.$t('shop.sale.go-through-the-order')
                : this.$t('shop.sale.at-home'),
              y: item.total,
              children: [],
              pie: filters?.name,
              stack: filters?.name,
            });
          }
        });

        this.stackNameDelivery = filters.name;
      }
      const totalDelivery = this.percentageDelivery
        .reduce((quantity, { total }) => total + quantity, 0);

      if (!totalDelivery) this.stackNameDelivery = filters.name;

      this.dataDelivery = {
        series: [...this.dataDeliveryOne, ...this.dataDeliveryTwo],
      };
    },
    async loadStatistics() {
      this.$store.commit('dashboard/enableLoader', 'storeDataCards');
      let campaigns = [this.campaignId];
      if (!this.campaignId) {
        campaigns = this.company.campaigns.map(({ id }) => id);
      }
      this.campaigns = campaigns;
      await this.getStatistics({ campaigns, ...this.filters });
      this.$store.commit('dashboard/disableLoader', 'storeDataCards');
    },
    async getStatistics({ campaigns, startDate, endDate }) {
      const data = await executeQuery('getStats',
        getStats,
        {
          campaign: campaigns,
          start: startDate,
          end: endDate,
        }, false);
      this.statistics = data;
    },
    getCampaign(idCampaign) {
      if (!idCampaign) {
        return 'Todas';
      }
      const campaign = this.company.campaigns.find(({ id }) => id === idCampaign);
      return campaign ? campaign.name : '';
    },
    formatNumber(total) {
      return numeral(total).format('$0,0.00');
    },
  },
};

</script>

<style scoped lang="scss">
.empty-rows {
  background-color: white;
}

.download {
  margin-bottom: 10px;
}
</style>
