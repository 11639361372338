<template>
  <div class="filter">
    <div class="filter-wrapper">
      <hcc-button
        variant="outline"
        color="primary"
        v-click-outside="vcoConfig"
        @click="toggle"
      >
        <filter-variant-icon />
        {{ $t("filter.title") }}
      </hcc-button>
      <div class="filter-content" ref="filterBox" v-if="isVisible">
        <div class="filter-content-container" v-if="companies.length > 0">
          <div v-if="showCompanies && display.includes('company')">
            <hcc-select
              :class="filterContentItem"
              track-by="id"
              option-label="name"
              :placeholder="$t('filter.company')"
              v-model="currentCompany"
              :options="mapCompanies"
              custom-class="select"
            />
          </div>
          <div v-if="display.includes('campaign')">
            <hcc-select
              :class="filterContentItem"
              track-by="id"
              option-label="name"
              :placeholder="$t('filter.campaign')"
              v-model="currentCampaign"
              :options="campaigns"
              custom-class="select"
              @select="updateCampaign"
            />
          </div>
          <div v-if="display.includes('channel')">
            <hcc-select
              :class="filterContentItem"
              track-by="id"
              option-label="name"
              :placeholder="
                fromDashboard ? $t('filter.channels-all') : $t('filter.channel')
              "
              v-model="currentChannel"
              :options="channels"
              custom-class="select"
            />
          </div>
          <div v-if="validRouteToUsers">
            <hcc-select
              :class="[filterContentItem, { 'margin-bottom': hasMarginBottom }]"
              track-by="id"
              option-label="name"
              :placeholder="$t('filter.agents-all')"
              v-model="currentAgents"
              :options="agents"
              :allow-empty="true"
              :close-on-select="false"
              :multiple="true"
              :limit-text="filterLimit"
              :limit="1"
              custom-class="select"
            />
          </div>
          <div v-if="display.includes('statusOrder')">
            <hcc-select
              :class="[filterContentItem, { 'margin-bottom': hasMarginBottom }]"
              track-by="key"
              option-label="name"
              :placeholder="$t('filter.status')"
              v-model="currentOrders"
              :options="statusOrder"
              :allow-empty="true"
              :close-on-select="false"
              :multiple="true"
              :limit-text="filterLimit"
              :limit="1"
              custom-class="select"
            />
          </div>
          <div v-if="display.includes('paymentMethodOrder')">
            <hcc-select
              :class="filterContentItem"
              :placeholder="$t('shop.sale.paymen-method')"
              v-model="currentPaymentMethod"
              :options="deliveryMethods"
              custom-class="select"
              option-label="text"
            />
          </div>
          <div v-if="display.includes('deliveryMethodOrder')">
            <hcc-select
              :class="filterContentItem"
              :placeholder="$t('shop.sale.delivery-method')"
              v-model="currentDeliveryMethod"
              :options="paymentMethods"
              custom-class="select"
              option-label="text"
            />
          </div>
          <div :class="filterContentItem" v-if="!!$slots.default">
            <slot />
          </div>
          <div v-if="display.includes('date')">
            <input-date
              class="datepicker"
              v-bind:options="{ opens: 'left' }"
              v-bind:startDate="currentStartDate"
              v-bind:endDate="currentEndDate"
              v-on:update="rangeChanged"
            />
          </div>
          <hcc-button
            size="xs"
            color="muted"
            variant="outline"
            @click="onClose"
            class="close-button"
            >{{ $t("filter.close") }}</hcc-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapMutations,
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import vClickOutside from 'v-click-outside';
import HccSelect from '@/components/shared/HccSelect/index.vue';
import HccButton from '@/components/shared/HccButton/index.vue';
import executeQuery from '@/utils/gql-api';
import usersByCampaignIdGql from '@/graphql/queries/usersByCampaignId.gql';

export default {
  components: {
    InputDate: () => import('@/components/InputDate.vue'),
    HccSelect,
    HccButton,
  },
  props: {
    display: {
      type: Array,
      required: false,
      default: () => ['company', 'campaign', 'agent', 'date', 'channel'],
    },
    channelType: {
      type: String,
      require: false,
      default: 'all',
    },
    initialStartDate: {
      type: String,
      require: false,
      default: null,
    },
    initialEndDate: {
      type: String,
      require: false,
      default: null,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      agents: [],
      currentCompany: null,
      currentCampaign: null,
      currentChannel: null,
      currentAgents: [],
      currentOrders: [],
      currentPaymentMethod: null,
      currentDeliveryMethod: null,
      currentStartDate: null,
      currentEndDate: null,
      vcoConfig: {
        handler: this.onClose,
        middleware: this.middleware,
        events: ['click'],
      },
      isVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      companies: 'shared/getFilterCompanies',
      commonCompany: 'shared/getCurrentCompany',
      userRol: 'shared/getUserRole',
      company: 'shared/getCurrentCompany',
      isLogged: 'shared/isLogged',
    }),
    ...mapState({
      windowWidth: state => state.windowWidth,
    }),
    mapCompanies() {
      return this.companies ? this.companies
        .map(({ id, name, maxUsers }) => ({ id, name, maxUsers })) : [];
    },
    showCompanies() {
      return this.companies.length > 1;
    },
    campaigns() {
      const company = this.getCompanyById(this.currentCompanyId);

      const campaigns = company !== undefined
        ? company.campaigns.map(({ id, name }) => ({ id, name })) : [];

      campaigns.unshift({ name: this.$t('filter.all') });
      return campaigns;
    },
    deliveryMethods() {
      return [
        {
          key: '',
          text: this.$t('filter.all'),
        },
        {
          key: 'cash',
          text: this.$t('shop.sale.cash'),
        },
        {
          key: 'card',
          text: this.$t('shop.sale.card'),
        },
      ];
    },
    paymentMethods() {
      return [
        {
          key: '',
          text: this.$t('filter.all'),
        },
        {
          key: '0',
          text: this.$t('shop.sale.go-through-the-order'),
        },
        {
          key: '1',
          text: this.$t('shop.sale.at-home'),
        },
      ];
    },
    statusOrder() {
      return [
        {
          key: 1,
          name: this.$t('filter.statusOrders.pending'),
        },
        {
          key: 2,
          name: this.$t('filter.statusOrders.ready'),
        },
        {
          key: 3,
          name: this.$t('filter.statusOrders.in-delivery'),
        },
        {
          key: 4,
          name: this.$t('filter.statusOrders.delivered'),
        },
        {
          key: 5,
          name: this.$t('filter.statusOrders.cancelled'),
        },
      ];
    },
    channels() {
      const company = this.getCompanyById(this.currentCompanyId);

      if (company !== undefined) {
        const campaign = company.campaigns.find(({ id }) => id === this.currentCampaignId);

        if (campaign && campaign.channels) {
          let channels = [];

          if (this.channelType === 'all') {
            channels = campaign.channels
              .map(({ id, name }) => ({ id, name }));

            if (this.fromDashboard) {
              channels.push({ name: this.$t('filter.all') });
            }

            return channels;
          }
          channels = campaign.channels
            .filter(({ channelType, official }) => channelType.key === this.channelType && official)
            .map(({ id, name }) => ({ id, name }));


          if (this.fromDashboard) {
            channels.push({ name: this.$t('filter.all') });
          }

          return channels;
        }
      }
      return [];
    },
    currentCompanyId() {
      return this.currentCompany ? this.currentCompany.id : null;
    },
    currentCampaignId() {
      return this.currentCampaign ? this.currentCampaign.id : null;
    },
    currentChannelId() {
      return this.currentChannel ? this.currentChannel.id : null;
    },
    isCampaign() {
      return this.$route.meta.site.name === this.$t('app.campaigns');
    },
    hasMargin() {
      return !this.display.includes('date');
    },
    filterContentItem() {
      return [{ 'filter-content__item': this.hasMargin }];
    },
    hasMarginBottom() {
      return this.currentAgents.length > 1 && this.currentAgents[0].name.length >= 10;
    },
    shortenName() {
      return (this.windowWidth <= 1536 && this.windowWidth > 1280)
        && this.currentCompany.name.length >= 20;
    },
    validRouteToUsers() {
      return this.display.includes('agent');
    },
    fromDashboard() {
      return this.$route.name === 'dashboard';
    },
  },
  watch: {
    commonCompany(newVal) {
      this.currentCompany = newVal;
    },
    mapCompanies(newValue) {
      if (newValue.length > 0) {
        this.autoSelectCompany(newValue);
      }
    },
    campaigns(newValue) {
      if (newValue.length > 0) {
        if (localStorage.currentCampaign
          && this.campaignIsSelectable(newValue, JSON.parse(localStorage.currentCampaign))) {
          this.currentCampaign = JSON.parse(localStorage.currentCampaign);
        } else {
          const [firstCampaign] = newValue;
          this.currentCampaign = firstCampaign;
        }
      } else {
        this.currentCampaign = null;
      }
    },
    currentCompany(newValue) {
      if (newValue) {
        this.resetValues();
        localStorage.currentCompany = JSON.stringify(newValue);
        this.setCurrentCompanyID(newValue.id);
        this.$emit('change', this.getFilters());
      }
    },
    currentCampaign(newValue) {
      if (newValue) {
        this.resetValues();
        this.getAgentsByCampaignId(newValue);
        localStorage.currentCampaign = JSON.stringify(newValue);
        this.setCurrentCampaign(newValue);
        this.$emit('change', this.getFilters());
      }
    },
    currentChannel(newValue) {
      if (newValue) {
        localStorage.currentChannel = JSON.stringify(newValue);
        this.setCurrentChannel(newValue);
        this.$emit('change', this.getFilters());
      }
    },
    currentAgents(newValue) {
      if (newValue) {
        this.$emit('change', this.getFilters());
      }
    },
    currentOrders(newValue) {
      if (newValue) {
        this.$emit('changeOrder', this.getFiltersOrders());
      }
    },
    currentPaymentMethod(newValue) {
      if (newValue) {
        this.$emit('changeOrder', this.getFiltersOrders());
      }
    },
    currentDeliveryMethod(newValue) {
      if (newValue) {
        this.$emit('changeOrder', this.getFiltersOrders());
      }
    },
  },
  async created() {
    await this.fetchCompanies();
  },
  activated() {
    this.autoSelectCompany(this.mapCompanies);
  },
  methods: {
    ...mapActions({ fetchCompanies: 'shared/fetchCompanies' }),
    ...mapMutations({
      setCurrentCompanyID: 'shared/setCurrentCompanyID',
      setCurrentCampaign: 'setting/setCurrentCampaign',
      setCurrentChannel: 'setting/setCurrentChannel',
    }),
    resetValues() {
      localStorage.currentChannel = null;
      this.currentChannel = null;
      this.currentAgents = [];
      this.setCurrentChannel(null);
    },
    toggle() {
      this.isVisible = !this.isVisible;
    },
    onClose() {
      this.isVisible = false;
    },
    middleware(event) {
      return !!this.$refs.filterBox && !this.$refs.filterBox.contains(event.target);
    },
    rangeChanged(startDate, endDate) {
      this.currentStartDate = startDate;
      this.currentEndDate = endDate;
      this.$emit('change', this.getFilters());
    },
    getFiltersOrders() {
      const filters = {
        paymentMethod: this.currentPaymentMethod,
        deliveryMethod: this.currentDeliveryMethod,
        orderStatus: this.currentOrders,
      };
      return filters;
    },
    getFilters() {
      let startDate = this.currentStartDate;
      let endDate = this.currentEndDate;

      if (startDate != null) {
        startDate = this.$moment(startDate).format();
        endDate = this.$moment(endDate).format();
      }

      const filters = {
        startDate,
        endDate,
        companyId: this.currentCompanyId,
        campaignId: this.currentCampaignId,
        channelId: this.currentChannelId,
      };

      if (this.currentAgents.length > 0) {
        filters.agentId = this.currentAgents.map(agent => agent.id);
      }
      return filters;
    },
    getCompanyById(id) {
      return this.companies ? this.companies.find(company => id === company.id) : undefined;
    },
    async getAgentsByCampaignId(campaign) {
      if (campaign && campaign.id && this.validRouteToUsers && this.isLogged) {
        const agents = await executeQuery(
          'usersByCampaignId', usersByCampaignIdGql, {
            id: campaign.id,
            company: this.company.id,
          },
          false,
        );
        this.agents = agents;
      } else {
        this.agents = [];
      }
    },
    campaignIsSelectable(campaignsList, campaign) {
      if (!campaign) {
        return false;
      }

      return campaignsList.find(({ id }) => id === campaign.id) !== undefined;
    },
    channelIsSelectable(channelsList, channel) {
      if (!channel) {
        return false;
      }

      return channelsList.find(({ id }) => id === channel.id) !== undefined;
    },
    autoSelectCompany(companiesList) {
      let changeCompany = true;

      if (localStorage.currentCompany != null) {
        const currentCompany = JSON.parse(localStorage.currentCompany);
        if (companiesList.some(({ id }) => id === currentCompany.id)) {
          changeCompany = false;
          this.currentCompany = currentCompany;
        }
      }

      if (changeCompany) {
        const [firstCompany] = companiesList;
        this.currentCompany = firstCompany;
      }
    },
    filterLimit(count) {
      return this.$t('multiselect.limit-suffix', { count });
    },
    updateCampaign(campaign) {
      if (campaign.id) {
        this.setCurrentCampaign(campaign);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_button-filter.scss";
</style>
