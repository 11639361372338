<template>
  <div class="filters">
    <hcc-badge fill customClass="badge__company" size="lg" v-if="showCompany">
      <template #badgeText>
        <div>{{ `${$t("filter.company")}:&nbsp;` }}</div>
        <div class="info-company">{{ companyName | truncate(30) }}</div>
      </template>
    </hcc-badge>

    <hcc-badge fill customClass="badge__campaign" size="lg" v-if="showCampaign">
      <template #badgeText>
        <span>{{ `${$t("filter.campaign")}:&nbsp;` }}</span>
        <span class="info-campaign" v-if="hasCampaigns">
          {{ campaignName | truncate(30) }}</span
        >
        <span class="info-empty" v-else>{{ $t("filter.no-campaigns") }}</span>
      </template>
    </hcc-badge>

    <hcc-badge fill customClass="badge__channel" size="lg" v-if="showChannel">
      <template #badgeText>
        <span>{{
          allChannels
            ? `${$t("filter.channels")}:&nbsp;`
            : `${$t("filter.channel")}:&nbsp;`
        }}</span>
        &nbsp;
        <span class="info-channel">
          {{ channelName | truncate(25) }}</span
        >
      </template>
    </hcc-badge>
    <hcc-badge fill customClass="badge__agent" size="lg" v-if="showAgent">
      <template #badgeText>
        <span>{{ `${$t("filter.agents")}:&nbsp;` }}</span>
        &nbsp;
        <span class="info-agent" v-if="allAgents"> {{ $t("filter.all") }}</span>
        <span
          class="info-agent"
          v-else
          v-for="(agent, index) in currentAgents"
          :key="index"
        >
          {{ index === currentAgents.length - 1 ? agent : `${agent},&nbsp;` }}
        </span>
      </template>
    </hcc-badge>

    <hcc-badge fill customClass="badge__status" size="lg" v-if="showStatus">
      <template #badgeText>
        <span>{{ `${$t("filter.status")}:&nbsp;` }}</span>
        &nbsp;
        <span class="info-status"> {{ currentStatus }}</span>
      </template>
    </hcc-badge>

    <hcc-badge fill customClass="badge__date" size="lg" v-if="showDate">
      <template #badgeText>
        <span>{{ `${$t("filter.date")}:&nbsp;` }}</span>
        &nbsp;
        <span class="info-date"> {{ dateFormatter(date) }}</span>
      </template>
    </hcc-badge>

    <slot name="add-badge" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HccBadge from '@/components/shared/HccBadge/index.vue';
import executeQuery from '@/utils/gql-api';
import usersByCampaignIdGql from '@/graphql/queries/usersByCampaignId.gql';

export default {
  components: {
    HccBadge,
  },
  props: {
    display: {
      type: Array,
      required: false,
      default: () => ['company', 'campaign', 'agent', 'channel', 'date'],
    },
    channel: {
      type: String,
      default: '',
    },
    channelType: {
      type: String,
      require: false,
      default: 'all',
    },
    agents: {
      type: Array,
      required: false,
      default: () => [],
    },
    date: {
      type: Object,
    },
    status: {
      type: String,
    },
  },
  data() {
    return {
      agentsByCampaign: [],
      hasCampaigns: true,
    };
  },
  watch: {
    agents() {
      if (this.agents.length > 0) {
        this.getAgentsByCampaignId(this.campaign.id);
      }
    },
    company(newValue) {
      this.hasCampaigns = newValue && newValue.campaigns?.length > 0;
    },

  },
  computed: {
    ...mapGetters({
      companies: 'shared/getFilterCompanies',
      company: 'shared/getCurrentCompany',
      campaign: 'setting/getCurrentCampaign',
    }),
    showCompany() {
      return this.display.includes('company');
    },
    showCampaign() {
      return this.display.includes('campaign');
    },
    showAgent() {
      return this.display.includes('agent');
    },
    showChannel() {
      return this.display.includes('channel');
    },
    showDate() {
      return this.display.includes('date');
    },
    showStatus() {
      return this.display.includes('status');
    },
    channels() {
      const company = this.getCompanyById(this.company?.id);

      if (company !== undefined) {
        const campaign = company.campaigns.find(({ id }) => id === this.campaign.id);


        if (campaign && campaign.channels) {
          if (this.channelType === 'all') {
            return campaign.channels
              .map(({ id, name }) => ({ id, name }));
          }
          return campaign.channels
            .filter(({ channelType, official }) => channelType.key === this.channelType && official)
            .map(({ id, name }) => ({ id, name }));
        }
      }
      return [];
    },
    allChannels() {
      return this.channel === this.$t('filter.all');
    },
    currentChannel() {
      if (!this.channel) {
        return { name: '---' };
      }
      return this.channel === this.$t('filter.all')
        ? { name: this.channel }
        : this.channels.find(channel => channel.id === this.channel);
    },
    channelName() {
      return this.currentChannel ? this.currentChannel.name : '';
    },
    campaignName() {
      return this.campaign ? this.campaign.name : '';
    },
    companyName() {
      return this.company ? this.company.name : '';
    },
    allAgents() {
      return this.agents.length === 0;
    },
    currentAgents() {
      const displayAgents = [];

      this.agentsByCampaign.forEach((agent) => {
        if (this.agents.includes(agent.id)) {
          displayAgents.push(agent.name);
        }
      });

      return displayAgents;
    },
    currentStatus() {
      return !this.status ? this.$t('filter.all') : this.status;
    },
  },
  methods: {
    getCompanyById(id) {
      return this.companies ? this.companies.find(company => id === company.id) : undefined;
    },
    async getAgentsByCampaignId(campaignId) {
      const agents = await executeQuery(
        'usersByCampaignId', usersByCampaignIdGql, {
          id: campaignId,
          company: this.company.id,
        },
        false,
      );
      this.agentsByCampaign = agents;
    },
    isSameDate(start, end) {
      return this.$moment(start).isSame(end, 'date');
    },
    dateFormatter(date) {
      const { start, end } = date;
      let formattedDate = start && end ? `${this.$moment(start).format('DD MMM YYYY')} - ${this.$moment(end).format('DD MMM YYYY')}` : this.$t('range-picker.all-time');

      const today = this.$moment().format('YYYY-MM-DD');
      const yesterday = this.$moment().subtract(1, 'days').format('YYYY-MM-DD');
      const lastWeek = this.$moment().subtract(7, 'days').format('YYYY-MM-DD');
      const last28Months = this.$moment().subtract(28, 'days').format('YYYY-MM-DD');
      const lastMonthStart = this.$moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      const lastMonthEnd = this.$moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');


      if (this.isSameDate(start, today) && this.isSameDate(end, today)) {
        formattedDate = this.$t('range-picker.today');
      }
      if (this.isSameDate(start, yesterday) && this.isSameDate(end, yesterday)) {
        formattedDate = this.$t('range-picker.yesterday');
      }
      if (this.isSameDate(start, lastWeek) && this.isSameDate(end, yesterday)) {
        formattedDate = this.$t('range-picker.last-7-days');
      }
      if (this.isSameDate(start, last28Months) && this.isSameDate(end, yesterday)) {
        formattedDate = this.$t('range-picker.last-28-days');
      }
      if (this.isSameDate(start, lastMonthStart) && this.isSameDate(end, lastMonthEnd)) {
        formattedDate = this.$t('range-picker.last-month');
      }


      return formattedDate;
    },

  },

};
</script>

<style lang="scss" scoped>
@import "~styles/components/filters.scss";
</style>
