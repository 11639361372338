import Vue from 'vue';

import AccountIcon from 'vue-material-design-icons/Account.vue';
import AccountEditIcon from 'vue-material-design-icons/AccountEdit.vue';
import AccountMultipleIcon from 'vue-material-design-icons/AccountMultiple.vue';
import AccountSupervisorCircleIcon from 'vue-material-design-icons/AccountSupervisorCircle.vue';
import AutorenewIcon from 'vue-material-design-icons/Autorenew.vue';
import AlertCircle from 'vue-material-design-icons/AlertCircle.vue';
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft.vue';
import BellOutlineIcon from 'vue-material-design-icons/BellOutline.vue';
import BriefcaseAccountIcon from 'vue-material-design-icons/BriefcaseAccount.vue';
import BullhornVue from 'vue-material-design-icons/Bullhorn.vue';
import Bike from 'vue-material-design-icons/Bike.vue';
import CalendarTodayIcon from 'vue-material-design-icons/CalendarToday.vue';
import CancelIcon from 'vue-material-design-icons/Cancel.vue';
import CartIcon from 'vue-material-design-icons/Cart.vue';
import ChartLineIcon from 'vue-material-design-icons/ChartLine.vue';
import CheckAllIcon from 'vue-material-design-icons/CheckAll.vue';
import CheckIcon from 'vue-material-design-icons/Check.vue';
import CheckCircleIcon from 'vue-material-design-icons/CheckCircle.vue';
import CheckCircleOutlineIcon from 'vue-material-design-icons/CheckCircleOutline.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
import CheckboxBlankCircleOutlineIcon from 'vue-material-design-icons/CheckboxBlankCircleOutline.vue';
import ClockOutlineIcon from 'vue-material-design-icons/ClockOutline.vue';
import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import CropSquareIcon from 'vue-material-design-icons/CropSquare.vue';
import CompareIcon from 'vue-material-design-icons/Compare.vue';
import ContentCopyIcon from 'vue-material-design-icons/ContentCopy.vue';
import ClipboardListOutline from 'vue-material-design-icons/ClipboardListOutline.vue';
import cashMultiple from 'vue-material-design-icons/CashMultiple.vue';
import creditCard from 'vue-material-design-icons/CreditCard.vue';
import DeleteIcon from 'vue-material-design-icons/Delete.vue';
import DotsHorizontalCircleIcon from 'vue-material-design-icons/DotsHorizontalCircle.vue';
import DotsVerticalIcon from 'vue-material-design-icons/DotsVertical.vue';
import DownloadIcon from 'vue-material-design-icons/Download.vue';
import EyeIcon from 'vue-material-design-icons/Eye.vue';
import EyeOffIcon from 'vue-material-design-icons/EyeOff.vue';
import ExclamationThickIcon from 'vue-material-design-icons/ExclamationThick.vue';
import FileDocumentIcon from 'vue-material-design-icons/FileDocument.vue';
import FileIcon from 'vue-material-design-icons/File.vue';
import FilePdfIcon from 'vue-material-design-icons/FilePdf.vue';
import FilterVariantIcon from 'vue-material-design-icons/FilterVariant.vue';
import FormatListBulletedSquareIcon from 'vue-material-design-icons/FormatListBulletedSquare.vue';
import ForumIcon from 'vue-material-design-icons/Forum.vue';
import FormatListBulleted from 'vue-material-design-icons/FormatListBulleted.vue';
import FileExcelIcon from 'vue-material-design-icons/FileExcel.vue';
import FileExcelOutlineIcon from 'vue-material-design-icons/FileExcelOutline.vue';
import HelpCircleIcon from 'vue-material-design-icons/HelpCircle.vue';
import HistoryIcon from 'vue-material-design-icons/History.vue';
import HomeIcon from 'vue-material-design-icons/Home.vue';
import HeartIcon from 'vue-material-design-icons/Heart.vue';
import HandshakeOutline from 'vue-material-design-icons/Handshake.vue';
import PotMixIcon from 'vue-material-design-icons/PotMix.vue';
import ImageIcon from 'vue-material-design-icons/Image.vue';
import ImageSearchOutlineIcon from 'vue-material-design-icons/ImageSearchOutline.vue';
import InformationVariantIcon from 'vue-material-design-icons/InformationVariant.vue';
import LinkIcon from 'vue-material-design-icons/Link.vue';
import LabelIcon from 'vue-material-design-icons/Label.vue';
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import MapMarkerIcon from 'vue-material-design-icons/MapMarker.vue';
import MessageIcon from 'vue-material-design-icons/Message.vue';
import MessagePlusIcon from 'vue-material-design-icons/MessagePlus.vue';
import MessageTextIcon from 'vue-material-design-icons/MessageText.vue';
import Messenger from 'vue-material-design-icons/FacebookMessenger.vue';
import Motorbike from 'vue-material-design-icons/Motorbike.vue';
import MessageBulletedOffIcon from 'vue-material-design-icons/MessageBulletedOff.vue';
import OpenInNewIcon from 'vue-material-design-icons/OpenInNew.vue';
import PaletteSwatchIcon from 'vue-material-design-icons/PaletteSwatch.vue';
import PauseIcon from 'vue-material-design-icons/Pause.vue';
import PencilIcon from 'vue-material-design-icons/Pencil.vue';
import PencilOffIcon from 'vue-material-design-icons/PencilOff.vue';
import PlayIcon from 'vue-material-design-icons/Play.vue';
import PlusIcon from 'vue-material-design-icons/Plus.vue';
import PlusCircleIcon from 'vue-material-design-icons/PlusCircle.vue';
import ProgressClockIcon from 'vue-material-design-icons/ProgressClock.vue';
import ReloadIcon from 'vue-material-design-icons/Reload.vue';
import RepeatIcon from 'vue-material-design-icons/Repeat.vue';
import ReorderIcon from 'vue-material-design-icons/ReorderHorizontal.vue';
import SendIcon from 'vue-material-design-icons/Send.vue';
import SettingsIcon from 'vue-material-design-icons/Settings.vue';
import ShuffleVariantIcon from 'vue-material-design-icons/ShuffleVariant.vue';
import Telegram from 'vue-material-design-icons/Telegram.vue';
import TextIcon from 'vue-material-design-icons/Text.vue';
import TextSubjectIcon from 'vue-material-design-icons/TextSubject.vue';
import ToolsIcon from 'vue-material-design-icons/Tools.vue';
import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
import TranslateIcon from 'vue-material-design-icons/Translate.vue';
import VideoIcon from 'vue-material-design-icons/Video.vue';
import ViewDashboardIcon from 'vue-material-design-icons/ViewDashboard.vue';
import ViewHeadlineIcon from 'vue-material-design-icons/ViewHeadline.vue';
import VoiceIcon from 'vue-material-design-icons/Voice.vue';
import WrenchIcon from 'vue-material-design-icons/Wrench.vue';
import SendCircleOutlineIcon from 'vue-material-design-icons/SendCircleOutline.vue';
import SmsIcon from 'vue-material-design-icons/ChatProcessing.vue';
import WarningIcon from 'vue-material-design-icons/Alert.vue';
import Webchat from 'vue-material-design-icons/Web.vue';
import Whatsapp from 'vue-material-design-icons/Whatsapp.vue';
import Instagram from 'vue-material-design-icons/Instagram.vue';
import WindowCloseIcon from 'vue-material-design-icons/WindowClose.vue';
import WindowMinimizeIcon from 'vue-material-design-icons/WindowMinimize.vue';
import DatabaseIcon from 'vue-material-design-icons/Database.vue';
import AccountGroupIcon from 'vue-material-design-icons/AccountGroup.vue';
import AlertOutlineIcon from 'vue-material-design-icons/AlertOutline.vue';
import TimerSandIcon from 'vue-material-design-icons/TimerSand.vue';
import CircleIcon from 'vue-material-design-icons/Circle.vue';
import AccountOffOutlineIcon from 'vue-material-design-icons/AccountOffOutline.vue';
import RotateRightIcon from 'vue-material-design-icons/RotateRight.vue';
import StoreIcon from 'vue-material-design-icons/Store.vue';
import Basket from 'vue-material-design-icons/Basket.vue';
import ChartBoxOutline from 'vue-material-design-icons/ChartBellCurve.vue';
import Loading from 'vue-material-design-icons/Loading.vue';
import QrCodeScanIcon from 'vue-material-design-icons/QrcodeScan.vue';
import Walk from 'vue-material-design-icons/Walk.vue';
import TruckFastIcon from 'vue-material-design-icons/TruckFast.vue';
import BlockHelperIcon from 'vue-material-design-icons/BlockHelper.vue';
import PuzzleIcon from 'vue-material-design-icons/Puzzle.vue';
import MoreIcon from './MoreVert.vue';

Vue.component('account-icon', AccountIcon);
Vue.component('account-edit-icon', AccountEditIcon);
Vue.component('account-multiple-icon', AccountMultipleIcon);
Vue.component('account-supervisor-circle-icon', AccountSupervisorCircleIcon);
Vue.component('account-off-outline-icon', AccountOffOutlineIcon);
Vue.component('autorenew-icon', AutorenewIcon);
Vue.component('circle-icon', CircleIcon);
Vue.component('account-group', AccountGroupIcon);
Vue.component('account-icon', AccountIcon);
Vue.component('alert-circle', AlertCircle);
Vue.component('alert-outline', AlertOutlineIcon);
Vue.component('arrow-left-icon', ArrowLeftIcon);
Vue.component('bell-outline', BellOutlineIcon);
Vue.component('briefcase-icon', BriefcaseAccountIcon);
Vue.component('bullhorn-icon', BullhornVue);
Vue.component('bike', Bike);
Vue.component('calendar-today-icon', CalendarTodayIcon);
Vue.component('cancel-icon', CancelIcon);
Vue.component('cart-icon', CartIcon);
Vue.component('chart-line-icon', ChartLineIcon);
Vue.component('check-icon', CheckIcon);
Vue.component('check-all-icon', CheckAllIcon);
Vue.component('check-circle', CheckCircleIcon);
Vue.component('check-circle-outline', CheckCircleOutlineIcon);
Vue.component('check-load-circle', CheckboxBlankCircleOutlineIcon);
Vue.component('chevron-left-icon', ChevronLeftIcon);
Vue.component('chevron-right-icon', ChevronRightIcon);
Vue.component('chevron-down-icon', ChevronDownIcon);
Vue.component('clock-outline-icon', ClockOutlineIcon);
Vue.component('close-circle-icon', CloseCircleIcon);
Vue.component('close-icon', CloseIcon);
Vue.component('compare-icon', CompareIcon);
Vue.component('copy-icon', ContentCopyIcon);
Vue.component('crop-square-icon', CropSquareIcon);
Vue.component('clipboard-list-outline', ClipboardListOutline);
Vue.component('credit-card', creditCard);
Vue.component('cash-multiple', cashMultiple);
Vue.component('database-icon', DatabaseIcon);
Vue.component('delete-icon', DeleteIcon);
Vue.component('dots-horizontal-circle-icon', DotsHorizontalCircleIcon);
Vue.component('dots-vertical-icon', DotsVerticalIcon);
Vue.component('download-icon', DownloadIcon);
Vue.component('exclamation-thick-icon', ExclamationThickIcon);
Vue.component('eye-icon', EyeIcon);
Vue.component('eye-off-icon', EyeOffIcon);
Vue.component('file-icon', FileIcon);
Vue.component('file-document-icon', FileDocumentIcon);
Vue.component('file-pdf-icon', FilePdfIcon);
Vue.component('filter-variant-icon', FilterVariantIcon);
Vue.component('file-excel', FileExcelIcon);
Vue.component('file-excel-outline', FileExcelOutlineIcon);
Vue.component('forum-icon', ForumIcon);
Vue.component('history-icon', HistoryIcon);
Vue.component('heart', HeartIcon);
Vue.component('handshake-outline', HandshakeOutline);
Vue.component('pot-mix', PotMixIcon);
Vue.component('format-list-bulleted-icon', FormatListBulleted);
Vue.component('help-circle-icon', HelpCircleIcon);
Vue.component('format-list-bulleted-square-icon', FormatListBulletedSquareIcon);
Vue.component('history-icon', HistoryIcon);
Vue.component('home-icon', HomeIcon);
Vue.component('image-icon', ImageIcon);
Vue.component('image-search-outline-icon', ImageSearchOutlineIcon);
Vue.component('information-variant-icon', InformationVariantIcon);
Vue.component('link-icon', LinkIcon);
Vue.component('label-icon', LabelIcon);
Vue.component('magnify-icon', MagnifyIcon);
Vue.component('map-marker-icon', MapMarkerIcon);
Vue.component('message-icon', MessageIcon);
Vue.component('message-plus-icon', MessagePlusIcon);
Vue.component('message-text-icon', MessageTextIcon);
Vue.component('messenger', Messenger);
Vue.component('messenger-cancel', MessageBulletedOffIcon);
Vue.component('more-icon', MoreIcon);
Vue.component('open-in-new-icon', OpenInNewIcon);
Vue.component('palette-swatch-icon', PaletteSwatchIcon);
Vue.component('pause-icon', PauseIcon);
Vue.component('pencil-icon', PencilIcon);
Vue.component('pencil-off-icon', PencilOffIcon);
Vue.component('play-icon', PlayIcon);
Vue.component('plus-circle-icon', PlusCircleIcon);
Vue.component('plus-icon', PlusIcon);
Vue.component('progress-click-icon', ProgressClockIcon);
Vue.component('reload-icon', ReloadIcon);
Vue.component('repeat-icon', RepeatIcon);
Vue.component('reorder-icon', ReorderIcon);
Vue.component('rotate-right-icon', RotateRightIcon);
Vue.component('send-circle-outline', SendCircleOutlineIcon);
Vue.component('send-icon', SendIcon);
Vue.component('settings-icon', SettingsIcon);
Vue.component('sms', SmsIcon);
Vue.component('shuffle-icon', ShuffleVariantIcon);
Vue.component('timer-sand-icon', TimerSandIcon);
Vue.component('telegram', Telegram);
Vue.component('text-icon', TextIcon);
Vue.component('text-subject-icon', TextSubjectIcon);
Vue.component('tools-icon', ToolsIcon);
Vue.component('trash-can-icon', TrashCanIcon);
Vue.component('translate-icon', TranslateIcon);
Vue.component('video-icon', VideoIcon);
Vue.component('view-dashboard-icon', ViewDashboardIcon);
Vue.component('view-headline-icon', ViewHeadlineIcon);
Vue.component('voice-icon', VoiceIcon);
Vue.component('warning-icon', WarningIcon);
Vue.component('wrench-icon', WrenchIcon);
Vue.component('webchat', Webchat);
Vue.component('whatsapp', Whatsapp);
Vue.component('instagram', Instagram);
Vue.component('window-close-icon', WindowCloseIcon);
Vue.component('store-icon', StoreIcon);
Vue.component('basket', Basket);
Vue.component('chart-box-outline', ChartBoxOutline);
Vue.component('loading-icon', Loading);
Vue.component('qrcode-scan-icon', QrCodeScanIcon);
Vue.component('window-minimize-icon', WindowMinimizeIcon);
Vue.component('walk', Walk);
Vue.component('truckfast-icon', TruckFastIcon);
Vue.component('block-helper-icon', BlockHelperIcon);
Vue.component('puzzle-icon', PuzzleIcon);
Vue.component('motorbike', Motorbike);
