<template>
  <div class="loader-container">
    <overlay-loader :loading="loading" />
    <ProductForm
      :idProduct="idProduct"
      :product="product"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';

export default {
  components: {
    OverlayLoader,
    ProductForm: () => import('@/components/shop/ProductOptionsForm.vue'),
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({ product: state => state.product.item }),
    idProduct() {
      return this.$route.params.idProduct;
    },
  },
  methods: {
    ...mapActions({
      getProductById: 'product/getProductById',
      getCategoriesLastChildren: 'categorie/getCategoriesLastChildren',
    }),
  },
  async mounted() {
    this.loading = true;
    await Promise.all([
      this.getProductById(this.idProduct),
      this.getCategoriesLastChildren(this.$route.params.id),
    ]);
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/_products-options.scss";
</style>
