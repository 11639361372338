import { storage } from '@/utils/firebase';

const chartColors = [
  '#F56565',
  '#ED8936',
  '#ECC94B',
  '#4299E1',
  '#48BB78',
  '#38B2AC',
  '#667EEA',
  '#9F7AEA',
  '#FC8181',
  '#F6AD55',
  '#F6E05E',
  '#68D391',
  '#4FD1C5',
  '#63B3ED',
  '#7F9CF5',
  '#B794F4',
  '#FEB2B2',
  '#FBD38D',
  '#FAF089',
  '#9AE6B4',
  '#81E6D9',
  '#90CDF4',
  '#A3BFFA',
  '#D6BCFA',
  '#FED7D7',
  '#FEEBC8',
  '#FEFCBF',
  '#C6F6D5',
  '#B2F5EA',
  '#BEE3F8',
  '#C3DAFE',
  '#E9D8FD',
  '#00A5e3',
  '#00B0BA',
  '#0065A2',
  '#00CDAC',
  '#FF96C5',
  '#FF5768',
  '#FF828B',
  '#FF60A8',
  '#FF5C77',
  '#E77577',
  '#FC6238',
  '#FFBF65',
  '#C05780',

];

function getDisplayableFormat(format) {
  switch (format) {
    case 'YYYY-MM-DD':
      return 'DD MMM YYYY';
    case 'YYYY-MM':
      return 'MMM YYYY';
    case 'YYYY':
      return 'YYYY';
    case 'HH':
      return 'hh:mm A';
    case 'E':
      return 'ddd';
    case 'MM':
      return 'MMM';
    default:
      return format;
  }
}

function clearString(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function compareTo(str1, str2) {
  const comp1 = clearString(str1).toLowerCase();
  const comp2 = clearString(str2).toLowerCase();
  return comp1 === comp2;
}

function getDataNotification(item) {
  const data = Object.assign({}, item);
  let msg = '';
  let titleNotification = '';
  if (item.type === 'change-status-order') {
    msg = item.message;
    titleNotification = item.title;
    data.message = msg;
  } else if (item.action.type === 'show-detail' && item.type !== 'template-status') {
    msg = `Numeros enviados: ${item.message}`;
    titleNotification = 'template';
    data.message = msg;
  } else if (item.type === 'template-status') {
    msg = item.message;
    titleNotification = 'template-status';
    data.message = msg;
  } else {
    titleNotification = 'report';
  }

  data.title = titleNotification;
  return data;
}

function validPassword(password) {
  const isValid = new RegExp('^(?=.*?[0-9])(?=.*?[A-Z])(?=.*[a-z]).{8,}$');
  return isValid.test(password);
}

function getImageUrlInformation(imageUrl) {
  return fetch(imageUrl)
    .then(response => response.blob())
    .then((blob) => {
      const data = blob;
      switch (data.type) {
        case 'image/png':
          data.ext = 'png';
          break;
        case 'image/jpeg':
          data.ext = 'jpeg';
          break;
        case 'image/webp':
          data.ext = 'webp';
          break;
        default:
          break;
      }
      return data;
    });
}

function createNameCanonical(name) {
  let nameCanonical = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  nameCanonical = name.toLowerCase().replace(/[^a-z0-9]/gi, '');
  return `@${nameCanonical}`;
}

function getDataImage(images = [], name) {
  return Promise.all(images.map(async (urlImage, index) => {
    const dataImg = await getImageUrlInformation(urlImage);
    return {
      name: `${name}_${index}.${dataImg.ext}`,
      url: urlImage,
      size: dataImg.size,
      ext: dataImg.ext,
      type: 'image/*',
    };
  }));
}

async function getDataImageStore(dataProduct) {
  const data = dataProduct;
  data.image = await getImageUrlInformation(data.imageURL);
  return {
    name: `${data.nombre}.${data.image.ext}`,
    url: data.imageURL,
    size: data.image.size,
    ext: data.image.ext,
    type: 'image/*',
  };
}

function hours() {
  const time = [];
  for (let i = 1; i < 24; i += 1) {
    time.push({ id: `${i}`, name: `${i}:00` });
  }
  time.push({ id: '0', name: '24:00' });
  return time;
}

async function saveImage(file, campaignId, name = null) {
  const nameImage = file.name.replace(/\s+/g, '').replace(/[()-]/g, '');
  const randomId = Math.floor(Math.random() * 999999) + 1;
  const storageRef = storage.ref();
  let spaceRef = storageRef.child(`${campaignId}/images/${randomId}/${nameImage}`);
  if (name) spaceRef = storageRef.child(`${campaignId}/images/${randomId}/${name}`);
  const snapshot = await spaceRef.put(file);
  const imageURL = await snapshot.ref.getDownloadURL();
  return imageURL;
}

function validateUrlMarketplace(url) {
  return url.includes(process.env.VUE_APP_DOMAIN_MANAGER);
}

export {
  hours,
  getDisplayableFormat,
  chartColors,
  getDataNotification,
  validPassword,
  getImageUrlInformation,
  createNameCanonical,
  getDataImage,
  clearString,
  saveImage,
  validateUrlMarketplace,
  compareTo,
  getDataImageStore,
};
