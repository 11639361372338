<template>
  <div class="loader-container">
    <overlay-loader :loading="loading" />
    <breadcrumb
      :items="items"
      :handleChange="getCategorys"
      :handleChangeChildren="showChildren"
      :showButtonEvent="true"
    />
    <hcc-table
      :initialPage="1"
      :columns="columns"
      :rows="groups"
      :group-options="isRetail"
      @edit="edit"
      @add="addCategory"
      @delete="deleteCategorie"
      @editChildren="edit"
      @deleteChildren="deleteSubcategorie"
      @showChildren="showChildren"
      :pagination="false"
      :showEditSubGroup="false"
      :showDeleteSubGroup="false"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'activo'">
          <div class="badget">
            {{ $t(props.row.activo ? 'shop.product.active' : 'shop.product.inactive') }}
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <modal-form-category
      @onGetCategorys="getCategorys"
      @onCancel="clear"
      @onLoad="load"
      :params="params"
      :idCategory="idCategory"
      ref="modalCategory"
    />
    <modal-form-categorie-children
      @onGetCategorysChildren="showChildren"
      @onGetCategorys="getCategorys"
      @onCancel="clearChildren"
      @onLoad="load"
      :idParent="idParentEdit"
      :idCategory="idCategory"
      ref="modalCategorieChildren"
    />
    <hcc-confirmation />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import deleteCategoryStore from '@/graphql/mutations/categoriesProducts/deleteCategorieStore.gql';
import deleteSubCategorieProductGql from '@/graphql/mutations/subcategoriesProducts/deleteSubCategorieProduct.gql';
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  components: {
    OverlayLoader,
    Breadcrumb,
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    ModalFormCategory: () => import('./ModalFormCategory.vue'),
    ModalFormCategorieChildren: () => import('./ModalFormCategorieChildren.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
  },
  data() {
    return {
      loading: true,
      getParent: '',
      idParentEdit: '',
      params: {
        id: '',
        name: '',
        active: false,
      },
      idCategory: '',
      currentCategory: null,
      editCatChildren: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedStore: 'shop/getCurrentShop',
    }),

    items() {
      return this.breadcrumb;
    },
    breadcrumb() {
      return this.$store.state.categorie.breadcrumbItems;
    },
    columns() {
      return [
        {
          label: this.$t('shop.product.options.name'),
          field: 'name',
        },
        {
          label: this.$t('shop.product.options.active'),
          field: 'activo',
        },
      ];
    },
    ...mapState({
      categories: state => state.categorie.categories,
    }),
    storeType() {
      return this.selectedStore.category;
    },
    isRetail() {
      return { enabled: true, collapsable: false };
    },
    getIdParent() {
      const parentFirst = this.categories?.response?.map(item => item.idParent);
      if (parentFirst !== undefined) {
        return parentFirst[0];
      }
      return [];
    },
    groups() {
      if (this.categories?.response?.map) {
        return this.categories.response.map((item, index) => ({
          ...item,
          index,
          nameCategory: item.name,
          name: `${item.name}`,
          activo: `${item.active ? 'Disponible' : 'No disponible'}`,
          addSubgroup: true,
          children: [],
        }));
      }
      return [];
    },
  },
  watch: {
    '$route.params.idStore': async function idStore() {
      this.loading = true;
      await this.getCategoriesCampaign(this.$route.params.id);
      this.loading = false;
    },
  },
  methods: {
    ...mapActions({
      getCategoriesCampaign: 'categorie/getCategoriesCampaign',
      getChildrensCategories: 'categorie/getChildrensCategories',
      removeItems: 'categorie/removeItems',
      setSelectedStore: 'shop/getById',
    }),
    async showChildren({ id, name }, key) {
      const params = {
        id: '',
        name: '',
      };
      params.id = id;
      params.name = name;
      this.currentCategory = id;
      if (key) {
        await this.removeItems(key);
      }
      await this.getChildrensCategories(params);
    },
    addCategorieChildren() {
      this.editCatChildren = false;
      this.idCategory = this.getIdParent;
      this.$modal.show('form-categorie-children');
    },
    edit({ id, idParent }) {
      if (id && idParent) {
        this.idCategory = id;
        this.idParentEdit = idParent;
        this.$refs.modalCategorieChildren.getCategoryChildren(this.idCategory);
        this.$modal.show('form-categorie-children');
      } else {
        this.idCategory = id;
        this.$refs.modalCategory.getCategory(id);
        this.$modal.show('form-category');
      }
    },
    clear() {
      this.params.id = '';
      this.params.name = '';
      this.params.active = false;
      this.editCategory = false;
      this.currentCategory = null;
    },
    clearChildren() {
      this.params.id = '';
      this.params.name = '';
      this.idParentEdit = '';
      this.params.active = false;
    },
    openAddGroup() {
      this.newGroup = { opciones: [] };
      this.$modal.show('group-options-modal');
    },
    async confirmDelete(id, idParent) {
      this.loading = true;
      const { data } = await this.$apollo.mutate({
        mutation: deleteCategoryStore,
        variables: {
          categoryId: id,
        },
      });
      const result = (data.deleteCategorieStore) ? this.loading = false : '';
      const child = this.categories.response.map(item => item.childrens);
      if ((idParent && this.categories.response.length > 1) || child.length === 1) {
        await this.getChildrensCategories({ id: idParent });
      } else {
        await this.getCategorys();
      }
      return result;
    },
    async deleteCategorie({ id, products, idParent }) {
      if (products?.length) {
        this.$modal.show('confirmation', {
          title: this.$t('shop.categories.remove-category-product'),
          description: this.$t('shop.categories.confirm-remove-category'),
          variant: 'default',
          confirm: () => this.confirmDelete(id, idParent),
        });
      } else {
        this.$modal.show('confirmation', {
          title: this.$t('shop.categories.remove-categorie'),
          description: this.$t('shop.categories.confirm-remove-categorie'),
          variant: 'default',
          confirm: () => this.confirmDelete(id, idParent),
        });
      }
    },
    async confirmDeleteSubcategorie(_id) {
      this.loading = true;
      await this.$apollo.mutate({
        mutation: deleteSubCategorieProductGql,
        variables: { _id },
      });
      await this.getCategorys();
      this.loading = false;
    },
    async deleteSubcategorie({ idSubcategorie }) {
      this.$modal.show('confirmation', {
        title: this.$t('shop.categories.remove-subcategorie'),
        description: this.$t('shop.categories.confirm-remove-subcategorie'),
        variant: 'default',
        confirm: () => this.confirmDeleteSubcategorie(idSubcategorie),
      });
    },
    addCategory() {
      if (this.currentCategory) {
        this.addCategorieChildren();
      } else {
        this.idCategory = '';
        this.$modal.show('form-category');
      }
    },
    async getCategorys() {
      this.clear();
      await this.setSelectedStore(this.$route.params.id);
      await this.getCategoriesCampaign(this.$route.params.id);
    },
    load(value) {
      this.loading = value;
    },
  },
  async mounted() {
    await this.getCategorys();
    this.loading = false;
  },
};
</script>
