<template>
  <div class="smartphone">
    <overlay-loader :loading="loading" />
    <div class="content">
      <iframe :src="urlVisitor" class="phone-content" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';

export default {
  components: { OverlayLoader },
  data: () => ({
    loading: true,
    html: '<p>Loading...</p>',
  }),
  computed: {
    ...mapState({
      selectedStore: state => state.shop.item,
    }),
    urlVisitor() {
      const path = `/${this.$route.params.id}/demo`;
      if (this.selectedStore) {
        return `${this.selectedStore.settings.url}${path}`;
      }

      return `${process.env.VUE_APP_MARKETPLACE_URL}${path}`;
    },
  },
  methods: {
    ...mapActions({
      getSelectedStore: 'shop/getById',
    }),
  },
  mounted() {
    this.loading = true;
    this.getSelectedStore(this.$route.params.id)
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
<style lang="scss" scoped>
/* The device with borders */
.smartphone {
  position: relative;
  width: 390px;
  height: 719px;
  margin: auto;
  border: 15px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

iframe.phone-content {
  width: 100%;
  height: 100%;
  border: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: none;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 640px;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
