import listProductsGql from '@/graphql/queries/products/listProducts.gql';
import getProductByIdGql from '@/graphql/queries/products/getProductById.gql';
import executeQuery from '@/utils/gql-api';
import errorHandler from '@/utils/error.handler';

export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    groupOptionsItems: [],
    currentGroup: [],
    idProduct: '',
  },
  getters: {
    getWholesalePrice({ item }) {
      return item.wholesalePrice;
    },
  },
  mutations: {
    setProduct(state, value) {
      state.item = value;
    },
    setItems(state, value) {
      state.items = value;
    },
    setItem(state, value) {
      state.item = value;
    },
    setGroupOptions(state, value) {
      state.groupOptionsItems = value;
    },
    setOptions(state, value) {
      state.currentGroup = value;
    },
    setIdProduct(state, value) {
      state.idProduct = value;
    },
  },
  actions: {
    getProductById({ commit }, id) {
      if (id.length > 20) {
        executeQuery('getProductById',
          getProductByIdGql,
          {
            id,
          }, false)
          .then((payload) => {
            const product = {
              id,
              descripcion: payload.descriptionProduct,
              stock: payload?.stock,
              precio: payload.salePrice,
              purchase: payload.purchasePrice,
              storeId: payload.campaign,
              nombre: payload.name,
              imageURL: payload.imageUrl,
              status: payload.active,
              categoria: payload.categoriesProduct,
              optionsGroup: payload.optionsGroup,
              promotion: payload.promotion,
              promotionDescription: payload.promotionDescription,
              height: payload?.height,
              length: payload?.length,
              width: payload?.width,
              weight: payload?.weight,
              wholesalePrice: payload?.wholesalePrice,
              wholesalePriceStatus: payload?.wholesalePriceStatus,
            };
            commit('setItem', product);
          })
          .catch((err) => {
            errorHandler.logErrors(err);
            commit('setItem', {});
          });
      } else {
        commit('setItem', {});
      }
      commit('setIdProduct', id);
    },
    getAllProducts({ commit }, id) {
      if (id?.length > 20) {
        executeQuery('listProducts',
          listProductsGql,
          {
            campaign: id,
          }, false)
          .then((payload) => {
          // eslint-disable-next-line
          const items = payload.map((item) => {
              const optionsGroup = item.optionsGroup.map(group => ({
                name: group.name,
                opciones: group.opciones.map(opcion => ({
                  name: opcion.name,
                  salePrice: opcion.salePrice,
                  active: opcion.active,
                })),
                type: group.type,
                required: group.required,
              }));
              return {
                id: item.id,
                descripcion: item.descriptionProduct,
                precio: item.salePrice,
                purchase: item.purchasePrice,
                storeId: id,
                nombre: item.name,
                imageURL: item.imageUrl,
                status: item.active,
                categoriesProduct: item.categoriesProduct.map(categorie => categorie.id),
                optionsGroup,
                promotion: item.promotion,
                promotionDescription: item.promotionDescription,
                stock: item.stock,
                length: item.length,
                width: item.width,
                height: item.height,
                weight: item.weight,
              };
            });
            commit('setItems', items);
          })
          .catch((err) => {
            errorHandler.logErrors(err);
            commit('setItems', []);
          });
      } else {
        commit('setItems', []);
      }
    },
    setItemPromotion({ commit }, product) {
      commit('setProduct', product);
    },
  },
};
