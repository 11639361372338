<template>
  <hcc-modal
    v-if="detail"
    name="template-detail-modal"
    :title="name"
    @opened="setStatus"
    @cancel="exitModal"
    @confirm="saveEdited"
    width="600px"
  >
    <div class="template-detail__modal">
      <div class="template-detail__row">
        <div class="template-detail__title">
          {{ $t("common.name") }}
        </div>
        <div
          class="template-detail__description"
          v-html="showNameDetails(detail.element_name)"
        ></div>
      </div>
      <div class="template-detail__row">
        <div class="template-detail__title">
          {{ $t("common.type") }}
        </div>
        <div class="template-detail__description">
          {{ detail.settings.message_type }}
        </div>
      </div>
      <div class="template-detail__row-margin">
        <div class="template-detail__title">
          {{ $t("campaigns.data-bank.templates.category") }}
        </div>
        <div class="template-detail__description">{{ detail.category }}</div>
      </div>
      <div v-if="!isOwner" class="template-detail__row">
        <div class="template-detail__title">
          {{ $t("common.status") }}
        </div>
        <div class="template-detail__description" :class="detail.status">
          {{ detail.status.toUpperCase() }}
        </div>
      </div>
      <hcc-select
        v-else
        v-model="status"
        option-label="name"
        :options="statusOption"
        :label="$t('common.status')"
        :placeholder="$t('common.status')"
        custom-class="add-template__row"
      />
      <div v-if="!isOwner" class="template-detail__row">
        <div class="template-detail__title">Namespace</div>
        <div class="template-detail__description">
          {{ namespaceValue(detail) }}
        </div>
      </div>
      <hcc-input
        v-else
        :placeholder="'namespace'"
        v-model.trim="detail.namespace"
        :label="'Namespace'"
        class="add-template__row"
      />
      <div class="template-detail__row">
        <div class="template-detail__row--description">
          <hcc-textarea
            :label="$t('campaigns.data-bank.templates.description')"
            class="template-detail__description"
            :placeholder="detail.text"
            readonly
            :value="detail.text"
            :cols="textColumns"
            :rows="8"
            name="Test_Textarea"
          />
        </div>
        <div class="template-detail__buttons" v-if="hasButtons">
          <div class="input__label"><label>Botones</label></div>
          <vue-flip v-for="button in buttons"
            :key="button.payload"
            :active-click="true"
            width="100%"
            height="-webkit-fill-available"
            class="flip__card">
            <template v-slot:front>
              <hcc-button color="muted" variant="outline" size="lg">{{ button.name }}</hcc-button>
            </template>
            <template v-slot:back>
              <div class="template-detail__row">
                <span class="template-detail__buttons-title">payload: </span>
                <div
                  class="template-detail__description"
                  v-html="showNameDetails(button.params[0].payload)"
                ></div>
              </div>
              <div class="template-detail__row">
                <div class="template-detail__buttons-title">type: </div>
                <div
                  class="template-detail__description"
                > {{ button.params[0].type }}</div>
              </div>
            </template>
          </vue-flip>
        </div>
      </div>
    </div>
    <template #footer v-if="!isOwner">
      <div class="template-detail__footer">
        <hcc-button color="muted" variant="outline" @click="exitModal">{{
          $t("common.exit")
        }}</hcc-button>
      </div>
    </template>
    <hcc-textarea
      readonly
      v-if="detail.footer && isMultimedia"
      v-model.trim="detail.footer"
      name="template_footer"
      :label="$t('campaigns.data-bank.templates.footer')"
      class="add-template__row"
      :rows="3"
      ref="templateFooter"
    />
    <hcc-textarea
      readonly
      v-if="detail.preview && detail.status !== 'active'"
      v-model.trim="detail.preview"
      name="template_preview"
      :label="$t('campaigns.data-bank.templates.preview')"
      class="add-template__row"
      :rows="8"
      ref="templateFooter"
    />
    <preview v-else
      :hasInformation="false"
      :numParams="isMultimedia ? detail.settings.num_params -1 :  detail.settings.num_params"
      :template="mapTemplate"
      :footer="detail.footer || ''"
    />
  </hcc-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import VueFlip from 'vue-flip';
import HccModal from '@/components/shared/HccModal/index.vue';
import HccButton from '@/components/shared/HccButton/index.vue';
import HccTextarea from '@/components/shared/HccTextarea/index.vue';
import HccSelect from '@/components/shared/HccSelect/index.vue';
import HccInput from '@/components/shared/HccInput/index.vue';
import updateTemplate from '@/graphql/mutations/dataBank/updateTemplate.gql';
import errorHandler from '@/utils/error.handler';
import Preview from './Preview.vue';

export default {
  props: {
    detail: {
      type: Object,
    },
  },
  components: {
    HccModal, HccButton, HccTextarea, HccSelect, HccInput, VueFlip, Preview,
  },
  computed: {
    ...mapGetters({ isOwner: 'shared/isOwner' }),
    buttons() {
      return this.detail?.buttons;
    },
    hasButtons() {
      return this.buttons?.length > 0;
    },
    textColumns() {
      return this.hasButtons ? 40 : 76;
    },
    isMultimedia() {
      return this.detail.settings.message_type !== 'text';
    },
    mapTemplate() {
      return {
        type: this.detail.settings.message_type,
        text: this.detail.text,
      };
    },
  },
  data() {
    return {
      status: '',
      statusOption: [{
        name: this.$t('campaigns.data-bank.templates.pending'),
        type: 'pending',
      }, {
        name: this.$t('campaigns.data-bank.templates.accepted'),
        type: 'active',
      }, {
        name: this.$t('campaigns.data-bank.templates.rejected'),
        type: 'rejected',
      }],
      name: this.$t('campaigns.data-bank.templates.detail'),
    };
  },
  methods: {
    exitModal() {
      this.$modal.hide('template-detail-modal');
    },
    setStatus() {
      this.status = {
        name: this.$t(`campaigns.data-bank.templates.${this.detail.status.toLowerCase()}`),
        type: this.detail.status.toLowerCase(),
      };
    },
    async saveEdited() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: updateTemplate,
          variables: {
            namespace: this.detail.namespace,
            template: this.detail.id,
            status: this.status.type,
            element_name: this.detail.element_name,
            message_type: this.detail.settings.message_type,
          },
        });
        this.$emit('update', data.updateTemplate);
        this.$toasted.global.success(this.$t('campaigns.data-bank.templates.update-success', { name: this.detail.element_name }));
      } catch (err) {
        errorHandler.logErrors(err);
        this.$toasted.global.error(this.$t('common.error-saving'));
      }
    },
    showNameDetails(text) {
      const size = text.length;
      const name = text.substring(0, size - 4);
      const key = text.substring(size - 4);
      return `${name}<b>${key}</b>`;
    },
    namespaceValue(props) {
      return props.namespace || 'Pending';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/settings/dataBank/_templates_tab_data_bank.scss";
</style>
