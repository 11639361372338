<template>
  <div class="loader-container">
    <overlay-loader :loading="pending" />
    <div class="channels__detail">
      <div class="channels__container">
        <div class="channels__group">
          <hcc-input
            :label="channelSettings.name"
            v-model="nameInput"
            customClass="channels__input"
            :requiredInput="isNewChannel"
            :error="error"
            :errorMessage="channelSettings['error-name']"
            :disabled="pending"
            @input="checkError"
          />
          <div class="channels__badge" :class="getBadgeClass()">
            <span v-if="isNewChannel"> {{ channel.name }}</span>
            <span v-else> {{ channel.channelType.name }}</span>
          </div>
        </div>
        <div class="channels__group" v-if="isOwner">
          <span>
            {{ channelSettings.status }}
          </span>
          <div class="channels__status">
            <span
              :class="
                channelStatus
                  ? 'channels__status-active'
                  : 'channels__status-inactive'
              "
              >{{ status }}</span
            >
            <hcc-toggle-switch
              name="toggle-status"
              :value="channelStatus"
              :disabled="pending"
              @change="toggleStatus"
            />
          </div>
        </div>
        <div class="channels__group">
          <hcc-input
            :label="channelSettings.url"
            v-model="urlInput"
            customClass="channels__input"
            :disabled="pending || !isOwner"
          />
        </div>
        <div class="channels__group">
          <whatsapp-edit
            v-if="channelType === 'whatsapp'"
            :channel="channel"
            @update-extras="addExtras"
            @add-logo="addWhatsappLogo"
            @error="checkWhatsappError"
          />
          <telegram-edit
            v-if="channelType === 'telegram' && isOwner"
            :channel="channel"
            @update-extras="addExtras"
          />
          <messenger-edit
            v-if="channelType === 'messenger' && canCreateChannel"
            :channel="channel"
            @update-extras="addExtras"
          />
          <instagram-edit
            v-if="channelType === 'instagram' && canCreateChannel"
            :channel="channel"
            @update-extras="addExtras"
          />
          <webchat-edit
            v-if="channelType === 'webchat' && isOwner"
            :channel="channel"
            @update-extras="addExtras"
          />
        </div>
        <div class="channels__group" v-if="isOwner">
          <hcc-checkbox
            name="notify-admin"
            :label="channelSettings['notify-admin']"
            :value="notify"
            @change="toggleNotification"
            :disabled="pending"
          />
        </div>
      </div>
    </div>
    <div class="channels__edit">
      <hcc-button
        variant="outline"
        color="muted"
        :disabled="pending"
        @click="cancelEdit"
        v-if="mode !== 'create'"
        >{{ channelSettings.cancel }}
      </hcc-button>
      <hcc-button
        :disabled="error || whatsappError || isNewChannelName || pending"
        @click="handleConfirm"
        >{{ channelSettings.save }}</hcc-button
      >
    </div>
  </div>
</template>
<script>
import HccButton from '@/components/shared/HccButton/index.vue';
import HccInput from '@/components/shared/HccInput/index.vue';
import HccCheckbox from '@/components/shared/HccCheckbox/index.vue';
import HccToggleSwitch from '@/components/shared/HccToggleSwitch/index.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import WhatsappEdit from './WhatsappEdit.vue';
import TelegramEdit from './TelegramEdit.vue';
import MessengerEdit from './MessengerEdit.vue';
import InstagramEdit from './InstagramEdit.vue';
import WebchatEdit from './WebchatEdit.vue';

export default {
  components: {
    HccButton,
    HccInput,
    HccToggleSwitch,
    HccCheckbox,
    WhatsappEdit,
    TelegramEdit,
    MessengerEdit,
    InstagramEdit,
    WebchatEdit,
    OverlayLoader,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nameInput: '',
      channelStatus: false,
      urlInput: '',
      notify: null,
      error: false,
      whatsappError: false,
      extras: {},
      waLogo: null,
    };
  },
  computed: {
    isOwner() {
      return this.role === 'owner';
    },
    canCreateChannel() {
      return this.role === 'owner' || this.role === 'super';
    },
    isNewChannel() {
      return this.getMode() === 'create' || this.getMode() === 'empty';
    },
    channelType() {
      return this.isNewChannel ? this.channel.key : this.channel.channelType.key;
    },
    channelSettings() {
      return this.$t('channels-settings');
    },
    status() {
      return this.channelStatus ? this.$t('channels-settings.active') : this.$t('channels-settings.inactive');
    },
    isNewChannelName() {
      return this.isNewChannel && this.nameInput.length === 0;
    },
    mode() {
      return this.getMode();
    },
    pending() {
      return this.isPending();
    },
    defaultUrlManager() {
      return process.env.VUE_APP_DEFAULT_URL_MANAGER || 'https://managers.msgr.pro/api/';
    },
  },
  inject: ['getMode', 'changeMode', 'updateChannel', 'isPending'],
  methods: {
    getBadgeClass() {
      return this.isNewChannel
        ? `channels__badge-${this.channel.key}`
        : `channels__badge-${this.channel.channelType.key}`;
    },
    cancelEdit() {
      this.$emit('cancel');
    },
    toggleStatus() {
      this.channelStatus = !this.channelStatus;
    },
    toggleNotification() {
      this.notify = !this.notify;
    },
    checkError() {
      if (this.nameInput.length === 0) {
        this.error = true;
      } else {
        this.error = false;
      }
    },
    checkWhatsappError(error) {
      this.whatsappError = error;
    },
    setData() {
      if (!this.isNewChannel) {
        this.nameInput = this.channel.name;
        this.channelStatus = this.channel.enabled;
        this.urlInput = this.channel.baseUrl;

        const { __typename, ...extras } = (this.channel.extras || {});
        this.extras = extras;
      }
      const isFBChannel = ['messenger', 'instagram'].includes(this.channelType);
      if (isFBChannel && this.role === 'super' && !this.urlInput) {
        this.urlInput = this.defaultUrlManager;
        if (this.isNewChannel) {
          this.channelStatus = true;
        }
      }
    },
    addExtras(extras) {
      this.extras = extras;
    },
    addWhatsappLogo(logo) {
      this.waLogo = logo;
    },
    handleConfirm() {
      const id = !this.isNewChannel ? this.channel.id : null;

      const channel = {
        id,
        name: this.nameInput,
        enabled: this.channelStatus,
        baseUrl: this.urlInput,
        sendNotification: this.notify,
        extras: this.extras,
        imgWs: this.waLogo,
      };

      if (this.isNewChannel) {
        channel.campaign = this.channel.campaign;
        channel.channelType = {
          name: this.channel.name,
          key: this.channel.key,
        };
        channel.overrideConfig = false;
        channel.overrideMessages = false;
        channel.overrideSchedules = false;
        channel.messages = [];
        channel.schedules = [];
        channel.config = null;
      }

      this.updateChannel(channel, this.channel)
        .then((result) => {
          this.changeMode('detail');
          this.$emit('update', result.data.saveChannel, this.channel, this.channelSettings.detail);
        });
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
